import { BehaviorSubject, Subject } from "rxjs";

declare var $: any;
export class LoaderService {
  scrollFn: any;
  doScrollFnNeedToRun: boolean = true;
  public blockUI(element?: any) {
    try {
      if (element == null || element == undefined) {
        element = "body";
      }
      $(element).block({
        message: $('#loadingImage')[0],
        css: {
          border: '0px',
          backgroundColor: '#fff'
        },
        overlayCSS: {
          backgroundColor: '#000',
          opacity: 0,
          cursor: 'wait'
        },
      });
    }
    catch (e) { }
  }
  public unblock(element?: any) {
    try {
      if (element == null || element == undefined) {
        element = 'body';
      }
      $(element).unblock();
    }
    catch (ex) { }
  }
  public InstructionHidden: Subject<boolean> = new Subject<boolean>();
  public applyScroller(): any {
    var selfThis = this;
    this.scrollFn = (function () {
      if (selfThis.doScrollFnNeedToRun) {
        if ($(".top-sticky") != null && $(".top-sticky")[0] != null) {
          var scroll = $(window).scrollTop();
          if (scroll >= 105) {
            if (!($(".top-sticky").hasClass("position-fixed"))) {
              $(".top-sticky").addClass("position-fixed");
            }
          }
          else {
            if ($(".top-sticky").hasClass("position-fixed")) {
              $(".top-sticky").removeClass("position-fixed");
            }
          }
        }
      }
    });
    return this.scrollFn;
  }
  public loadScroller() {
    this.doScrollFnNeedToRun = true;
    if (this.scrollFn == null) {
      $(window).scroll(this.applyScroller());
    }  
  }
  public emptyFunction() {
    this.doScrollFnNeedToRun = false;
  }
  public getElement(id: string): any {
    return $(id);
  }
}
