import { NgModule } from "@angular/core";
import { EnterpriseLocationAddEditComponent } from "../../enterprise-location-manager/enterprise-location-add-edit/enterprise-location-add-edit.component";
import { EnterprisePropertyLocationComponent } from "../../enterprise-location-manager/enterprise-property-location/enterprise-property-location.component";
//import { PropertyLocationViewComponent } from "../../enterprise-location-manager/property-location-view/property-location-view.component";
import { SharedModule } from "../shared.module";
import { AgmCoreModule } from "@agm/core";
import { MatDatepickerModule, MatNativeDateModule } from '@angular/material';

import { CommonDirectiveModule } from '../../directives/common-directive.module';
import { CommonControlModule } from "../../common-controls/common-control.module";
//import { MatAccordion } from '@angular/material/expansion';

@NgModule({

  imports: [
    SharedModule,
    MatDatepickerModule, MatNativeDateModule,
    CommonDirectiveModule, CommonControlModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB7YEKE1Q7BtDsp8CvBcVriRbcaYkLQG4s',
      libraries: ['places', 'geometry']
    })],
  exports: [
    EnterpriseLocationAddEditComponent, AgmCoreModule, EnterprisePropertyLocationComponent, MatDatepickerModule, MatNativeDateModule
  ],
  declarations: [
    EnterpriseLocationAddEditComponent, EnterprisePropertyLocationComponent
  ]
})
export class EnterpriseLocationModule {}
