import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthenticationSchemes, TenantInformation, AuthenticationSchemeVariables, AuthenticationSchemeVariableValuesPerTenant, TenantVariableValues } from "../tenant-provider/tenant-provider.model";
import { Observable } from "rxjs";
import { GlobalVariable } from "./globalService";
//import { options } from "../app.module";
import { shareReplay } from "rxjs/operators";
import { GeneratedBillVM, TotalInvoiceBill } from "../models/tenant-billing.model";

@Injectable({
  providedIn: 'root'
})
export class TenantProviderService {
  constructor(private httpService: HttpClient, private globalVar: GlobalVariable) { }
  getAllProviders(): Observable<AuthenticationSchemes[]> {
    return this.httpService.get<AuthenticationSchemes[]>(this.globalVar.BASE_URL + 'api/tenant/getAllProviders');
  }
  getAllTenants(): Observable<TenantInformation[]> {
    return this.httpService.get<TenantInformation[]>(this.globalVar.BASE_URL + 'api/tenant/getAllTenants');
  }
  getTenantProviders(tenantId: number): Observable<AuthenticationSchemes[]> {
    return this.httpService.get<AuthenticationSchemes[]>(this.globalVar.BASE_URL + 'api/tenant/getTenantProviders?tenantId=' + tenantId);
  }
  getAllDynamicVariables(providerId: number): Observable<AuthenticationSchemeVariables[]> {
    return this.httpService.get<AuthenticationSchemeVariables[]>(this.globalVar.BASE_URL + 'api/tenant/getAllDynamicVariables?providerId=' + providerId);
  }
  getAllVariableValuesPerTenantAndProvider(tenantId: number, providerId: number): Observable<AuthenticationSchemeVariableValuesPerTenant[]> {
    return this.httpService.get<AuthenticationSchemeVariableValuesPerTenant[]>(this.globalVar.BASE_URL + 'api/tenant/getAllVariableValuesPerTenantAndProvider?providerId=' + providerId + "&tenantId=" + tenantId);
  }
  unSetProviderForATenant(tenantId: number, providerId: number): Observable<boolean> {
    return this.httpService.get<boolean>(this.globalVar.BASE_URL + 'api/tenant/unSetProviderForATenant?providerId=' + providerId + "&tenantId=" + tenantId);
  }
  setProviderForTenantAndSetDynamicVariablesValue(variable: TenantVariableValues): Observable<boolean> {
    return this.httpService.post<boolean>(this.globalVar.BASE_URL + 'api/tenant/setProviderForTenantAndSetDynamicVariablesValue', variable);
  }
  getDataObservable: Observable<string> = null;
  getData(): Observable<string> {
    if (this.getDataObservable == null) {
      this.getDataObservable = this.httpService.get(this.globalVar.BASE_URL + 'api/tenant/getData', { responseType: 'text' }).pipe(shareReplay(1));
      return this.getDataObservable;
    }
    else {
      return this.getDataObservable
    }
  }
  getConfig(): Observable<any> {
    return this.httpService.get<Observable<any>>(this.globalVar.BASE_URL + 'api/tenant/configureAuth?tenantId=1');
  }
  IsTenantRequest(): Observable<number> {
    return this.httpService.get<number>(this.globalVar.BASE_URL + 'api/tenant/isTenantRequest');
  }
  getTenantIdentifier(): Observable<string> {
    return this.httpService.get(this.globalVar.BASE_URL + 'api/tenant/getTenantIdentifier', { responseType:'text' });
  }
  getAllBills(filterData: any): Observable<{ data: GeneratedBillVM[], total: number, totalPendingAmount: TotalInvoiceBill }> {
    return this.httpService.post<{ data: GeneratedBillVM[], total: number, totalPendingAmount: TotalInvoiceBill }>(this.globalVar.BASE_URL + 'api/tenantBilling/getTenantBills', filterData);
  }
  getBillingInfo(billId: number): Observable<GeneratedBillVM> {
    return this.httpService.get<GeneratedBillVM>(this.globalVar.BASE_URL + 'api/tenantBilling/getBillInfo?billId=' + billId);
  }
  getTenantName(): Observable<string> {
    return this.httpService.get(this.globalVar.BASE_URL + 'api/tenant/getTenantName', { responseType:'text' })
  }
  updateStatusOfGeneratedBill(): Observable<boolean> {
    return this.httpService.get<boolean>(this.globalVar.BASE_URL + 'api/tenantBilling/updateStatusOfGeneratedBill');
  }
}
