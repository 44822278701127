import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizeGuard } from '../../../api-authorization/authorize.guard';
import { TenantGaurd } from '../../services/tenant-gaurd.service';
import { EnterpriseLocationModule } from '../enterprise-location-module/enterprise-location.module';

const appRoutes: Routes = [
  {
    //Main Site
      //Eagerly Load
    path: 'mainsite',
    loadChildren: () => import('../../main-site/main-site-module/main-site.module').then(m => m.MainSiteModule),
    canActivate: [TenantGaurd]
  },
  {
    //Dashboard
    //Lazy Load
    path: 'mainapp', loadChildren: () => import('../software-panel.module').then(m => m.SoftwarePanelModule), canActivate: [AuthorizeGuard],
    //canActivate: [AuthorizeGuard],
  },
  {
    //Site Home Page(Default)
    path: '', redirectTo: '/mainsite/home', pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    
    RouterModule.forRoot(appRoutes)   
  ],
  exports: [RouterModule, EnterpriseLocationModule]
})
export class AppRoutingModule { }
export const routingComponent = [
  //VersionDialogComponent
]
