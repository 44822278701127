import { DecimalPipe } from "@angular/common";
import { BehaviorSubject, Observable } from "rxjs";

export class EnterpriseModel {

    public id: number;

    public name: string;
    public currencyId: number;
    public countryId: number;
    public isTestEnterprise: boolean;
    public levelId: number;
    public isActive: boolean;
    public tenantId: number;
    public currencyName: string;
    public countryName: string;
    public levelName: string;
    public tenantid: number;

}
export class LevelInfoModel {
    public levelId: number;
  public levelName: string;
  public isSelected: boolean;
}
export class CurrencyModel {
    public currencyId: number;
  public name: string;
  public currencyCode: string;
}
export class CountryModel {
    public lookupId: number;
    public lookupName: string;
    public parentLookupId: number;
    public parentId: number;
    public isSelected: boolean;
}
export class StateModel {
    public lookupId: number;
    public lookupName: string;
    public parentLookupId: number;
    public parentId: number;
}
  
export class EnterprisePageModel {
    public enterpriseList: EnterpriseVMDBMapp[];
    public enterpriseListVM: EnterpriseModelVM[];
    public totalCount: number;
}
export class EnterpriseStructure {

    public enterpriseStructureId: number;
    public structureName: string;
}
export class EnterpriseModelVM extends EnterpriseModel {
    public parentId: number;
    public otherName: string;
    public levelName: string;
    public isCollapsed: boolean = true;
    public otherLevelId: number
    public otherLevelName: string
    public countryName: string;
    public isEditMode: boolean;
    public isParticipating: boolean;
  public isSelected: boolean = false;
  public take: number;
  public skip: number;
  public searchText: string;
  public appId: number;
  public versionId: number;
}
export class AuditTrialLogVM {
  public auditTrialId: number;
  public keyId: number;
  public actionType: string;
  public modelName: string;
  public oldValue: string;
  public newValue: string;
  public description: string;
  public enterpriseId: number;
  public appId: number;
  public comments: string;
  public userId: string;
  public createdBy: string;
  public createdDate: Date;
  public strCreatedDate: string;
  public modifiedBy: string;
  public modifiedDate: Date;
  public appname: string;
  public enterpriseName: string;
  public createdUser: string;
  public modifiedUser: string;
  public tenantId: number;
  public take: number;
  public skip: number;
  public attachmentVM: AttachmentVM[] = [new AttachmentVM()];
}
export class AttachmentVM {
  public attachment: string;
  public attachmentPath: string;
  public attachmentId: number;
}
export class EnterpriseHierarchyVM {
    public enterprise: EnterpriseModelVM;
    public enterprises: EnterpriseModelVM[];
}
export interface HierarchyNode {
    name: string;
    enterpriseId: number;
    parentEnterpriseId: number;
    children?: HierarchyNode[];
}
export class EnterpriseVMDBMapp {
    id: number
    name: string;
    levelId: number
    levelName: string;
    countryId: number
    currencyId: number
    isActive: boolean
    currencyName: string;
    symbol: string;
    countryName: string;
    totalCount: number;
    isTestEnterprise: boolean;
}
export class EnterpriseUsersVM {
  public id: number;
  public userId: string;
  public entityId: number;
  public roleId: number;
  public createdBy: string;
  public createdDate: Date;
  public modifiedBy: string;
  public modifiedDate: Date;
  public userName: string;
  public take: number;
  public skip: number;
  public searchText: string;
  public roleName: string;
  public createdUser: string;
  public modifiedUser: string;
  public usersList: userLisVM[];
  public rolesList: ApplicationRolesVM[];
    }

export class userLisVM {
  public id: string;
  public userName: string;
  public email: string;
  public firstName: string;
  public lastName: string;
  public middleName: string;
  public isActive: boolean;
  public isSelected: boolean;
  public phoneNumber: string;
  public externalProviderId: number;
  public externalProviderIds: string;

}
export class ApplicationRolesVM {
  public roleId: number;
  public name: string;
  public description: string;
  public tenantId: number;
  public isActive: boolean;
  public createdBy: string;
  public createdDate: Date;
  public modifiedBy: string;
  public modifiedDate: Date;
  public isSelected: boolean;
  public isDeleted: boolean;
  public enterpriseUserId: number;
}
export class EnterpriseUserSaveModel {
  public id: number;
  public entityId: number;
  public roleIds: number[];
  public userIds: string[];
  public userid: string;

}
export class EnterpriseIdsAppVM {
  public enterpriseIds: number[];
  public appId: number;
  public isChecked: boolean;
  public comment: string;
  public versionId: number;
        }

export class UserListfilteredModel {

  public detailsSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public detailObservable: Observable<userGridListVM[]>;
  public userCollectionsDetail: userGridListVM[] = [];
}
export class userGridListVM {
  public id: string;
  public userName: string;
  public email: string;
  public searchString: string;
  public firstName: string;
  public lastName: string;
  public middleName: string;
  public completeName: string;
  public userID: string;
  public enterprises: string;
  public enterpriseId: number;
  public applicationRoleId: number;
  public applicationRole: string;
  public organizationalRoleId: number;
  public organizationalRole: string;
  public enterpriseIds: number[];
  public applicationRoleIds: number[];
  public applicationName: string;
  public appVersionId: number;
  public applicationVersionName: string;
  public isSelected: boolean;
  public levelId: number;
  public userSelected: boolean;
}
    export class EmailSessionVM {
      public id: number;
      public sessionId: string;
      public createdDate: Date;
      public stopTime: Date
      public status: string;
      public appId: number;
      public versionId: number
      public startedBy: string;
      public totalUserToBeMailed: number;
      public totalEnterprise: number;
      public schedularId: number;
      public isTestOnly: number;
      public isReminderMails: number;
      public reminderCounter: number;
      public tenantId: number;
      public appName: string;
      public versionName: string;
      public userName: string;
      public take: number;
      public skip: number;
}


