import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[appDisablePast]'
})
export class DisablePasteDirective {
    constructor() { }
  
    @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
        e.preventDefault();
    }
}
