import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from "ngx-pagination";
import { HttpClientModule } from '@angular/common/http';
import { ApiAuthorizationModule } from 'src/api-authorization/api-authorization.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  imports: [CommonModule],
  exports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    FormsModule,
    ApiAuthorizationModule,
    NgxPaginationModule,
    DragDropModule,
    NgbModule,
    ImageCropperModule
  ]
})
export class SharedModule { }
