import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { GlobalVariable } from "./globalService";
import { Projects, Employees, DropDownVM, ApplicationsVM, TimeTracks, HolidayList, ExpressEntry, TimeTrackerFilterModelVM, ApplicationType, LogErrorVM, TimeTrackConstraints, TimeTrackerComments, AppUsersModelVM, TimetrackAppHours } from '../models/Timetrack';
import { map, catchError, retry } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { commonFunctionService } from './commonFunction.service';
import { GridPageSortFilters } from '../models/commonControl.model';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}
@Injectable({
  providedIn: 'root'
})

export class TimeTrackService {
  public baseUrl;

  constructor(private globalVar: GlobalVariable, private httpclient: HttpClient, @Inject('BASE_URL') baseUrl: string, private _commonservice: commonFunctionService) {
    this.baseUrl = baseUrl;

  }

  //public GetTimeTracks(): Observable<any> {
  //  return this.httpclient.get<TimeTracks[]>(this.baseUrl + 'api/TimeTrack/GetAllEmployeesTimeTrackData');
  //  }

  getAllTimeTracks(constraints: TimeTrackConstraints, gridpsFilterItem: GridPageSortFilters): Observable<any> {
    return this.httpclient.post<{ item1: TimeTracks[], item3: string }>(this.globalVar.BASE_URL + 'api/TimeTrack/ListTimeTracks', { constraints: constraints, sortPageFilters: gridpsFilterItem });
  }

  public SaveNewTimeTrackFromGrid(timeObj: TimeTracks): Observable<any> {
    return this.httpclient.post(this.baseUrl + 'api/TimeTrack/SaveOrUpdateTimeTrackDataFromInlineGrid', timeObj);
    //.pipe(retry(1),
     // catchError(this.handleError)).pipe(catchError(e => throwError(this.errorHandler(e))))
  }
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
  public GetTimeTrackById(timeTrackId: number): Observable<any> {
    return this.httpclient.get<TimeTracks>(this.baseUrl + 'api/TimeTrack/GetTimeTrackById?timeTrackId=' + timeTrackId);
  }
  getEmployeeTime(id: number): Observable<TimeTracks> {
    if (id != 0) {
      return this.httpclient.get<TimeTracks>(this.globalVar.BASE_URL + 'api/TimeTrack/GetTimeTrackById?timeTrackId=' + id).pipe(map((resp => {
        return resp;
      })));
    }
    else {
      var recTime = new TimeTracks();
      recTime.id = 0;
      return of(recTime);
    }
  }
  getApplicationTypes(): Observable<ApplicationType[]> {
    return this.httpclient.get<ApplicationType[]>(this.globalVar.BASE_URL + 'api/TimeTrack/GetApplicationForTimeTrack');
  }
  public downloadTimeTrackData(filter: TimeTrackerFilterModelVM, isDownloadExcel: boolean): Observable<any> {
    return this.httpclient.get(this.globalVar.BASE_URL + "api/TimeTrack/DownloadTimeTrackData?filter=" + JSON.stringify(filter) + "&isDownloadExcel=" + isDownloadExcel, { observe: 'response', responseType: 'blob' }).pipe(map(resInner => {

      if (isDownloadExcel) {
        saveAs(resInner.body, "Time Track File" + new Date() + ".xlsx");
      }
      else {
        saveAs(resInner.body, "Time Track File" + new Date() + ".PDF");
      }
      return resInner;
    }))
  }
  public DeleteTimeTrackById(timeTrackId: number): Observable<any> {
    return this.httpclient.get<any>(this.baseUrl + 'api/TimeTrack/DeleteTimeTrackById?timeTrackId=' + timeTrackId);
  }

  public GetFilteredTimeTrack(timetrackfilterVM: TimeTrackerFilterModelVM): Observable<{ item1: TimeTracks[], item2: number }> {
    return this.httpclient.post<{ item1: TimeTracks[], item2: number }>(this.baseUrl + 'api/TimeTrack/GetFilteredTimeTrackNew', timetrackfilterVM);
  }

  public GetFilteredTimeTrackForDashboard(timetrackfilterVM: TimeTrackerFilterModelVM): Observable<{ item1: TimetrackAppHours[], item2: TimetrackAppHours[], item3: TimetrackAppHours[], item4: TimetrackAppHours }> {
    return this.httpclient.post<{ item1: TimetrackAppHours[], item2: TimetrackAppHours[], item3: TimetrackAppHours[], item4: TimetrackAppHours }>(this.baseUrl + 'api/TimeTrack/GetFilteredTimeTrackForDashboard', timetrackfilterVM);
  }
  
  public GetFilteredVerifyTime(timetrackfilterVM: TimeTrackerFilterModelVM): Observable<{ item1: TimeTracks[], item2: number }> {
    return this.httpclient.post<{ item1: TimeTracks[], item2: number }>(this.baseUrl + 'api/TimeTrack/GetFilteredVerifyTime', timetrackfilterVM);
  }


  public ApproveOrDisapproveTimeTrack(timeTrackObj: TimeTracks): Observable<TimeTracks> {
    return this.httpclient.post<TimeTracks>(this.baseUrl + 'api/TimeTrack/ApproveTimeTrack', timeTrackObj);
  }

  public SaveManagersComment(timeTrackId: number, managersComment: string): Observable<any> {
    return this.httpclient.get<boolean>(this.baseUrl + 'api/TimeTrack/SaveManagersComment?timeTrackId=' + timeTrackId + '&managersComment=' + managersComment);
  }

  public MarkAllRecordsApproved(employeeId: string, projectId: number, status: boolean, timeTrackIds: number[]): Observable<any> {
    return this.httpclient.post<boolean>(this.baseUrl + 'api/TimeTrack/MarkAllRecordsApproved?employeeId=' + employeeId + '&projectId=' + projectId + '&status=' + status, timeTrackIds);
  }

  saveExpressEntry(expresEntryData: any[]) {
    expresEntryData.forEach(obj => {
      obj.currentDate = new Date(obj.currentDate);
      obj.currentDate = this._commonservice.convertToUTC(obj.currentDate);
    })
    var returnedObservableStream = this.httpclient.post(this.globalVar.BASE_URL + 'api/TimeTrack/SaveOrUpdateExpressEntryData', expresEntryData).pipe(retry(1),
      catchError(this.handleError));
    return returnedObservableStream;
  }

  errorHandler(e) { console.log(e); }

  public GetExpressEntryData(startDate: Date, endDate: Date): Observable<any> {

    return this.httpclient.get<ExpressEntry[]>(this.baseUrl + 'api/TimeTrack/GetExpressEntryDataForDateRange?startDate=' + startDate.toDateString() + '&endDate=' + endDate.toDateString());
  }
  public GetAllUsers(): Observable<any> {
    return this.httpclient.get<Employees[]>(this.baseUrl + 'api/TimeTrack/GetAllUsers');
  }
  public getAllProjects(): Observable<any> {
    return this.httpclient.get<Projects[]>(this.baseUrl + 'api/TimeTrack/GetAllProjects');
  }
  public getAllWorkType(): Observable<any> {
    return this.httpclient.get<DropDownVM[]>(this.baseUrl + 'api/TimeTrack/GetAllWorkType');
  }
  public getAllStatusType(): Observable<any> {
    return this.httpclient.get<DropDownVM[]>(this.baseUrl + 'api/TimeTrack/GetAllStatusType');
  }
  public getAllPriorityType(): Observable<any> {
    return this.httpclient.get<DropDownVM[]>(this.baseUrl + 'api/TimeTrack/GetAllPriorityType');
  }
  public getAllCategoryType(): Observable<any> {
    return this.httpclient.get<DropDownVM[]>(this.baseUrl + 'api/TimeTrack/GetAllCategoryType');
  }
  public GetPermissionForVerifyTime(): Observable<boolean> {
    return this.httpclient.get<boolean>(this.baseUrl + 'api/TimeTrack/GetPermissionForVerifyTime');
  }
  public logError(logObj: LogErrorVM) {
    var i = this.httpclient.post<number>(this.globalVar.BASE_URL + 'api/TimeTrack/LogError', logObj);
    return i;
  }
  public getTimetrackCommentsbyId(id: number): Observable<TimeTrackerComments[]> {
    return this.httpclient.get<TimeTrackerComments[]>(this.baseUrl + 'api/TimeTrack/GetTimetrackCommentById?id=' + id);
  }
  public saveTimetrackComments(obj: TimeTrackerComments): Observable<number> {
    return this.httpclient.post<number>(this.globalVar.BASE_URL + 'api/TimeTrack/SaveTimetrackComments', obj);
  }
  public userTimetrackStatus(): Observable<any> {
    return this.httpclient.get<DropDownVM>(this.globalVar.BASE_URL + 'api/TimeTrack/UserTimetrackStatus');
  }
  //public saveEmployeeTaskStatus(timeTrackObj: TimeTracks): Observable<TimeTracks> {
  //  return this.httpclient.post<TimeTracks>(this.globalVar.BASE_URL + 'api/TimeTrack/ManageTimeTrackStatus', timeTrackObj);
  //}

  saveEmployeeTaskStatus(timeTrackObj: TimeTracks) {
    var returnedObservableStream = this.httpclient.post<TimeTracks>(this.globalVar.BASE_URL + 'api/TimeTrack/ManageTimeTrackStatus', timeTrackObj).pipe(retry(1),
      catchError(this.handleError));
    return returnedObservableStream;
  }






  public fetchAplicationAndUsers(appid: number, userid: string): Observable<AppUsersModelVM> {
    return this.httpclient.get<AppUsersModelVM>(this.globalVar.BASE_URL + 'api/TimeTrack/GetProjectAndUsersfilters?appId=' + appid + '&userId=' + userid);
  }
  public setCommentReadStatus(id: number): Observable<number> {
    return this.httpclient.post<number>(this.globalVar.BASE_URL + 'api/TimeTrack/SetCommentReadStatus',id);
  }
  public getTimetrackProjectfilters(appid: number, userid: string): Observable<AppUsersModelVM> {
    return this.httpclient.get<AppUsersModelVM>(this.baseUrl + 'api/TimeTrack/GetTimetrackProjectfilters?appId=' + appid + '&userId=' + userid);
  }
  public sendWeeklyEmail(timetrackfilter: TimeTrackerFilterModelVM): Observable<number> {
    return this.httpclient.post<number>(this.globalVar.BASE_URL + 'api/TimeTrack/SendWeeklyReportEmail', timetrackfilter);
  }
}
