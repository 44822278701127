import { NgModule, ErrorHandler } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthorizeInterceptor } from "../../../api-authorization/authorize.interceptor";
import { GlobalVariable } from "../../services/globalService";
import { AppName } from "../../services/appNameService";
import { GlobalDialogService } from "../../global-dialog/global-dialog.service";
import { GlobalErrorHandler } from "../../services/error-log/global-error.handler";
import { ServerErrorInterceptor } from "../../services/error-log/server-error.interceptor";
import { AppManager } from "../../services/appService";
import { LoaderService } from "../../services/loadingService";
import { EnterpriseManager } from "../../services/enterpriseService";
import { SafetyManager } from "../../safety/safety.service";
import { CommonControlManager } from "../../services/commonControl.service";
import { QuestionService } from "../../services/question.service";
import { sharedDataService } from "../../services/shareData-service";
import { commonFunctionService } from "../../services/commonFunction.service";
import { DirectoryService } from "../../directory/directory.service";
import { TenantService } from "../../services/TenantService.service";
@NgModule({

    providers: [
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    GlobalVariable, AppName, GlobalDialogService, AppManager, LoaderService, EnterpriseManager, SafetyManager, CommonControlManager, QuestionService, sharedDataService,
    commonFunctionService, DirectoryService,TenantService
    ]
})
export class ProviderModule { }
