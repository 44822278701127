import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-download-controls',
  templateUrl: './download-controls.component.html',
  styleUrls: ['./download-controls.component.css']
})
export class DownloadControlsComponent implements OnInit {
  from: number =0;
  to: number =1;
  constructor() { }

  @Output() downloadExcelClicked: EventEmitter<{ from: number, to: number }> = new EventEmitter<{ from: number, to: number }>();
  @Output() downloadPDFClicked: EventEmitter<{ from: number, to: number }> = new EventEmitter<{ from: number, to: number }>();
  ngOnInit() {
  }
  downloadExcel() {
    if (this.from == null) {
      this.from = 0;
    }
    if (this.to == null) {
      this.to = 0;
    }
    this.downloadExcelClicked.emit({ from: this.from, to: this.to });
  }
  downloadPDF() {
    if (this.from == null) {
      this.from = 0;
    }
    if (this.to == null) {
      this.to = 0;
    }
    this.downloadPDFClicked.emit({ from: this.from, to: this.to });
  }

}
