import { HttpClient } from "@angular/common/http";
import { GlobalVariable } from "./globalService";
import { Observable, of } from "rxjs";
import { Tenant } from "../main-site/tenant-register/tenant.model";
import { Injectable } from "@angular/core";
@Injectable()
export class TenantService {
  constructor(private http: HttpClient, private globalVar: GlobalVariable) { }
  createUserConfirmEmail(tenant: Tenant): Observable<any> {
    return this.http.post<any>(this.globalVar.BASE_URL + 'api/tenant/createUserConfirmEmail', tenant);
  }
  checkIfTenantExists(tenantName: string): Observable<boolean> {
    return this.http.get<boolean>(this.globalVar.BASE_URL + 'api/tenant/checkIfTenantExists?tenantName=' + tenantName)
  }
  checkIfEmailAttachedToTenant(email: string, tenantIdentifierStr: string, sendOtp: boolean) {
    return this.http.get<number>(this.globalVar.BASE_URL + 'api/tenant/checkIfEmailAttachedToTenant?email=' + email + "&tenantId=" + tenantIdentifierStr + "&sendOtp=" + sendOtp);
  }
}
