import { Component, OnInit } from '@angular/core';
import { AuthorizeService } from '../authorize.service';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GlobalVariable } from '../../app/services/globalService';
import { UserManagementService } from '../../app/services/user-management.service';
import { CommonControlManager } from '../../app/services/commonControl.service';

@Component({
  selector: 'app-login-menu',
  templateUrl: './login-menu.component.html',
  styleUrls: ['./login-menu.component.css']
})
export class LoginMenuComponent implements OnInit {
  public isAuthenticated: Observable<boolean>;
  //public userName: Observable<string>;
  public userName: string;
  public userEmail: string;
  public subscription: Subscription;
  public doctType: string = 'UserProfile';
  userLoggedInDetail: any;
  isAdminUser: boolean;
  public role: number;
  public userRolesubscription: Subscription;
  public rolesubscription: Subscription;
  public emailSubscription: Subscription;
  constructor(private authorizeService: AuthorizeService, private global: GlobalVariable, private userService: UserManagementService, private commmoncontrolManager: CommonControlManager) {
    this.userName = global.userName;
    this.userEmail = global.userEmail;
  }

  ngOnInit() {
    this.isAuthenticated = this.authorizeService.isAuthenticated();
    // this.userName = this.authorizeService.getUser().pipe(map(u => u && u.name));

    this.subscription = this.global.userBehaviourSubject.subscribe(userName => {
      this.userName = userName;
    });
    this.rolesubscription = this.global.roleBehaviourSubject.subscribe(isAdmin => {
      this.isAdminUser = isAdmin;
    });
    this.emailSubscription = this.global.userEmailBehaviourSubject.subscribe(userEmail => {
      this.userEmail = userEmail;
    });
    this.isAuthenticated.subscribe(rr => {
      if (rr == true) {
        this.commmoncontrolManager.getLoginIsUserRole().subscribe(res => {
          this.role = res;
        })
      }
    })
  }

}
