import { GlobalVariable } from "./globalService";
import { AspnetuserList, ApplicationRolesVM, tenantInformation, tenantUsersVM, userTenantInfo, AppPermissionsVM, AppPermissionsModelVM, AspnetuserVM, userActivityLogVM } from "../models/appModel";
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from "rxjs";
import { map, catchError, retry } from "rxjs/operators";
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { GridPageSortFilters, CommonParameters } from "../models/commonControl.model";
import { EnterpriseUsersVM, EnterpriseUserSaveModel, userLisVM } from '../models/enterpriseModel';
import { AuthenticationSchemes } from "../tenant-provider/tenant-provider.model";
@Injectable(
  {
    providedIn: 'root'
  }
)
export class UserManagementService {

  constructor(private globalVar: GlobalVariable, private httpService: HttpClient) {
  }
  getAllUserList(constraints: AspnetuserList, gridpsFilterItem: GridPageSortFilters, commonParameters: CommonParameters): Observable<any> {
    return this.httpService.post<any>(this.globalVar.BASE_URL + 'api/UserManagement/GetAllAspnetUsers', { constraints: constraints, sortPageFilters: gridpsFilterItem, commonParameters: commonParameters });
  }
  getCurrentUser(userId: string): Observable<AspnetuserList> {

    return this.httpService.get<AspnetuserList>(this.globalVar.BASE_URL + 'api/UserManagement/GetUserById?userId=' + userId);//.pipe(map((resp => {
    //return resp;
    //   })));
  }
  saveAspnetUser(currentUser: AspnetuserList): Observable<AspnetuserList> {
    return this.httpService.post<AspnetuserList>(this.globalVar.BASE_URL + 'api/UserManagement/AddEditAspnetUsers', currentUser).pipe(map((resp => {
      return resp;
    })));
  }
  saveAspnetUserWithTenant(currentUser: AspnetuserVM): Observable<AspnetuserList> {
    return this.httpService.post<AspnetuserList>(this.globalVar.BASE_URL + 'api/UserManagement/saveAndUpdateAspnetUsers', currentUser).pipe(map((resp => {
      return resp;
    })));
  }
  SaveUserAsDirectory(currentUser: EnterpriseUsersVM): Observable<EnterpriseUsersVM> {
    return this.httpService.post<EnterpriseUsersVM>(this.globalVar.BASE_URL + 'api/UserManagement/SaveUserAsDirectory', currentUser).pipe(map((resp => {
      return resp;
    })));
  }
  uploadFile(formData: any) {
    return this.httpService.post(this.globalVar.BASE_URL + 'api/UserManagement/Upload', formData, { reportProgress: true, observe: 'events' })
  }
  public inActiveUser(currentUser: string): Observable<any> {
    return this.httpService.get<any>(this.globalVar.BASE_URL + 'api/UserManagement/InactiveUserById?userid=' + currentUser);
  }

  public getUserPhoto(userId: string, docType: string, image_thumb: boolean): Observable<string> {
    return this.httpService.get<string>(this.globalVar.BASE_URL + 'api/UserManagement/GetProfilePhoto?userId=' + userId + '&docType=' + docType + '&image_thumb=' + image_thumb, { responseType: 'text' as 'json' }).
      pipe(map((resp => {
        return resp;
      })));
  }
  getTenantInformationById(): Observable<any> {
    return this.httpService.get<any>(this.globalVar.BASE_URL + 'api/UserManagement/GetTenantInformationById');
  }
  resetUserPaswd(resetPassword: AspnetuserList): Observable<AspnetuserList> {
    return this.httpService.post<AspnetuserList>(this.globalVar.BASE_URL + 'api/UserManagement/ResetUserPassword', resetPassword).pipe(map((resp => {
      return resp;
    })));
  }

  getApplicationRoles(obj: ApplicationRolesVM): Observable<{ item1: ApplicationRolesVM[], item2: number }> {
    return this.httpService.post<{ item1: ApplicationRolesVM[], item2: number }>(this.globalVar.BASE_URL + 'api/UserManagement/GetApplicationRoles', obj)
  }

  getApplicationRole(): Observable<ApplicationRolesVM[]> {
    return this.httpService.post<ApplicationRolesVM[]>(this.globalVar.BASE_URL + 'api/UserManagement/GetApplicationRole', {})
  }

  getappRolebyId(roleId: number): Observable<ApplicationRolesVM> {
    return this.httpService.get<ApplicationRolesVM>(this.globalVar.BASE_URL + 'api/UserManagement/GetAppRolebyId?roleId=' + roleId);
  }
  addUpdateApplicationrole(obj: ApplicationRolesVM): Observable<number> {
    return this.httpService.post<number>(this.globalVar.BASE_URL + 'api/UserManagement/AddUpdateApplicationrole', obj).pipe(
      retry(1),
      catchError(this.handleError)
    );



    //  pipe(map((resp => {
    // return resp;
    //  })));
    //.pipe(
    //  retry(1),
    // catchError(this.handleError)
    // );
  }
  public getloggedInUserDetail(): Observable<any> {
    return this.httpService.get<any>(this.globalVar.BASE_URL + "api/app/getloggedInUserDetail");
  }
  getEnterpriseUserList(obj: EnterpriseUsersVM): Observable<{ item1: EnterpriseUsersVM[], item2: number }> {
    return this.httpService.post<{ item1: EnterpriseUsersVM[], item2: number }>(this.globalVar.BASE_URL + 'api/UserManagement/GetUserbyEnterpriseId', obj)
  }
  getEnterpriseUserModel(obj: EnterpriseUserSaveModel): Observable<EnterpriseUsersVM> {
    return this.httpService.post<EnterpriseUsersVM>(this.globalVar.BASE_URL + 'api/UserManagement/getEnterpriseUserModel', obj)
  }
  saveEnterpriseUSers(obj: EnterpriseUserSaveModel): Observable<number> {
    return this.httpService.post<number>(this.globalVar.BASE_URL + 'api/UserManagement/SaveEnterpriseUsers', obj)
  }
  removeUserRole(id: number, enterpriseid: number): Observable<number> {
    return this.httpService.get<number>(this.globalVar.BASE_URL + 'api/UserManagement/RemoveEnterpriseUserRoleById?id=' + id + '&enterpriseid=' + enterpriseid)
  }
  removeAppRole(id: number): Observable<number> {
    return this.httpService.get<number>(this.globalVar.BASE_URL + 'api/UserManagement/RemoveAppRoleById?id=' + id)
  }
  getUserList(): Observable<userLisVM[]> {
    return this.httpService.post<userLisVM[]>(this.globalVar.BASE_URL + 'api/UserManagement/getAllUsers', {})
  }
  getUsersByPermissions(): Observable<userLisVM[]> {
    return this.httpService.post<userLisVM[]>(this.globalVar.BASE_URL + 'api/UserManagement/getUsersByPermissions', {})
  }


  public downloadAllUserList(filter: AspnetuserList, isDownloadExcel: boolean): Observable<any> {
    return this.httpService.get(this.globalVar.BASE_URL + "api/UserManagement/DownloadUserListData?filter=" + JSON.stringify(filter) + "&isDownloadExcel=" + isDownloadExcel, { observe: 'response', responseType: 'blob' }).pipe(map(resInner => {

      if (isDownloadExcel) {
        saveAs(resInner.body, "User List File" + new Date() + ".xlsx");
      }
      else {
        saveAs(resInner.body, "User List File" + new Date() + ".PDF");
      }
      return resInner;
    }))
  }

  getAllApplicationUserList(constraints: AspnetuserList, gridpsFilterItem: GridPageSortFilters, commonParameters: CommonParameters, applicationId: number, enterpriseId: number, userId: string, roleId: number, appRoleId: number, appVersionId: number, selectedAppOwnerOption: boolean, selectedEnterpriseAdminOption: boolean): Observable<any> {
    return this.httpService.post<any>(this.globalVar.BASE_URL + 'api/UserManagement/GetAllApplicationUsers?applicationId=' + applicationId + '&enterpriseId=' + enterpriseId + '&userId=' + userId + '&roleId=' + roleId + '&appRoleId=' + appRoleId + '&selectedAppOwnerOption=' + selectedAppOwnerOption + '&selectedEnterpriseAdminOption=' + selectedEnterpriseAdminOption + '&appVersionId=' + appVersionId, { constraints: constraints, sortPageFilters: gridpsFilterItem, commonParameters: commonParameters });
  }
  getPermissionChangeHistory(constraints: AspnetuserList, gridpsFilterItem: GridPageSortFilters, commonParameters: CommonParameters, appPermissionModelObj: AppPermissionsModelVM, applicationId: number, enterpriseId: number, userId: string, roleId: number, appRoleId: number, appVersionId: number): Observable<any> {
    return this.httpService.post<any>(this.globalVar.BASE_URL + 'api/UserManagement/GetPermissionChangeHistory?applicationId=' + applicationId + '&enterpriseId=' + enterpriseId + '&userId=' + userId + '&roleId=' + roleId + '&appRoleId=' + appRoleId + '&appVersionId=' + appVersionId, { constraints: constraints, sortPageFilters: gridpsFilterItem, commonParameters: commonParameters, appPermissionModelObj: appPermissionModelObj, appVersionId: appVersionId });
  }
  public getUserRole(): Observable<boolean> {
    return this.httpService.get<boolean>(this.globalVar.BASE_URL + "api/app/getuserrole");
  }

  public getUserAppAdminRole(appId: number, appVersionId: number): Observable<boolean> {
    return this.httpService.get<boolean>(this.globalVar.BASE_URL + 'api/app/getUserAppAdminRole?appId=' + appId + '&appVersionId=' + appVersionId);
  }
  getUserListByAppIdUserIdList(applicationId: number, enterpriseId: number, userId: string, appRoleId: number, orgRoleId: number): Observable<any> {
    return this.httpService.get<any>(this.globalVar.BASE_URL + 'api/UserManagement/getAllUsersByAppAndEnterprise?appId=' + applicationId + '&enterpriseId=' + enterpriseId + '&userId=' + userId + '&appRoleId=' + appRoleId + '&orgRoleId=' + orgRoleId);
  }
  getAllTenants(): Observable<tenantInformation[]> {
    return this.httpService.get<tenantInformation[]>(this.globalVar.BASE_URL + 'api/UserManagement/getAllTenants');
  }
  getAllTenantsGrid(constraints: tenantInformation, gridpsFilterItem: GridPageSortFilters): Observable<any> {
    return this.httpService.post<any>(this.globalVar.BASE_URL + 'api/UserManagement/GetTenantGrid', { constraints: constraints, sortPageFilters: gridpsFilterItem });
  }
  removeTenants(id: number): Observable<number> {
    return this.httpService.get<number>(this.globalVar.BASE_URL + 'api/UserManagement/RemoveTenantById?id=' + id);
  }
  removeTenantUser(tenant: number, userid: string, id: number): Observable<number> {
    return this.httpService.get<number>(this.globalVar.BASE_URL + 'api/UserManagement/RemoveTenantOwner?tenantid=' + tenant + '&userId=' + userid + '&id=' + id);
  }
  addUpdateTenants(obj: tenantInformation): Observable<any> {
    return this.httpService.post<any>(this.globalVar.BASE_URL + 'api/UserManagement/AddUpdateTenants', obj).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getUserbyTenantId(objs: tenantUsersVM): Observable<{ item1: tenantUsersVM[], item2: number }> {
    return this.httpService.post<{ item1: tenantUsersVM[], item2: number }>(this.globalVar.BASE_URL + 'api/UserManagement/GetUserbyTenantId', objs)
  }

  getTenantUserModel(objs: tenantUsersVM): Observable<tenantUsersVM> {
    return this.httpService.post<tenantUsersVM>(this.globalVar.BASE_URL + 'api/UserManagement/getTenantUserModel', objs)
  }
  saveTenantUsers(obj: EnterpriseUserSaveModel): Observable<number> {
    return this.httpService.post<number>(this.globalVar.BASE_URL + 'api/UserManagement/SaveTenantUsers', obj)
  }
  removeUserTenant(id: number, userid: string): Observable<number> {
    return this.httpService.get<number>(this.globalVar.BASE_URL + 'api/UserManagement/RemoveUserTenantById?id=' + id + '&userid=' + userid)
  }
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
  public downloadPermissionSectionData(filter: AppPermissionsVM, isDownloadExcel: boolean): Observable<any> {
    return this.httpService.get(this.globalVar.BASE_URL + "api/UserManagement/DownloadPermissionSectionData?filter=" + JSON.stringify(filter) + "&isDownloadExcel=" + isDownloadExcel, { observe: 'response', responseType: 'blob' }).pipe(map(resInner => {

      if (isDownloadExcel) {
        saveAs(resInner.body, "Application User File" + new Date() + ".xlsx");
      }
      else {
        saveAs(resInner.body, "Application User File" + new Date() + ".PDF");
      }
      return resInner;
    }))
  }
  public getLoginUserTenant(): Observable<userTenantInfo[]> {
    return this.httpService.get<userTenantInfo[]>(this.globalVar.BASE_URL + "api/app/GetUserTenat");

  }

  public loginUserTenant(obj: userTenantInfo): Observable<any> {
    return this.httpService.post<any>(this.globalVar.BASE_URL + "api/app/loginUserTenant", obj).pipe(
      retry(1),
      catchError(this.handleError)
    );

  }
  getTenantUserList(constraints: AspnetuserList, gridpsFilterItem: GridPageSortFilters, commonParameters: CommonParameters): Observable<any> {
    return this.httpService.post<any>(this.globalVar.BASE_URL + 'api/UserManagement/GetTenantUserList', { constraints: constraints, sortPageFilters: gridpsFilterItem, commonParameters: commonParameters });
  }
  getUtilizationReport(): Observable<any> {
    return this.httpService.get(this.globalVar.BASE_URL + 'api/UserManagement/GetUtilizationReport');
  }
  getCostAnalyticsReport(startdate: string, enddate: string): Observable<any> {
    return this.httpService.get(this.globalVar.BASE_URL + 'api/UserManagement/GetCostAnalyticsReport?StartDate=' + startdate + '&EndDate=' + enddate);
  }
  public checkUserExist(email: string): Observable<AspnetuserList> {
    return this.httpService.get<AspnetuserList>(this.globalVar.BASE_URL + "api/UserManagement/checkUserExist?email=" + email);

  }
  getAllUserActivityLog(constraints: userActivityLogVM, gridpsFilterItem: GridPageSortFilters, commonParameters: CommonParameters): Observable<any> {
    return this.httpService.post<any>(this.globalVar.BASE_URL + 'api/UserManagement/GetAllUserActivityLog', { constraints: constraints, sortPageFilters: gridpsFilterItem, commonParameters: commonParameters });
  }
  public DownloadUserActivityLogData(filter: userActivityLogVM, isDownloadExcel: boolean): Observable<any> {
    return this.httpService.get(this.globalVar.BASE_URL + "api/UserManagement/DownloadUserActivityLogData?filter=" + JSON.stringify(filter) + "&isDownloadExcel=" + isDownloadExcel, { observe: 'response', responseType: 'blob' }).pipe(map(resInner => {

      if (isDownloadExcel) {
        saveAs(resInner.body, "User Activity log" + new Date() + ".xlsx");
      }
      else {
        saveAs(resInner.body, "User Activity log" + new Date() + ".PDF");
      }
      return resInner;
    }))
  }
  saveAspnetUserWithTenantOwner(currentUser: AspnetuserVM): Observable<AspnetuserList> {
    return this.httpService.post<AspnetuserList>(this.globalVar.BASE_URL + 'api/UserManagement/SaveAndUpdateTenantAdmin', currentUser).pipe(map((resp => {
      return resp;
    })));
  }
  public getUserTenantInfo(): Observable<any> {
    return this.httpService.get<any>(this.globalVar.BASE_URL + "api/app/GetUserTenantInfo");

  }
  public checkIsAlreadyInvitationSent(userId: string): Observable<boolean> {
    return this.httpService.get<boolean>(this.globalVar.BASE_URL + "api/UserManagement/CheckIsAlreadyInvitationSent?userId=" + userId);
  }
  getTenantProviders(): Observable<AuthenticationSchemes[]> {
    return this.httpService.get<AuthenticationSchemes[]>(this.globalVar.BASE_URL + 'api/UserManagement/GetTenantProviders');
  }
  getUserTenantRole(): Observable<string>
  {
    return this.httpService.get(this.globalVar.BASE_URL + 'api/usermanagement/getUserTenantRole', { responseType:'text' });
  }
}
