import { GlobalVariable } from "./globalService";
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from "rxjs";
import { ApplicationDocuments, FinalizeFilesModel, FinalizeFilesWithUpdateModel } from "../models/commonControl.model";
import { Injectable } from "@angular/core";
import { map, switchMap } from "rxjs/operators";
import { saveAs } from 'file-saver';
import { GlobalDialogService } from "../global-dialog/global-dialog.service";
@Injectable()
export class CommonControlManager {

  constructor(private httpService: HttpClient, private globalVar: GlobalVariable, private dialogService: GlobalDialogService) {
  }
  private isStarted: boolean = false;
  private isRunning: boolean = false;
  public getUploadURL(): string {
    return this.globalVar.BASE_URL + "api/commoncontrol/addUploadedFilesToTable";
  }
  public addUploadedFilesToTable(formData: any) {

    return this.httpService.post<ApplicationDocuments>(this.globalVar.BASE_URL + "api/commoncontrol/AddUploadedFileToTable", formData, { reportProgress: true, observe: 'events' })
  }
  public addUploadedFilesToTableDynamic(formData: any, controllerName: string, actionName:string) {

    return this.httpService.post<{ item1: ApplicationDocuments, item2: any }>(this.globalVar.BASE_URL + "api/" + controllerName + "/" + actionName + "", formData, { reportProgress: true, observe: 'events' })
  }
  public finalizeFiles(obj: FinalizeFilesModel): Observable<boolean> {
    return this.httpService.post<boolean>(this.globalVar.BASE_URL + "api/commoncontrol/finalizeFiles", obj).pipe(map(res => {
      if (res != false) {
        if (obj.fileIds == null) {
          obj.fileIds = [];
        }
        else {
          obj.fileIds.length = 0;
        }
      }
      return res;
    }))
  }
  public finalizeFilesWithAttachment(obj: FinalizeFilesWithUpdateModel): Observable<boolean> {
    return this.httpService.post<boolean>(this.globalVar.BASE_URL + "api/commoncontrol/FinalizeFilesWithAttachment", obj).pipe(map(res => {
      if (res != false) {
        if (obj.fileIds == null) {
          obj.fileIds = [];
        }
        else {
          obj.fileIds.length = 0;
        }
      }
      return res;
    }))
  }
  public deleteTemporaryFiles(uploadConfig: any): Observable<boolean> {
    return this.httpService.get<boolean>(this.globalVar.BASE_URL + "api/commoncontrol/deleteTemporaryFiles?appId=" + uploadConfig.appId + "&modelName=" + uploadConfig.modelName);
  }
  public getUploadedFiles(appId: number, modelName: string, fkey: number, entityId: number=0, versionId: number=0, userId: string='', fkeyQuestion: number=0, fkeyRowIndex: number=0, fkeyControl:number=0): Observable<ApplicationDocuments[]> {
    return this.httpService.get<ApplicationDocuments[]>(this.globalVar.BASE_URL + "api/commoncontrol/getUploadedFiles?appId=" + appId + "&modelName=" + modelName + "&fkey=" + fkey + "&entityId=" + entityId + "&versionId=" + versionId + "&userId=" + userId + "&fkeyQuestion=" + fkeyQuestion + "&fkeyRowIndex=" + fkeyRowIndex + "&fkeyControl=" + fkeyControl);
  }
  public deleteFile(fileId: number): Observable<boolean> {
    return this.httpService.get<boolean>(this.globalVar.BASE_URL + "api/commoncontrol/deleteFile?fileId=" + fileId);
  }
  public deleteFileByFileIds(fileId: number[]): Observable<boolean> {
    // return this.httpService.get<boolean>(this.globalVar.BASE_URL + "api/commoncontrol/deleteFileByFileIds?fileIds=" + fileId);
    return this.httpService.post<boolean>(this.globalVar.BASE_URL + 'api/commoncontrol/deleteFileByFileIds', fileId);
  }
  public downloadFile(fileId: number): Observable<any> {
    return this.httpService.get<ApplicationDocuments>(this.globalVar.BASE_URL + "api/commoncontrol/getfileinfo?fileId=" + fileId).pipe(switchMap(res => {
      return this.httpService.get(this.globalVar.BASE_URL + "api/commoncontrol/DownloadFile?fileId=" + fileId, { observe: 'response', responseType: 'blob' }).pipe(map(resInner => {
        //var blob = new Blob([resInner.blob()], { type: res.extention })
        saveAs(resInner.body, res.fileName);
        return resInner;
      }))
    }))
  }
  public downloadAuditTrailFile(fileId: number): Observable<any> {
    return this.httpService.get<ApplicationDocuments>(this.globalVar.BASE_URL + "api/commoncontrol/GetAuditTrailFileInfo?fileId=" + fileId).pipe(switchMap(res => {
      return this.httpService.get(this.globalVar.BASE_URL + "api/commoncontrol/DownloadAuditTrailFile?fileId=" + fileId, { observe: 'response', responseType: 'blob' }).pipe(map(resInner => {
        //var blob = new Blob([resInner.blob()], { type: res.extention })
        saveAs(resInner.body, res.fileName);
        return resInner;
      }))
    }))
  }

  public filterControlData(URL: string, postedData: any):Observable<any> {
   return this.httpService.post<any>(URL, postedData);
  }
  //item1 is status 0=InProgress, 1=completed, -1 cancelled
  //item2 is entityId that is completed
  //item3 and message to be shown in snackbar
  //item4 = 1 terminate loop
  private transactionChecker(): Observable<{ item1: number, item2: number, item3: string, item4: number }> {
    return this.httpService.get<{ item1: number, item2: number, item3: string, item4: number }>(this.globalVar.BASE_URL + "api/commoncontrol/transactionChecker").pipe(map((rr) => {
      if (rr.item1 == 1 || rr.item1 == -1) {
        this.initimateSubscriber.next({ enterprise: rr.item2, message: rr.item3, status: 1 });
        this.dialogService.showAlertMessage(rr.item3, "Completed", 10000);
      }
      if (rr.item4 == 1) {
        this.isRunning = false;
      }
      else {
        this.httpHitter.next();
      }
      return rr;
    }));
  }

  private httpHitter: Subject<void> = new Subject<void>();
  private initimateSubscriber: Subject<{ enterprise: number, message: string, status: number }> = new Subject<{ enterprise: number, message: string, status: number }>();
  public startTransactionChecker(): Subject<{ enterprise: number, message: string }> {
    if (!this.isStarted) {           
      this.httpHitter.pipe(map(rr => { this.isStarted = true; this.isRunning = true; return rr; }),switchMap(() => this.transactionChecker())).subscribe();
      this.httpHitter.next();
    }
    if (!this.isRunning) {
      this.httpHitter.next();
    }
    return this.initimateSubscriber;
  }

  public getIsUserOrAppAdmin(appid: number, versionid: number): Observable<boolean> {
    return this.httpService.get<boolean>(this.globalVar.BASE_URL + "api/commoncontrol/GetLoginIsUserOrAppAdmin?appid=" + appid + "&versionId=" + versionid);
  }
  public getLoginIsUserRole(): Observable<number> {
    return this.httpService.get<number>(this.globalVar.BASE_URL + "api/commoncontrol/GetLoginIsUserRole");
  }
  
  public DownloadSurveyHelper(): Observable<any> {
    return this.httpService.get(this.globalVar.BASE_URL + "api/commoncontrol/DownloadSurveyHelper", { observe: 'response', responseType: 'blob' }).pipe(map(resInner => {
         
      saveAs(resInner.body, "9am Survey Helper" + new Date() + ".PDF");

      return resInner;

    }))
  }
  public downloadFileFromFolder(filePath: string): Observable<any> {
      return this.httpService.get(this.globalVar.BASE_URL + "api/commoncontrol/DownloadFileFromFolder?filePath=" + filePath, { observe: 'response', responseType: 'blob' }).pipe(map(resInner => {
        saveAs(resInner.body, "User Template" + new Date() + ".xlsx");
        return resInner;
      }))
  }
  public checkUserSurveyAdmin(): Observable<boolean> {
    return this.httpService.get<boolean>(this.globalVar.BASE_URL + "api/commoncontrol/CheckUserIsSurveyAdmin");
  }
} 
