import { GlobalVariable } from "../services/globalService";
import { AspnetuserList, ApplicationRolesVM, tenantInformation, tenantUsersVM, userTenantInfo, AppPermissionsVM, AppPermissionsModelVM, AspnetuserVM, userActivityLogVM } from "../models/appModel";
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from "rxjs";
import { map, catchError, retry } from "rxjs/operators";
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { GridPageSortFilters, CommonParameters } from "../models/commonControl.model";
import { DirectoryModel, DirectoryUserListVM } from "../models/directoryModel";
@Injectable(
  {
    providedIn: 'root'
  }
)
export class DirectoryService {

  constructor(private globalVar: GlobalVariable, private httpService: HttpClient) {
  }
  getAllApplicationUserList(constraints: AspnetuserList, gridpsFilterItem: GridPageSortFilters, commonParameters: CommonParameters, applicationId: number, enterpriseId: number, userId: string, appVersionId: number): Observable<any> {
    return this.httpService.post<any>(this.globalVar.BASE_URL + 'api/Directory/GetAllDirectoryEntityUsers?applicationId=' + applicationId + '&enterpriseId=' + enterpriseId + '&userId=' + userId  + '&appVersionId=' + appVersionId, { constraints: constraints, sortPageFilters: gridpsFilterItem, commonParameters: commonParameters });
  }
  getEntityUploadStatus(constraints: DirectoryModel, gridpsFilterItem: GridPageSortFilters, enterpriseId: number, userTimeZone:number): Observable<any> {
    return this.httpService.post<any>(this.globalVar.BASE_URL + 'api/Directory/GetEntityUploadStatus?enterpriseId=' + enterpriseId + '&userTimeZone=' + userTimeZone, { constraints: constraints, sortPageFilters: gridpsFilterItem});
  }
  generateUserTemplateExcel(enterpriseId: number) {
    return this.httpService.get(this.globalVar.BASE_URL + "api/Directory/GenerateDirectoryUserTemplateExcel?enterpriseId=" + enterpriseId, { observe: 'response', responseType: 'blob' }).pipe(map(resInner => {
      //var blob = new Blob([resInner.blob()], { type: res.extention })
      saveAs(resInner.body, "User Template.xlsx");
      return resInner;
    }))
  }
  checkIfOldUploadIsRunning(enterpriseId: number): Observable<boolean> {
    return this.httpService.get<boolean>(this.globalVar.BASE_URL + "api/Directory/checkIfOldUploadIsRunning?enterpriseId=" + enterpriseId);
  }
  cancelTransaction(enterpriseId: number): Observable<boolean> {
    return this.httpService.get<boolean>(this.globalVar.BASE_URL + "api/Directory/cancelTransaction?enterpriseId=" + enterpriseId);
  }
  public checkUserRole(appId: number, versionId: number, enterpriseId: number): Observable<boolean> {
    return this.httpService.get<boolean>(this.globalVar.BASE_URL + 'api/Directory/CheckUserRole?applicationId=' + appId + "&versionId=" + versionId + "&enterpriseId=" + enterpriseId);
  }

  public downloadDirectoryStatusData(filter: DirectoryModel, isDownloadExcel: boolean, userTimeZone:number): Observable<any> {
    return this.httpService.get(this.globalVar.BASE_URL + "api/Directory/DownloadDirectoryStatusData?filter=" + JSON.stringify(filter) + "&isDownloadExcel=" + isDownloadExcel + '&userTimeZone=' + userTimeZone, { observe: 'response', responseType: 'blob' }).pipe(map(resInner => {

      if (isDownloadExcel) {
        saveAs(resInner.body, "Directory Status File" + new Date() + ".xlsx");
      }
      else {
        saveAs(resInner.body, "Directory Status File" + new Date() + ".PDF");
      }
      return resInner;
    }))
  }
  public downloadDirectoryContactData(filter: AspnetuserList, isDownloadExcel: boolean, enterpriseId: number): Observable<any> {
    return this.httpService.get(this.globalVar.BASE_URL + "api/Directory/DownloadDirectoryContactData?filter=" + JSON.stringify(filter) + "&isDownloadExcel=" + isDownloadExcel + "&enterpriseId=" + enterpriseId, { observe: 'response', responseType: 'blob' }).pipe(map(resInner => {

      if (isDownloadExcel) {
        saveAs(resInner.body, "Directory Contact File" + new Date() + ".xlsx");
      }
      else {
        saveAs(resInner.body, "Directory Contact File" + new Date() + ".PDF");
      }
      return resInner;
    }))
  }

  AddUpdateDirectoryUpload(obj: DirectoryUserListVM): Observable<number> {
    return this.httpService.post<number>(this.globalVar.BASE_URL + "api/Directory/AddUpdateDirectoryUpload", obj).pipe(map((resp => {
      return resp;
    })));
  }
  CancelDirectoryUploadProcess(obj: DirectoryUserListVM): Observable<number> {
    return this.httpService.post<number>(this.globalVar.BASE_URL + "api/Directory/CancelTemporaryUploadedProcess", obj);
  }
  public sendEmailToTenantUser(userId: string): Observable<any> {
    return this.httpService.get<any>(this.globalVar.BASE_URL + 'api/UserManagement/SendEmailToTenantUser?userid=' + userId);
  }
  public setSurveyAdmin(userId: string, isSurveyAdmin: boolean): Observable<any> {
    return this.httpService.get<any>(this.globalVar.BASE_URL + 'api/UserManagement/SetSurveyAdmin?userid=' + userId + "&isSurveyAdmin=" + isSurveyAdmin);
  }
}
