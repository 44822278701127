export class NccicauseGroup {
  public nccicauseGroupId: number;
  public nccicauseGroupName: string;
  public parentNccicauseGroupId: number;
}
export class BodyParts {
  public id: number;
  public bodyPartName: string;
  public isActive: boolean;
}
export class ClaimEvaluationPeriod {
  public id: number;
  public evaluationPeriod: string;
  public orderNumber: number;
  public isSelected: boolean;
}
export class RecordedIncident {

  public incidentId: number;
  public incidentDescription: string;
  public incidentTypeId: number;
  public incidentSubTypeId: number;
  public incidentSubTypeOther: string;
  public locationId: number;
  public quarter: number;
  public dayOfMonth: number;
  public month: number;
  public year: number;
  public incidentTime: string;
  public entityId: number
  public isActive: number;
  public isDayAwayFromWork: boolean
  public action: string;
  public isClaimReported: boolean;
  public claimStatus: number;
  public totalIncurred: number;
  public totalOutstanding: number;
  public totalPaid: number;
  public financialYear: number;

  public isClaimReportedStr: string;
  public isDayAwayFromWorkStr: string;

  //public incidentDate: Date;
}
export class NCCICauseGroupVM extends NccicauseGroup {
  public children: NccicauseGroup[];
}
export class ClaimStatusVM {
  public statusId: number;
  public statusName: string
  public isDefault: boolean;
  public orderNumber: number
}
export class RecordedIncidentVM extends RecordedIncident {
  public incidentTypeName: string;
  public incidentTypeSubName: string;
  public incidentDate: Date;
  public locationAddress: string;
  public claimStatusString: string
  public trir: number;
  public dart: number;
  public totalHoursWorked: number;
  public totalHoursWorkedOld: number;
  public trirrate: number;
  public dartrate: number;
  public isHourWorkedTBEnabledTemporary: boolean=true;
  public quarterYear: string;
  //public evaluationDate: Date;
  public bodyPartId: number;
  public recordedIncidentClaim: RecordedIncidentClaim[] = [];
  public claimNumber: string;
}
export class RecordedIncidentClaim {
  public id: number;
  public incidentId: number; 
  public totalIncurred: number;
  public totalPaid: number;
  public evaluationPeriod: number;
  public claimStatus: number;
  public PaidCostPristine: boolean = true;
  public PaidCostValidationdFired: boolean = false;
}
export class RecordedIncidentWithLocationVM {
  public locationName: string;
  public locationId: number;
  public isTotalIncident: boolean;
  public recordedIncidentVM: RecordedIncidentVM[] = [];
  public locationTotal: number;
}
export class IncidentFilterModelVM {
  constructor() {
    this.filterDartCases = 0;
    this.isClaimReportedCase = 0;
    this.descriptionOptions = 0;
    this.actionOptions = 0;
  }
  public selectedIncidentTypes: number[];
  public selectedIncidentSubTypes: number[];
  public selectedLocations: number[];
  public filterDartCases: number;
  public selectedClaimStatus: number[]
  public isClaimReportedCase: number;
  public descriptionOptions: number;
  public actionOptions: number;
  public fromDate: Date;
  public toDate: Date;
  public skip: number
  public take: number
  public searchString: string
  public enterpriseId: number;
  public selectedYearQuarters: YearQuarterModel[];

  public sortyBy: string;
  public sortDirection: string;
  public evaluationPeriod: number;
}
export class YearQuarterModel {
  public year: number
  public quarters: QuarterModel[] = [];
  public quarterNumber: number
  public yearQuarterText: string;

}
export class QuarterModel {
  public quarterNumber: number
  public isCY: boolean;
  public isChecked: boolean;
  public isDart: boolean
  public isTrir: boolean;
  public isHourWorked: boolean;
}
export class yearModel {
    public year: number;
    public isChecked: boolean;
}
export class IncidentType {
  public incidentTypeId: number;
  public incidentName: string;
  public description: string;
}
export class EnterpriseLocations {

  public locationid: number
  public entityid: number;
  public streetAdddress: string
  public cityId: string
  public stateId: number;
  public countryId: number;
  public zipCode: string
  public otherCountryName: string
  public otherStateName: string
  public logitude: string
  public latitude: string
  public isActive: boolean;
}
export class EnterpriseLocationsVM extends EnterpriseLocations {
  public stateName: string
  public countryName: string
  public completeAddress: string
}
export class QuarterlyHourWorkedVM {

  public trir: number;
  public dart: number;
  public quarter: number;
  public totalHoursWorked: number;
  public entityId: number
  public financialYear: number;
  public trirrate: number;
  public dartrate: number;

}
export class WCLossesYearlyDataReportVM {
  public entityId: number;
  public year: number;
  public avgNoOfEmployeeForTheYear: number;
  public totalClaims: number;

  public claimsPer100Employee: number;
  public totalAmountOfClaims: number;
  public costPerClaim: number;
  public locationId: number;
  public totalIncurredCosts: number;
  public estimatedCostPerClaim: number;
  public ultimateCostPerHour: number;
  public totalHoursWorked: number;
  public totalPaidCost: number;
  public totalOutStandingCost: number;
  public isDisabledForUser: boolean;
  public noOfReportedClaims: number;
  public incurredCostPerClaim: number;
  public totalUltimateCost: number;
    public ultimateCostperClaim: number;
    public isSavedValue: boolean;
  public evaluationPeriod: number;
}

export class SafetyLossesYearlyDataReportWithLocationVM {
  public locationName: string;
  public locationId: number;
  public isTotalClaim: boolean;
  public safetyLossesYearlyDataReportVM: WCLossesYearlyDataReportVM[] = [];
}
export class SafetyProgramsVM {
    constructor() {

        this.attachmentsOptions = 0;
    }
  public programId: number;
  public enterpriseId: number;
  public programinfo: string; 
  public programDate: Date;
  public skip: number
  public take: number
  public searchString: string
  public sortyBy: string;
  public sortDirection: string;
  public dayOfMonth: number;
  public month: number;
  public year: number;
  public strProgramDate: string;
  public attachments: fileModel[];
  public attachmentsOptions: number;
  public id: number;
}

export class FileOutputModel {
  public files: fileModel[];
  public newFileIds: number[];
}
export class fileModel {
  public data: any;
  public inProgress: boolean;
  public progress: number;
  public isCompleted: boolean;
  public name: string;
  public url: string;
  public fileId: number;
  public keyId: number;
  public isError: boolean;

}

export class safetyReport {
  public quarter: number;
  public year: number;
  public isDart: boolean;
  public isTrir: boolean;
  public isHourWorked: boolean;
}

export class QuesstionAnswerVM
{
    public questionNumberId: number;
    public questionText: string;
    public questionSubtext: string
    public entityId: number;
    public orderNumber: number;
    public answerId: number;
    public auestionNumber :number
    public response: string;
    public appId: number;
    public isActive: boolean;
    public total: number;
   public totalQuestions: totalQuestions[];
    public versionId: number;
}
export class totalQuestions {

}

export class SafetyAnalysisFilterVM {

    public currencyId: number;
    public countryId: number;
    public levelId: number;
    public isActive: boolean;
    public yearQuarters: YearQuarterModel[];
    public filterdEnterprise: EnterpriseModelVM[];
    public safetyAnalysisEntityModel: SafetyAnalysisEntityModel[];

}
export class EnterpriseModel {

    public id: number;

    public name: string;
    public currencyId: number;
    public countryId: number;
    public isTestEnterprise: boolean;
    public levelId: number;
    public isActive: boolean;
    public tenantId: number;
    public currencyName: string;
    public countryName: string;
    public levelName: string;

}
export class EnterpriseModelVM extends EnterpriseModel {
    public parentId: number;
    public otherName: string;
    public levelName: string;
    public isCollapsed: boolean = true;
    public otherLevelId: number
    public otherLevelName: string
    public countryName: string;
    public isEditMode: boolean;
    public isParticipating: boolean;
    public isSelected: boolean = false;

}
export class SafetyAnalysisEntityModel {
    public  enterprise:number
    public Children: number[];
}
export class analysisInputModel {
    public selectedLevelId: number;
    public entityHierarchyIds: SafetyAnalysisEntityModel[];
    public selectedYearQuarter: YearQuarterModel[];
    public selectedYear: yearModel[];
    public selectedIncidentTypeId: number;
    public immediateParentAndChildren: SafetyAnalysisEntityModel[];
  public ishighlight: boolean;
  public structureId: number;
        }

export class EntityAnalysis {
    public entityId: number;
    public aggregateData: SafetyAnalysisAggregateModel[];
    public entityName: string;
    public status: number;
    public industryTRIRRate: number;
    public industryDARTRate: number;
    public isSavedValue: boolean;
    public ishighlight: boolean;
    public countryName: string;
    }
export class SafetyAnalysisAggregateModel {
    public year: number;
    public quarter: number; 
    public totalRecordableIncidents: number;
    public totalDARTIncidents: number;
    public hourWorked: number;
    public dartRate: number;
    public trirRate: number;
    public isSavedValue: boolean;
    public countryName: string;
}
 export class LookupCollectionVM {
     public lookupId: number;
     public lookupName: string;
     public parentLookupId: number;
     public parentId: number;
}
export class EntityAnalysisWCLosses {
    public aggregateWCData: WCLossesYearlyDataReportVM[]; 
    public entityId: number;
    public entityName: string;
    public status: number;
    public industryTRIRRate: number;
    public industryDARTRate: number;
    public isSavedValue: boolean;
    public ishighlight: boolean;

    }

