import { Component, OnInit } from '@angular/core';
import { userTenantInfo, tenantUsersVM } from '../../../models/appModel';
import { UserManagementService } from '../../../services/user-management.service';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { LoaderService } from '../../../services/loadingService';
import { GlobalDialogService } from '../../../global-dialog/global-dialog.service';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-select-tenant',
  templateUrl: './select-tenant.component.html',
  styleUrls: ['./select-tenant.component.css']
})
export class SelectTenantComponent implements OnInit {
  tenantinfoData: userTenantInfo[] = [];
  public tenantID: number = 0;
  public selectedEmail: string = "";
  public password: string = "";
  pswdRequired: boolean = false;
  pswdPristine: boolean = true;
  closeResult: string;
  public result: string;
  modalReference: NgbModalRef;
  constructor(private userService: UserManagementService, private modalService: NgbModal, private router: Router, private location: Location, private loadingService: LoaderService, public dialog: GlobalDialogService
) { }

  ngOnInit() {
    return this.userService.getLoginUserTenant().subscribe(res => {
      this.tenantinfoData = res;
      this.tenantID = this.tenantinfoData.filter(a => a.isSelected == true)[0].id;
      this.selectedEmail = res[0].email;
    });
  }

 
  openConfirmation(content, a: any, id: number) {
    this.dialog.openDialog('Are you sure?', 'Are you sure you want to switch this Tenants?', [{ option: 'Yes', optionValue: 1 }, { option: 'No', optionValue: 2 }]).pipe(switchMap(res => {
      if (Number(res) == 1) {
        this.open(content, a, id);
        return of(0);
      }
      else {
        return of(0);
      }
    })).subscribe((resp: any) => {
      try {

      }
      catch (e) { }
      
    })
  }
  open(content, a: any,id:number) {
    a.srcElement.blur();
    a.preventDefault();
    this.tenantID = id;
    this.modalReference = this.modalService.open(content);
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  onChangepassword() {
    this.pswdPristine = false;
    if (this.password == null ||
      (this.password != null && this.password.trim() == "")) {
      this.pswdRequired = true;
    }
    else {
      this.pswdRequired = false;
    }

  }
  userLogin(content) {
    let isError: boolean = false;
    this.pswdPristine = false;
    let obj = new userTenantInfo();
    obj.email = this.selectedEmail;
    obj.id = this.tenantID;
    obj.password = this.password;
    if (this.password == null || (this.password != null && this.password.trim() == "")) {
      isError = true;
      this.pswdRequired = true;
    }
    else {
      this.pswdRequired = false;
    }
    if (!isError) {
      this.userService.loginUserTenant(obj).subscribe(a => {
        if (a == 1) {
          this.loadingService.blockUI();
          //this.modalService.dismissAll();
          this.modalService.dismissAll(content);
          this.router.navigate(['/mainapp/dashboard']);
          this.loadingService.unblock();
        }
        if (a == 0) {
          this.result = "invalid login attempt";
        }
      });
    }
  }



}
