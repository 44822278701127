import { NgModule } from '@angular/core';
import { MatNativeDateModule, MatRippleModule, MatDatepickerModule, MatCardModule, MatProgressSpinnerModule, MatSortModule, MatTableModule, MatIconModule, MatToolbarModule, MatProgressBarModule, MatExpansionModule, MatSnackBarModule, MatMenuModule, MatDialogModule, MatButtonModule, MatAutocompleteModule, MatInputModule, MatSelectModule, MatCheckboxModule, MatTooltipModule, MatListModule, MatPaginatorModule, MatSidenavModule, MatFormFieldModule, MatSlideToggleModule, MatStepperModule } from '@angular/material';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { MatTabsModule } from '@angular/material/tabs';
import { SurveyAddEditComponent } from '../../survey/survey-designer/survey-list/survey-list.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ChartsModule, ThemeService } from 'ng2-charts';

@NgModule({

  exports: [
    MatMenuModule,
    MatDialogModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatInputModule,
    MatSelectModule,
    MatNativeDateModule,
    MatRippleModule,
    MatDatepickerModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    MatIconModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatSelectInfiniteScrollModule,
    MatTabsModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatListModule,
    MatPaginatorModule,
    MatSidenavModule,
    NgxMaterialTimepickerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    ChartsModule,
    MatSlideToggleModule,
    MatStepperModule
  ],
  providers: [ThemeService],
})
export class MaterialModule { }
