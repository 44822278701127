import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-global-dialog',
    templateUrl: './global-dialog.component.html',
    styleUrls: ['./global-dialog.component.css']
})
export class GlobalDialogComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<GlobalDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {

    }
    onClick(control: any) {
        control.tbValue = this.data.tbValue;
        this.dialogRef.close(control);
    }

}
