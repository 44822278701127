//import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
//import { AppRoutingModule, routingComponent } from '../shared-module/component-module/app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '../shared-module/material-module/material.module';
import { SharedModule } from '../shared-module/shared.module';
import { NumberCommadirDirective } from './numbercomma.directive';
import { DisablePasteDirective } from './disablepaste.directive';
import { FilterArrayPipe } from './filter-array.directive';
import { TagifyControl } from './tagify.directive';
import { NumberDirective } from './numbers-only.directive';
import { ProfileImageDirective } from './profile-image.directive';
import { NgInit } from './ngInit.directive';
import { DateRangeDirective } from './date-range-custom';
@NgModule({
  
  declarations: [
    NumberCommadirDirective,
    DisablePasteDirective,
    FilterArrayPipe,
    TagifyControl,
    NumberDirective,
    ProfileImageDirective,
    NgInit,
    DateRangeDirective
  ],
  imports: [
  //  //BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
  SharedModule,
  //  //BrowserAnimationsModule,
  //  //MaterialModule,
  ],
  exports: [
    NumberCommadirDirective,
    DisablePasteDirective,
    FilterArrayPipe,
    TagifyControl,
    NumberDirective,
    ProfileImageDirective,
    NgInit,
    DateRangeDirective
  ]
  //bootstrap: [AppComponent]
})
export class CommonDirectiveModule { }
