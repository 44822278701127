import { Directive, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { UserManagementService } from '../services/user-management.service';

@Directive({
  selector: '[profile-image]',
  host: {
    '[src]': 'sanitizedImageData'
  }
})
export class ProfileImageDirective implements OnInit {
  imageUrl: any;
  sanitizedImageData: any;
  @Input() name: string;
  @Input() value: string;
  @Input('image-loader') image_thumb: boolean;
  constructor(protected _sanitizer: DomSanitizer, private userService: UserManagementService) { }

  ngOnInit() {
    this.imageUrl = 'assets/images/generic_user_image.png';
    this.sanitizedImageData = this._sanitizer.bypassSecurityTrustUrl(this.imageUrl);
    if (this.name != null) {
      this.userService.getUserPhoto(this.name, this.value, this.image_thumb).subscribe(rr => {
        if (this.image_thumb) { this.imageUrl = 'assets/images/generic_user_image_thumb.png';}
        if (rr != "") {this.imageUrl = 'data:image/png;base64,' + rr; }
          this.sanitizedImageData = this._sanitizer.bypassSecurityTrustUrl(this.imageUrl);
      });
    } 
  }
}  
