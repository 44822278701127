import { Directive, Output, OnInit, EventEmitter } from '@angular/core';
@Directive({
  selector: '[ngInit]'
})
export class NgInit implements OnInit {
  @Output() ngInit: EventEmitter<any> = new EventEmitter();
  ngOnInit() {
    if (this.ngInit) { this.ngInit.emit() }
  }
}
