import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable()
export class commonFunctionService {
  public convertToUTC(_date: Date) {
    try { return new Date(Date.UTC(_date.getFullYear(), _date.getMonth(), _date.getDate())); }
    catch (error) { return _date }
  }
  public convertToLocal(_date: Date) {
    try {
      var newDate = new Date(_date.getTime() - _date.getTimezoneOffset() * 60 * 1000);
      return newDate;
    }
    catch (error) { return _date }
  }
  public getFormattedDate(firstDate: Date): string {
    var date = new Date(firstDate);
    var newdate = new Date(date);
    var dd = newdate.getDate();
    var mm = newdate.getMonth() + 1;
    var y = newdate.getFullYear();
    var startHours = String(newdate.getHours())
    if (startHours.length < 2) {
      startHours = "0" + startHours;
    }
    var startMin = String(newdate.getMinutes())
    if (startMin.length < 2) {
      startMin = "0" + startMin;
    }
    var startSeconds = String(newdate.getSeconds())
    if (startSeconds.length < 2) {
      startSeconds = "0" + startSeconds;
    }
    var someFormattedDate = mm + '/' + dd + '/' + y + " " + startHours + ":" + startMin + ":" + startSeconds;
    return someFormattedDate;
  }
}
