import { GlobalVariable } from "./globalService";
import { AppModel, AppAddEditModel, AppPageModel, ApplicationErrorVM, AppInstructionVM, AppPermissionsVM, AppPermissionsModelVM, ApplicationVersionVM, userTenantInfo, AspnetuserList, AspnetuserVM } from "../models/appModel";
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of, Subject, ObservableInput } from "rxjs";
import { map, catchError, retry } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { ApplicationRolesVM, EnterpriseStructure, EmailSessionVM } from "../models/enterpriseModel";
import { GridPageSortFilters, CommonParameters } from "../models/commonControl.model";
import { saveAs } from 'file-saver';
import { AppTemplateUser } from "../models/configSettingsModel";
import { ApplicationsVM } from "../models/Timetrack";
@Injectable()
export class AppManager {
  constructor(private globalVar: GlobalVariable, private httpService: HttpClient) {
  }
  allApplications: AppModel[] = [];
  isApplicationListToBeRefreshed = false;
  returnedObservableStream: Observable<{ item1: AppModel[], item2: number }>;
  isEmissionPending: boolean = false;
  changeInAppOrVersion: Subject<{ appId: number, versionId: number }> = new Subject<{ appId: number, versionId: number }>();
  addRemoveOfApplication: Subject<boolean> = new Subject<boolean>();
  setSelectedApplicationAndVersion(appId: number, versionId: number) {
    this.changeInAppOrVersion.next({ appId: appId, versionId: versionId });
  }
  getApplicationsById(id: number): Observable<AppAddEditModel> {
    var returnedObservableStream = this.httpService.get<AppAddEditModel>(this.globalVar.BASE_URL + 'api/app/getapplicationById?applicationId=' + id).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  getApplicationById(id: number): Observable<AppModel> {
    var returnedObservableStream = this.httpService.get<AppModel>(this.globalVar.BASE_URL + 'api/app/getapplicationById?applicationId=' + id).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  getApplications(currPage: number, take: number, search: string): Observable<AppPageModel> {
    var returnedObservableStream = this.httpService.get<AppPageModel>(this.globalVar.BASE_URL + 'api/app/getapplications?currPage=' + currPage + "&take=" + take + "&search=" + search).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  saveApplication(applicationObj: AppAddEditModel) {
    var returnedObservableStream = this.httpService.post<number>(this.globalVar.BASE_URL + 'api/app/SaveApplication', applicationObj).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  getAllApplications() {
    var returnedObservableStream = this.httpService.get<AppModel[]>(this.globalVar.BASE_URL + 'api/app/getallapplications').pipe(map(resp => {
      return resp;
    }));
    return returnedObservableStream;
  }
  removeApp(appId: number): Observable<boolean> {
    return this.httpService.get<boolean>(this.globalVar.BASE_URL + 'api/app/RemoveApp?applicationId=' + appId);
  }
  reOrderApplications(appModels: AppModel[]) {
    var returnedObservableStream = this.httpService.post<boolean>(this.globalVar.BASE_URL + 'api/app/reorderapps', appModels).pipe(map(resp => {
      return resp;
    }));
    return returnedObservableStream;
  }
  setStructureForApp(appId: number, appVersionId: number, structureId: number, tenantId: number): Observable<boolean> {
    var returnedObservableStream = this.httpService.post<boolean>(this.globalVar.BASE_URL + 'api/app/setStructureForApp', { appId: appId, appVersionId: appVersionId, structureId: structureId, tenantId: tenantId }).pipe(map(resp => {
      return resp;
    }));
    return returnedObservableStream;
  }
  getParticipatingEnterprises(appId: number): Observable<number[]> {
    var returnedObservableStream = this.httpService.get<number[]>(this.globalVar.BASE_URL + 'api/app/getParticipatingEnterprises?appId=' + appId).pipe(map(resp => {
      return resp;
    }));
    return returnedObservableStream;
  }
  getParticipatingEnterpriseIdsByTenantId(appId: number, tenantId: number, versionid: number): Observable<number[]> {
    var returnedObservableStream = this.httpService.get<number[]>(this.globalVar.BASE_URL + 'api/app/getParticipatingEnterpriseIdsByTenantId?appId=' + appId + "&tenantId=" + tenantId + "&appVersionId=" + versionid).pipe(map(resp => {
      return resp;
    }));
    return returnedObservableStream;
  }

  //GetApplicationErrorLog(logfilters: ApplicationErrorVM): Observable<{ item1: ApplicationErrorVM[], item2: number }> {
  //  return this.httpService.post<{ item1: ApplicationErrorVM[], item2: number }>(this.globalVar.BASE_URL + 'api/app/GetApplicationErrorLog', logfilters).pipe(
  //    retry(1),
  //    catchError(this.handleError)
  //  );
  //}
  GetApplicationErrorLog(constraints: ApplicationErrorVM, gridpsFilterItem: GridPageSortFilters): Observable<any> {
    return this.httpService.post<any>(this.globalVar.BASE_URL + 'api/app/GetApplicationErrorLog', { constraints: constraints, sortPageFilters: gridpsFilterItem });
  }
  GetApplicationErrorById(erroerId: number): Observable<ApplicationErrorVM> {

    return this.httpService.get<ApplicationErrorVM>(this.globalVar.BASE_URL + 'api/app/GetApplicationErrorById?errorId=' + erroerId);//.pipe(map((resp => {
    //return resp;
    //   })));
  }
  saveApplicationInstructions(obj: AppInstructionVM): Observable<number> {
    return this.httpService.post<number>(this.globalVar.BASE_URL + 'api/survey/SaveSurveyInstruction', obj);
  }
  getappInstructions(id: number): Observable<AppModel> {
    return this.httpService.get<AppModel>(this.globalVar.BASE_URL + 'api/app/getapplicationById?applicationId=' + id);
  }
  setAppListRefreshedVariable() {
    this.isApplicationListToBeRefreshed = true;
    this.addRemoveOfApplication.next(true);
  }
  getappSurveyList(objfilter: AppModel): Observable<{ item1: AppModel[], item2: number }> {
    var search = objfilter.searchString;
    search = (search == null ? "" : search).trim().toLowerCase();
    var skip = objfilter.skip;
    var take = objfilter.take;
    objfilter.searchString = "";
    if (this.allApplications == null || this.allApplications.length == 0 || this.isApplicationListToBeRefreshed) {
      if (!this.isEmissionPending) {
        this.isEmissionPending = true;
        this.returnedObservableStream = this.httpService.post<{ item1: AppModel[], item2: number }>(this.globalVar.BASE_URL + 'api/app/GetAppSurveyList', objfilter).pipe(map(resp => {
          if (this.isApplicationListToBeRefreshed) {
            this.isApplicationListToBeRefreshed = false;
          }
          this.allApplications = resp.item1.filter(d => { return true });
          resp.item1 = this.allApplications.filter(dd => dd.name != null && (dd.name.trim().toLowerCase().indexOf(search) > -1 || (dd.description != null && dd.description.trim().toLowerCase().indexOf(search) > -1) || (dd.createdBy != null && dd.createdBy.trim().toLowerCase().indexOf(search) > -1) || search == "")).slice(skip, skip + take);
          this.isEmissionPending = false;
          return resp;
        }))
      }
      else {
        return this.returnedObservableStream;
      }
    }
    else {
      var appList;
      var count = 0;
      if (objfilter.appTypeFilter == 0) {
        appList = this.allApplications.filter(dd => dd.name != null && (dd.name.trim().toLowerCase().indexOf(search) > -1 || (dd.description != null && dd.description.trim().toLowerCase().indexOf(search) > -1) || (dd.createdBy != null && dd.createdBy.trim().toLowerCase().indexOf(search) > -1) || search == "")).slice(skip, skip + take);
        count = this.allApplications.length;
      }
      else {
        appList = this.allApplications.filter(dd => dd.name != null && dd.isApp == objfilter.appTypeFilter && (dd.name.trim().toLowerCase().indexOf(search) > -1 || (dd.description != null && dd.description.trim().toLowerCase().indexOf(search) > -1) || (dd.createdBy != null && dd.createdBy.trim().toLowerCase().indexOf(search) > -1) || search == "")).slice(skip, skip + take);
        count = this.allApplications.filter(dd => dd.name != null && dd.isApp == objfilter.appTypeFilter && (dd.name.trim().toLowerCase().indexOf(search) > -1 || (dd.description != null && dd.description.trim().toLowerCase().indexOf(search) > -1) || (dd.createdBy != null && dd.createdBy.trim().toLowerCase().indexOf(search) > -1) || search == "")).length;//appList.length;
      }
      //var count = this.allApplications.length;
      let appPageModelTemp: { item1: AppModel[], item2: number } = { item2: 0, item1: [] };
      appPageModelTemp.item1 = appList;
      appPageModelTemp.item2 = count;
      this.returnedObservableStream = of(appPageModelTemp);
    }
    return this.returnedObservableStream;
  }
  getAllApplicationsPreparedList(): AppModel[] {
    return this.allApplications;
  }
  getAppInstruction(appid: number, versionId): Observable<AppInstructionVM> {
    return this.httpService.get<AppInstructionVM>(this.globalVar.BASE_URL + 'api/survey/GetSurveyInstruction?appId=' + appid +
      '&versionId=' + versionId);
  }

  getAllAppRoles(): Observable<ApplicationRolesVM[]> {
    var returnedObservableStreamRolesList = this.httpService.get<ApplicationRolesVM[]>(this.globalVar.BASE_URL + 'api/app/GetApplicationsRoles').pipe(map(resp => {
      return resp;
    }));
    return returnedObservableStreamRolesList;
  }

  getApplicationPermissions(applicationid: number, userId: string): Observable<AppPermissionsModelVM> {//Observable<AppPermissionsVM> {
    var returnedObservableStreamPermissionList = this.httpService.get<AppPermissionsModelVM>(this.globalVar.BASE_URL + 'api/app/GetApplicationPermissionSettings?applicationId=' + applicationid +
      '&userId=' + userId).pipe(map(resp => {
        return resp;
      }));
    return returnedObservableStreamPermissionList;
  }

  getEnterpriseApplicationOrganizationalPermissions(applicationid: number, userId: string, appVersionId: number): Observable<AppPermissionsModelVM> {//Observable<AppPermissionsVM> {
    var returnedObservableStreamPermissionList = this.httpService.get<AppPermissionsModelVM>(this.globalVar.BASE_URL + 'api/app/GetEnterpriseApplicationOrganizationalPermissionSettings?applicationId=' + applicationid +
      '&userId=' + userId + '&appVersionId=' + appVersionId).pipe(map(resp => {
        return resp;
      }));
    return returnedObservableStreamPermissionList;
  }

  saveApplicationPermission(applicationPermissionObj: AppPermissionsVM) {
    var returnedObservableStream = this.httpService.post<number>(this.globalVar.BASE_URL + 'api/app/SaveApplicationPermissions', applicationPermissionObj).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  saveApplicationOwnerPermission(applicationPermissionObj: AppPermissionsVM) {
    var returnedObservableStream = this.httpService.post<number>(this.globalVar.BASE_URL + 'api/app/SaveApplicationOwnerPermission', applicationPermissionObj).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  saveNonEntityBasedApplicationPermission(applicationPermissionObj: AppPermissionsVM) {
    var returnedObservableStream = this.httpService.post<number>(this.globalVar.BASE_URL + 'api/app/SaveNonEntityBasedApplicationPermission', applicationPermissionObj).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  getAppSurveyVersionList(appid: number): Observable<ApplicationVersionVM[]> {
    return this.httpService.get<ApplicationVersionVM[]>(this.globalVar.BASE_URL + 'api/app/GetAppSurveyVersionList?id=' + appid);
  }
  

  deleteApplicationPermission(appId: number, roleId: number, userId: string) {
    var returnedObservableStream = this.httpService.get<boolean>(this.globalVar.BASE_URL + 'api/app/DeleteApplicationPermission?applicationId=' + appId + '&roleId=' + roleId + '&userId=' + userId).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }

  deleteApplicationOrganizationPermission(appId: number, orgaziationalRoleId: number, applicationRoleId: string) {
    var returnedObservableStream = this.httpService.get<boolean>(this.globalVar.BASE_URL + 'api/app/DeleteApplicationOrganizationalPermission?applicationId=' + appId + '&orgRoleId=' + orgaziationalRoleId + '&appRoleId=' + applicationRoleId).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }

  deleteEnterpriseApplicationRolePermission(appId: number, appVersionId: number, appRoleId: number, userId: string, enterpriseId: number, isAppAdmin: boolean, comments: string) {
    var returnedObservableStream = this.httpService.get<number>(this.globalVar.BASE_URL
      + 'api/app/DeleteEnterpriseApplicationRolePermission?appId=' + appId + '&appVersionId=' + appVersionId + '&appRoleId=' + appRoleId + '&userId=' + userId + '&enterpriseId=' + enterpriseId + '&isAppAdmin=' + isAppAdmin + '&comments=' + comments)
      .pipe(map(resp => {
        return resp;
      }))
    return returnedObservableStream;
  }

  deleteEnterpriseApplicationOrganizationalPermission(appId: number,appVersionId:number, orgRoleId: number, userId: string, enterpriseId: number, comments: string) {
    var returnedObservableStream = this.httpService.get<number>(this.globalVar.BASE_URL
      + 'api/app/DeleteEnterpriseApplicationOrganizationalPermission?appId=' + appId + '&appVersionId=' + appVersionId+ '&orgRoleId=' + orgRoleId
      + '&userId=' + userId + '&enterpriseId=' + enterpriseId + '&comments=' + comments)
      .pipe(map(resp => {
        return resp;
      }))
    return returnedObservableStream;
  }

  getApplicationOrganizationalPermissions(applicationid: number,appVersionId:number): Observable<AppPermissionsModelVM> {
    var returnedObservableStreamPermissionList = this.httpService.get<AppPermissionsModelVM>(this.globalVar.BASE_URL + 'api/app/GetApplicationOrganizationalRolePermissions?applicationId=' + applicationid + '&appVersionId=' + appVersionId).pipe(map(resp => {
      return resp;
    }));
    return returnedObservableStreamPermissionList;
  }

  saveApplicationOrganizationalPermission(applicationPermissionObj: AppPermissionsVM) {
    var returnedObservableStream = this.httpService.post<boolean>(this.globalVar.BASE_URL + 'api/app/SaveApplicationOrganizationalPermissions', applicationPermissionObj).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }

  saveApplicationRolePermission(applicationPermissionObj: AppPermissionsVM) {
    var returnedObservableStream = this.httpService.post<number>(this.globalVar.BASE_URL + 'api/app/SaveApplicationRolePermissions', applicationPermissionObj).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  updateApplicationRolePermission(applicationPermissionObj: AppPermissionsVM) {
    var returnedObservableStream = this.httpService.post<number>(this.globalVar.BASE_URL + 'api/app/UpdateApplicationRolePermission', applicationPermissionObj).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  getApplicationInheritedPermissions(applicationid: number, userId: string, appVersionId: number): Observable<AppPermissionsModelVM> {
    var returnedObservableStreamPermissionList = this.httpService.get<AppPermissionsModelVM>(this.globalVar.BASE_URL + 'api/app/GetInheritedPermissionSettings?applicationId='
      + applicationid + '&userId=' + userId + '&appVersionId=' + appVersionId).pipe(map(resp => {
        return resp;
      }));
    return returnedObservableStreamPermissionList;
  }

  getUserGridFilterDropdownsData(appId: number, enterpriseId: number, orgRoleId: number, appRoleId: number, userId: string, appVersionId: number): Observable<AppPermissionsModelVM> {
    var returnedObservableStream = this.httpService.get<AppPermissionsModelVM>(this.globalVar.BASE_URL + 'api/app/GetUserGridFilterDropdownsData?appId=' + appId +
      "&enterpriseId=" + enterpriseId + "&orgRoleId=" + orgRoleId + "&appRoleId=" + appRoleId + "&userId=" + userId + "&appVersionId=" + appVersionId).pipe(map(resp => {
        return resp;
      }));
    return returnedObservableStream;
  }
  public getStucture(): Observable<any> {
    return this.httpService.get<EnterpriseStructure[]>(this.globalVar.BASE_URL + 'api/app/GetEnterpriseStructures');
  }
  public DownloadApplicationErrorData(filter: ApplicationErrorVM, isDownloadExcel: boolean): Observable<any> {
    return this.httpService.get(this.globalVar.BASE_URL + "api/app/DownloadApplicationErrorData?filter=" + JSON.stringify(filter) + "&isDownloadExcel=" + isDownloadExcel, { observe: 'response', responseType: 'blob' }).pipe(map(resInner => {

      if (isDownloadExcel) {
        saveAs(resInner.body, "Enterprise Location File" + new Date() + ".xlsx");
      }
      else {
        saveAs(resInner.body, "Enterprise Location File" + new Date() + ".PDF");
      }
      return resInner;
    }))
  }
  public getUserRole(): Observable<any> {
    return this.httpService.get(this.globalVar.BASE_URL + 'api/app/GetUserRole');
  }
  public getLoginUserTenant(): Observable<userTenantInfo[]> {
    return this.httpService.get<userTenantInfo[]>(this.globalVar.BASE_URL + "api/app/GetUserTenat");
  }
  getAppAndsurveyVersionList(appid: number): Observable<ApplicationVersionVM[]> {
    return this.httpService.get<ApplicationVersionVM[]>(this.globalVar.BASE_URL + 'api/app/GetAppAndsurveyVersionList?id=' + appid);
  }

  publihAppVersion(appId: number,versionId:number): Observable<number> {
    return this.httpService.get<number>(this.globalVar.BASE_URL + 'api/app/PublihAppVersion?applicationId=' + appId + "&versionId=" + versionId );
  }
  getAllApplicationUserList(constraints: AspnetuserList, gridpsFilterItem: GridPageSortFilters, commonParameters: CommonParameters, applicationId: number, enterpriseId: number, userId: string, roleId: number, appRoleId: number, appVersionId: number, selectedAppOwnerOption: boolean, selectedEnterpriseAdminOption: boolean): Observable<any> {
    return this.httpService.post<any>(this.globalVar.BASE_URL + 'api/app/GetAllApplicationUsersForEmailSend?applicationId=' + applicationId + '&enterpriseId=' + enterpriseId + '&userId=' + userId + '&roleId=' + roleId + '&appRoleId=' + appRoleId + '&selectedAppOwnerOption=' + selectedAppOwnerOption + '&selectedEnterpriseAdminOption=' + selectedEnterpriseAdminOption + '&appVersionId=' + appVersionId, { constraints: constraints, sortPageFilters: gridpsFilterItem, commonParameters: commonParameters });
  }
  GetApplicationFilters(): Observable<any> {
    return this.httpService.get<any>(this.globalVar.BASE_URL + 'api/app/GetApplicationFilters');
  }
  sendApplicationReleaseEmail(appEmailObj: AppTemplateUser): Observable<AppTemplateUser> {
    return this.httpService.post<AppTemplateUser>(this.globalVar.BASE_URL + 'api/ManageEmail/SendAppReleaseEmail', appEmailObj);
  }
  getAppEmailSession(constraints: EmailSessionVM, gridpsFilterItem: GridPageSortFilters, commonParameters: CommonParameters): Observable<any> {
    return this.httpService.post<any>(this.globalVar.BASE_URL + 'api/ManageEmail/GetEmailSession', { constraints: constraints, sortPageFilters: gridpsFilterItem, commonParameters: commonParameters });
  }
  getApplicationVersions(constraints: ApplicationVersionVM, gridpsFilterItem: GridPageSortFilters, commonParameters: CommonParameters): Observable<any> {
    return this.httpService.post<any>(this.globalVar.BASE_URL + 'api/app/GetApplicationVersionGrid', { constraints: constraints, sortPageFilters: gridpsFilterItem, commonParameters: commonParameters });
  }
  saveAppVersionTenantConfiguration(obj: ApplicationsVM): Observable<any> {
    return this.httpService.post<any>(this.globalVar.BASE_URL + 'api/app/SaveAppVersionTenantConfiguration',obj );
  }
  getTenantAppVersionList(constraints: ApplicationVersionVM, gridpsFilterItem: GridPageSortFilters, tenantId: number): Observable<any> {
    return this.httpService.post<any>(this.globalVar.BASE_URL + 'api/app/GetTenantApplicationVersionGrid?tenantId=' + tenantId, { constraints: constraints, sortPageFilters: gridpsFilterItem });
  }
  public checkUserExist(email: string,tenantid:number): Observable<AspnetuserList> {
    return this.httpService.get<AspnetuserList>(this.globalVar.BASE_URL + "api/app/checkUserExist?email=" + email + '&tenantid=' + tenantid);

  }
  SaveAppVersionTenantConfigSetting(obj: ApplicationsVM): Observable<any> {
    return this.httpService.post<any>(this.globalVar.BASE_URL + 'api/app/SaveAppVersionTenantConfigSetting', obj);
  }
  
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
