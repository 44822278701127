import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule, routingComponent } from './shared-module/component-module/app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './shared-module/material-module/material.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ProviderModule } from './shared-module/provider-module/provider.module';
import { SharedModule } from './shared-module/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonControlModule } from './common-controls/common-control.module';
import { CommonDirectiveModule } from './directives/common-directive.module';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';





@NgModule({
  //entryComponents: [
  //  SurveyAddEditComponent,
  //  //VersionDialogComponent
  //],
  declarations: [
    AppComponent,
    routingComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    MaterialModule,
    CKEditorModule,
    ProviderModule,
    CommonControlModule,
    CommonDirectiveModule,

    //MonacoEditorModule.forRoot()
  ],
  providers: [
    GuidedTourService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
