import { Subject } from "rxjs";

export class GridPagingModel {
    public take: number=2;
    public search: string="";
    public currentPage: number;
    public eventName: string = "default";
    public routedPageNumber: number;
    public showGrid: Subject<boolean> = new Subject<boolean>();
    public forceFullReinitialize: Subject<number> = new Subject<number>();
}
