import { EnterpriseModelVM, userLisVM, AuditTrialLogVM } from "./enterpriseModel";
import { ApplicationDocuments } from "./commonControl.model";
export class AppModel {

  public applicationId: number;

  public name: string;
  public description: string;
  public isEnterpriseBased: boolean;
  public isReleased: boolean;
  public hierarchyStructure: number;
  public isActive: boolean;
  public configuration: string;
  public createdBy: string;
  public modifiedBy: string;
  public instructions: string;
  public isApp: number;
  public skip: number;
  public take: number;
  public searchString: string;
  public appTypeFilter: number = 0;
  public createdDate: Date;
  public modifiedDate: Date;
  public nameType: string;
  public applicationVersions: ApplicationVersionVM[];
  public appAssignedPermissions: ApplicationRolesVM[];
  public systemApp: boolean;
  public imageUrl: string;
}
export class AppAddEditModel {
  public applicationId: number;
  public name: string;
  public description: string;
  public isEnterpriseBased: boolean;
  public isReleased: boolean;
  public hierarchyStructure: number;
  public isActive: boolean;
  public configuration: string;
  public createdBy: string;
  public modifiedBy: string;
  public instructions: string;
  public isApp: number;
  public applicationVersions: ApplicationVersionVM[];
  public allRolesList: ApplicationRolesVM[] = [new ApplicationRolesVM()];
  public appAssignedPermissions: number[] = [];
  public systemApp: boolean;
  public isTimetrackApp: boolean;
  public startDate: Date;
  public endDate: Date;
  public isNoEndDate: boolean;
}
export class AppPageModel {
  public appList: AppModel[];
  public totalCount: number;
}
export class AspnetuserList {
  public id: string;
  public userName: string;
  public email: string;
  public phoneNumber: string;
  public totalCount: number;
  public skip: number
  public take: number
  public searchString: string
  public sortyBy: string;
  public sortDirection: string;
  public appId: number;
  public applicationId: number;
  public firstName: string;
  public lastName: string;
  public middleName: string;
  public completeName: string;
  public userID: string;
  public status: string;
  public isActive: boolean;
  public accountCreateDate: Date;
  public lastLoginDate: Date;
  public lastActivityDate: Date;
  public lastPasswordSet: Date;
  public password: string;
  public confirmPassword: string;
  public isPasswordNeverExpires: boolean;
  public userActivity: UserActivityInfoVM;
  public errors: string;
  public IsActiveInt: number;
  public applicationRoleId: number;
  public organizationalRoleId: number;
  public applicationRole: string;
  public organizationalRole: string;
  public applicationName: string;
  public isExistUserInTenant: boolean;
  public userEmail: string;
  public appVersionId: number;
  public permissions: string;
  public tenantId: number;
  public enterpriseList: string;
  public externalProviderIds: string;
  public isExternalUser: boolean;
  public isSurveyAdmin: boolean;
}

export class AspnetuserVM {
  public id: string;
  public userName: string;
  public email: string;
  public phoneNumber: string;
  public totalCount: number;
  public skip: number
  public take: number
  public searchString: string
  public sortyBy: string;
  public sortDirection: string;
  public appId: number;
  public applicationId: number;
  public firstName: string;
  public lastName: string;
  public middleName: string;
  public completeName: string;
  public userID: string;
  public status: string;
  public isActive: boolean;
  public accountCreateDate: Date;
  public lastLoginDate: Date;
  public lastActivityDate: Date;
  public lastPasswordSet: Date;
  public password: string;
  public confirmPassword: string;
  public isPasswordNeverExpires: boolean;
  public userActivity: UserActivityInfoVM;
  public errors: string;
  public IsActiveInt: number;
  public applicationRoleId: number;
  public organizationalRoleId: number;
  public applicationRole: string;
  public organizationalRole: string;
  public applicationName: string;
  public isExistUserInTenant: boolean;
  public userEmail: string;
  public tenantId: number;
  public externalProviderIds: string;
  public isExternalUser: boolean;
  public isSurveyAdmin: boolean;
}


export class UserActivityInfoVM {
  public userid: string;
  public accountCreateDate: Date;
  public lastLoginDate: Date;
  public lastActivityDate: Date;
  public lastLockedDate: Date;
  public status: string;
  public lock: boolean;
  public accountCreateBy: string
  public lastActivityCreatedBy: string;
  public lastPasswordSetBy: string;
  public lastPasswordSet: Date;
}

export class ApplicationErrorVM {
  public applicationErrorNo: number;
  public logDate: Date;
  public level: string;
  public logger: string;
  public userName: string;
  public machineName: string;
  public message: string;
  public exception: string;
  public url: string;
  public take: number;
  public skip: number;
  public searchtext: string;
  public sortDirection: string;
  public sortyBy: string;
}

export class ApplicationRolesVM {
  public roleId: number;
  public name: string
  public description: string
  public tenantId: number;
  public isActive: boolean;
  public createdBy: string;
  public createdDate: Date;
  public modifiedBy: string;
  public modifiedDate: Date;
  public take: number;
  public skip: number;
  public searchtext: string;
  public isSelected: boolean;
  public isDeleted: boolean;
  public enterpriseUserId;
}
export class questionnaireVM {
  public questionnaireId: number;
  public name: string;
  public version: string;
  public createdBy: string;
  public createdDate: Date;
  public modifiedBy: string;
  public modifiedDate: Date;
  public surveyId: number;
  public isActive: boolean;
  public edited: boolean;
  public isRequired: boolean;
}

export class ApplicationVersionVM {
  public applicationVersionId: number;
  public name: string;
  public version: string;
  public createdBy: string;
  public createdDate: Date;
  public modifiedBy: string;
  public modifiedDate: Date;
  public applicationId: number;
  public isActive: boolean;
  public edited: boolean;
  public isRequired: boolean;
  public isPublished: boolean
  public tenantId: number;
  public instructions: string;
  public statusType: string;
  public publishedBy: string;
  public publishedDate: Date;
  public skip: number;
  public take: number;
  public isRequiredForVersion: boolean;
}

export class AppInstructionVM {
  public listAppInstructions: ApplicationVersionVM[] = [];
  public statusList: SurveyInstructionStatus[] = [];
  public surveyInstructions: ApplicationVersionVM[] = [];
  public statusListArray: SurveyInstructionStatus[] = [];
  public AppId: number;
  public VersionId: number;
  public instructions: string;
}

export class SurveyInstructionStatus {
  public instructions: string;
  public statusType: string;
}

export class AppPermissionsVM {
  public id: number;
  public applicationId: number;
  public appVersionId: number;
  //public organizationalRoleIds: number[]=[];
  public organizationalRoleId: number;
  public userId: string;
  public type: string;
  public userEmail: string;
  //public applicationRoleIds: number[] = [];
  public applicationRoleId: number;
  public enterpriseId: number;
  public isAppAdmin: boolean;
  public isEnterpriseAdmin: boolean;
  public take: number;
  public skip: number;
  public comments: string;
}

export class AppPermissionsModel {
  public id: number;
  public applicationId: number;
  public appVersionId: number;
  public organizationalRoleId: number;
  public userId: string;
  public type: string;
  public applicationRoleId: number;
  public enterpriseId: number;
  public isAppAdmin: boolean = false;
  public comments: string;
  public isShowEditMode: boolean = false;
  public fileList: ApplicationDocuments[] = [];
  public enterpriseName: string;
  public orgRoleName: string;
  public appRoleName: string;
  public isChangedPermission: boolean = true;
}

export class AppPermissionsModelVM {
  public permissionSet: AppPermissionsModel[] = [];
  public userObj: AspnetuserList;
  public allRolesList: ApplicationRolesVM[] = [new ApplicationRolesVM()];
  public allApplicationRolesList: ApplicationRolesVM[] = [new ApplicationRolesVM()];
  public appModelList: AppModel[] = [new AppModel()];
  public enterpriseList: EnterpriseModelVM[] = [new EnterpriseModelVM()];
  public appOwner: AppPermissionsModel;
  public userList: userLisVM[] = [new userLisVM()];
  public auditTrailList: AuditTrialLogVM[] = [new AuditTrialLogVM()];
  public userEmail: string;
  public firstName: string;
  public lastName: string;
  public middleName: string;
  public userId: string;
  public isDeleteAvailable: boolean;
  public comments: string;
  public flag: boolean;
  public hierarchyEnterpriseList: EnterpriseModelVM[] = [new EnterpriseModelVM()];
  public appVersionList: ApplicationVersionVM[] = [new ApplicationVersionVM()];
  public phoneNumber: string = '';

}
export class tenantInformation {
  public id: number;
  public tenant: string;
  public tenantUrl: string;
  public createdDate: Date;
  public CreatedBy: string;
  public modifiedDate: Date;
  public modifiedBy: string;
  public isActive: boolean;
  public isRequired: boolean;
  public edited: boolean;
  public isSelected: boolean;
  public isDeleted: boolean;
  public userid: string;
  public tenantUserId: number;
  public userCount: number;
}
export class tenantUsersVM {
  public Id: number;
  public tenantId: number;
  public userId: string;
  public createdDate: Date;
  public createdBy: string;
  public modifiedBy: string;
  public modifiedDate: Date;
  public tenantName: string;
  public take: number;
  public skip: number;
  public searchText: string;
  public userName: string;
  public tenantList: tenantInformation[];
}

export class userTenantInfo {
  public id: number;
  public tenant: string;
  public isSelected: boolean;
  public userid: string;
  public email: string;
  public password: string;
  public tenantOwner: number;
  public tenantUsers: number;
  public tenantApplications: number;
  public superAdminCount: number;
}
export class userActivityLogVM {
  public activityLogNo: number
  public userName: string;
  public sessionId: string;
  public event: string;
  public activityTypeNo: string;
  public postActionCount: number
  public activityStartDate: Date;
  public activityEndDate: Date;
  public ipaddress: string;
  public ipaddressBinary: boolean;
  public pageUrl: string;
  public data: string;
  public browserType: string;
  public browserVersion: string;
  public isMobile: boolean;
  public os: string;
  public platform: boolean;
  public createDate: Date;
  public updateDate: Date;
  public locationNo: number
  public isAutoExpired: boolean;
  public duration: string;
  public take: number;
  public skip: number;
  public strActivityStartDate: string;
  public durationLastActivity: string;
  public strActivityEndDate: string;
  public tenantId: number;
}
