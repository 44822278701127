import { GlobalVariable } from "./globalService";
import { HttpClient } from '@angular/common/http';
import { Observable, of, observable, Subject } from "rxjs";
import { EnterpriseModel, EnterprisePageModel, EnterpriseStructure, EnterpriseModelVM, LevelInfoModel, CurrencyModel, CountryModel, EnterpriseIdsAppVM, AuditTrialLogVM} from "../models/enterpriseModel";
import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { ApplicationsVM } from "../models/Timetrack";
import { GridPageSortFilters, CommonParameters } from "../models/commonControl.model";
import { saveAs } from 'file-saver';
import { tenantInformation, ApplicationVersionVM } from "../models/appModel";
import { enterpriseLocationListVM, PropertyBcplan } from "../models/LocationModel";
@Injectable()
export class EnterpriseManager {
  public enterpriseChange: Subject<any> = new Subject<any>();
  constructor(private globalVar: GlobalVariable, private httpService: HttpClient) {
  }
  //  getEnterprise(currPage: number, take: number, search: string): Observable<EnterprisePageModel> {
  //      var returnedObservableStream = this.httpService.get<EnterprisePageModel>(this.globalVar.BASE_URL + 'api/enterprise/GetEnterpriseInfo?currPage=' + currPage + "&take=" + take + "&search=" + search).pipe(map(resp => {
  //          return resp;
  //      }))
  //      return returnedObservableStream;
  //}
  getEnterprise(constraints: EnterpriseModel, gridpsFilterItem: GridPageSortFilters): Observable<any> {
    return this.httpService.post<any>(this.globalVar.BASE_URL + 'api/enterprise/GetEnterpriseInfo', { constraints: constraints, sortPageFilters: gridpsFilterItem });
  }
  getEnterpriseById(id: number): Observable<EnterpriseModel> {
    if (id != 0) {
      var returnedObservableStream = this.httpService.get<EnterpriseModel>(this.globalVar.BASE_URL + 'api/enterprise/GetEnterpriseById?enterpriseId=' + id).pipe(map(resp => {
        return resp;
      }))
      return returnedObservableStream;
    }
    else {
      var enterpriseModel = new EnterpriseModel();
      enterpriseModel.id = 0;
      return of(enterpriseModel);
    }
  }

  getLevelInfo() {
    var returnedObservableStream = this.httpService.get<LevelInfoModel[]>(this.globalVar.BASE_URL + 'api/enterprise/GetLevelInfo').pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  getCurrencies() {
    var returnedObservableStream = this.httpService.get<CurrencyModel[]>(this.globalVar.BASE_URL + 'api/enterprise/GetCurrencies').pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  getCountries() {
    var returnedObservableStream = this.httpService.get<CountryModel[]>(this.globalVar.BASE_URL + 'api/enterprise/GetCountries').pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }


  saveEnterprise(enterpriseObj: EnterpriseModel, isSaveForStructure: boolean, structureId: number) {
    var returnedObservableStream = this.httpService.post<number>(this.globalVar.BASE_URL + 'api/enterprise/SaveEnterprise?isSaveForStructure=' + isSaveForStructure + '&structureId=' + structureId, enterpriseObj).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  getEnterpriseStructure(): Observable<EnterpriseStructure[]> {
    var returnedObservableStream = this.httpService.get<EnterpriseStructure[]>(this.globalVar.BASE_URL + 'api/enterprise/getenterprisestructure').pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  getEnterpriseStructuresByTenant(tenantId: number): Observable<EnterpriseStructure[]> {
    var returnedObservableStream = this.httpService.get<EnterpriseStructure[]>(this.globalVar.BASE_URL + 'api/enterprise/getEnterpriseStructuresByTenantId?tenantId=' + tenantId).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  getEnterpriseStructuresByTenantId(tenantId: number): Observable<EnterpriseStructure[]> {
    return this.httpService.get<EnterpriseStructure[]>(this.globalVar.BASE_URL + 'api/enterprise/getEnterpriseStructuresByTenantId?tenantId=' + tenantId);
    //var returnedObservableStream = this.httpService.get<EnterpriseStructure[]>(this.globalVar.BASE_URL + 'api/enterprise/getEnterpriseStructuresByTenantId?tenantId=' + tenantId).pipe(map(resp => {
  }

  getEnterpriseHierarchy(parentId: number, currentSelectedStructure: number, tenantId: number): Observable<{ item1: EnterpriseModelVM[], item2: LevelInfoModel[] }> {

    var returnedObservableStream = this.httpService.get<{ item1: EnterpriseModelVM[], item2: LevelInfoModel[] }>(this.globalVar.BASE_URL + 'api/enterprise/gethierarchybyparentid?parentId=' + parentId + "&structureId=" + currentSelectedStructure + "&tenantId=" + tenantId).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  getEnterpriseHierarchyOfAnalysisApp(parentId: number, currentSelectedStructure: number, appId: number, appVersionId: number): Observable<EnterpriseModelVM[]> {

    var returnedObservableStream = this.httpService.get<EnterpriseModelVM[]>(this.globalVar.BASE_URL + 'api/enterprise/GetEnterpriseHierarchyOfAnalysisApp?parentId=' + parentId + "&structureId=" + currentSelectedStructure + "&appId=" + appId + "&appVersionId=" + appVersionId).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  getEnterpriseHierarchyByRole(parentId: number, currentSelectedStructure: number, appId: number, appVersionId: number): Observable<{ item1: EnterpriseModelVM[], item2: number }> {

    var returnedObservableStream = this.httpService.get<{ item1: EnterpriseModelVM[], item2: number }>(this.globalVar.BASE_URL + 'api/enterprise/GetEnterpriseHierarchyByRole?parentId=' + parentId + "&structureId=" + currentSelectedStructure + "&appId=" + appId + "&appVersionId=" + appVersionId).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  getUserAppEnterprises(appId: number): Observable<EnterpriseModelVM[]> {
    var returnedObservableStream = this.httpService.get<EnterpriseModelVM[]>(this.globalVar.BASE_URL + 'api/enterprise/getUserAppEnterprises?appId=' + appId).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  updateOrDeleteHierarchy(parentId: number, childId: number, structureId: number, option: number, tenantId: number) {
    var returnedObservableStream = this.httpService.get<number>(this.globalVar.BASE_URL + 'api/enterprise/updateordeletehierarchy?parentId=' + parentId + "&childId=" + childId + "&structureId=" + structureId + "&option=" + option + "&tenantId=" + tenantId).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  getEnterpriseMoveContent(childId: number, parentId: number, structureId: number, tenantId: number): Observable<any> {
    var returnedObservableStream = this.httpService.get<any>(this.globalVar.BASE_URL + 'api/enterprise/getHierarchyMoveContent?parentId=' + parentId + "&childId=" + childId + "&structureId=" + structureId + "&tenantId=" + tenantId).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  CheckEntityExsitInHierarchy(enterpriseId: number, structureId: number) {
    var returnedObservableStream = this.httpService.get<boolean>(this.globalVar.BASE_URL + 'api/enterprise/CheckEntityExsitInHierarchy?enterpriseId=' + enterpriseId + "&structureId=" + structureId).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  addOrEditEnterpriseStructure(structureId: number, structureName: string, tenantId: number) {
    var returnedObservableStream = this.httpService.get<number>(this.globalVar.BASE_URL + 'api/enterprise/AddOrEditStructure?structureId=' + structureId + "&structureName=" + structureName + "&tenantId=" + tenantId).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  deleteStructure(structureId: number, tenantId: number) {
    var returnedObservableStream = this.httpService.get<boolean>(this.globalVar.BASE_URL + 'api/enterprise/deleteStructure?structureId=' + structureId + "&tenantId=" + tenantId).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  changeEnterpriseNameLevelForStructure(structureId: number, level: number, name: string, enterpriseId: number, tenantId: number): Observable<{ item1: string, item2: number }> {
    var returnedObservableStream = this.httpService.get<{ item1: string, item2: number }>(this.globalVar.BASE_URL + 'api/enterprise/changeEnterpriseNameLevelForStructure?structureId=' + structureId + '&level=' + level + "&name=" + name + "&enterpriseId=" + enterpriseId + "&tenantId=" + tenantId).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  assignEnterpriseToApp(enterpriseAppVM: EnterpriseIdsAppVM) {

    var returnedObservableStream = this.httpService.post<boolean>(this.globalVar.BASE_URL + 'api/enterprise/AssignEnterpriseToApp', enterpriseAppVM).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  getParticipatedEnterpriseByAppId(appid: number, tenantId: number, appVersionId: number): Observable<{ item1: EnterpriseModelVM[], item2: LevelInfoModel[] }> {
    return this.httpService.get<{ item1: EnterpriseModelVM[], item2: LevelInfoModel[] }>(this.globalVar.BASE_URL + 'api/app/getParticipatedEnterpriseByAppId?appId=' + appid + '&tenantId=' + tenantId + '&appVersionId=' + appVersionId)
  }

  getAppParcipatingEnterprise(enterpriseObj: EnterpriseModelVM): Observable<{ item1: EnterpriseModelVM[], item2: number, item3: number[] }> {
    return this.httpService.post<{ item1: EnterpriseModelVM[]; item2: number, item3: number[] }>(this.globalVar.BASE_URL + 'api/enterprise/GetAppParcipatingEnterprise', enterpriseObj)
  }
  getAppNotParcipatingEnterprise(enterpriseObj: EnterpriseModelVM): Observable<{ item1: EnterpriseModelVM[], item2: number }> {
    return this.httpService.post<{ item1: EnterpriseModelVM[]; item2: number }>(this.globalVar.BASE_URL + 'api/enterprise/GetAppNotParcipatingEnterprise', enterpriseObj)
  }
  //getAppChangeHistory(filterObj: AuditTrialLogVM): Observable<{ item1: AuditTrialLogVM[], item2: EnterpriseModel[], item3: number }> {
  //  return this.httpService.post<{ item1: AuditTrialLogVM[]; item2: EnterpriseModel[]; item3: number }>(this.globalVar.BASE_URL + 'api/commonControl/GetAuditTrial', filterObj)
  //}
  getAppChangeHistory(constraints: AuditTrialLogVM, gridpsFilterItem: GridPageSortFilters, commonParameters: CommonParameters): Observable<any> {
    return this.httpService.post<any>(this.globalVar.BASE_URL + 'api/commonControl/GetAuditTrial', { constraints: constraints, sortPageFilters: gridpsFilterItem, commonParameters: commonParameters });
  }
  getEnterpriseBasedApp(): Observable<ApplicationsVM[]> {
    return this.httpService.get<ApplicationsVM[]>(this.globalVar.BASE_URL + 'api/app/GetEnterpriseBasedApp');
  }
  GetEnterpriseBasedAppVersion(appid: number): Observable<ApplicationVersionVM[]> {
    return this.httpService.get<ApplicationVersionVM[]>(this.globalVar.BASE_URL + 'api/app/GetEnterpriseBasedAppVersion?appId=' + appid);

  }
  public downloadEnterpriseData(filter: EnterpriseModelVM, isDownloadExcel: boolean): Observable<any> {
    return this.httpService.get(this.globalVar.BASE_URL + "api/enterprise/DownloadEnterpriseData?filter=" + JSON.stringify(filter) + "&isDownloadExcel=" + isDownloadExcel, { observe: 'response', responseType: 'blob' }).pipe(map(resInner => {

      if (isDownloadExcel) {
        saveAs(resInner.body, "Enterprise File" + new Date() + ".xlsx");
      }
      else {
        saveAs(resInner.body, "Enterprise File" + new Date() + ".PDF");
      }
      return resInner;
    }))
  }
  public downloadAuditTrailData(filter: AuditTrialLogVM, isDownloadExcel: boolean): Observable<any> {
    return this.httpService.get(this.globalVar.BASE_URL + "api/commonControl/DownloadAuditTrailData?filter=" + JSON.stringify(filter) + "&isDownloadExcel=" + isDownloadExcel, { observe: 'response', responseType: 'blob' }).pipe(map(resInner => {

      if (isDownloadExcel) {
        saveAs(resInner.body, "Enterprise Location File" + new Date() + ".xlsx");
      }
      else {
        saveAs(resInner.body, "Enterprise Location File" + new Date() + ".PDF");
      }
      return resInner;
    }))
  }
  public getAllTenant(): Observable<any> {
    return this.httpService.get<tenantInformation[]>(this.globalVar.BASE_URL + 'api/CommonControl/GetAllTenants');
  }
  getTestEnterprises(): Observable<EnterpriseModelVM> {
    var returnedObservableStream = this.httpService.get<EnterpriseModelVM>(this.globalVar.BASE_URL + 'api/enterprise/GetTestEnterprises').pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  getPropertyLocations(entityId: number, appVersionId: number, year:number): Observable<enterpriseLocationListVM[]> {
    return this.httpService.get<enterpriseLocationListVM[]>(this.globalVar.BASE_URL + 'api/enterprise/GetPropertyLocations?entityId=' + entityId + "&appVersionId=" + appVersionId + "&year=" + year);
  }
  getBCPlanData(obj: PropertyBcplan): Observable<any> {
    return this.httpService.post<any>(this.globalVar.BASE_URL + 'api/enterprise/GetBCPlanData',obj);
  }
  saveBCPlanData(obj: PropertyBcplan): Observable<PropertyBcplan> {
    return this.httpService.post<PropertyBcplan>(this.globalVar.BASE_URL + 'api/enterprise/SaveBCPlanData',obj);
  }
 // saveBCPlanData()
}
