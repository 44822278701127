
export class LocationModel {

    public locationid: number;
    public entityid: number; 
    public streetAdddress: string; 
    public cityId: string;
    public stateId: number;
    public countryId: number; 
    public zipCode: string;
    public otherCountryName: string; 
    public otherStateName: string;
    public logitude: string; 
    public latitude: string;
    public isActive: boolean; 
    public modifiedBy: string;
    public modifiedDate: Date; 
    public createdBy: string;
    public createdDate: Date;
    public stateName: string;
    public countryName: string;
  
}
export class enterpriseLocationListVM {

  public locationid: number;
  public locationIdGuid: string;
    public entityid: number;
    public streetAdddress: string;
    public cityId: string;
    public stateId: number;
    public countryId: number;
    public zipCode: string;
    public otherCountryName: string;
  public otherStateName: string;
  public countryName: string;
  public stateName: string;
    public logitude: string;
    public Latitude: string;
    public IsActive: boolean;
  public skip: number
  public take: number
  public searchString: string
  public sortyBy: string;
  public sortDirection: string;


}

export class EnterpriseLocationPageModel {
    public enterpriseList: LocationModel[];
    public locationid: number;
    public entityid: number;
    public streetAdddress: string;
    public cityId: string;
    public stateId: number;
    public countryId: number;
    public zipCode: string;
    public otherCountryName: string;
    public otherStateName: string;
    public logitude: string;
    public Latitude: string;
    public IsActive: boolean;
    public totalCount: number;
    public skip: number
    public take: number
    public searchString: string
    public sortyBy: string;
    public sortDirection: string;
  public enterpriseId: number;
  public appVersionId: number;
  public countryName: string;
  public stateName: string;
}


export class PropertyFields {

  public fieldName: string;
  public displayName: string;
  public type: string;
  public lookupId: number;
  public isHidden: boolean;
  public isHiddenInCurrent: boolean;
  public section: number;
  public row: number;
  public orderInCurrent: number;
  public sections: string;
  public sectionNumber: number;
  //  public List<Validation> ValidationIds :
  public minimumValue: string;
  public conditionsAndActions: ConditionAndAction[];
  public checkedValue: string;
  public unCheckedValue: string;
  public showFieldAsLabel: boolean;
  public showFieldAsLabelAlways: boolean;
  public isAlwaysDisabled: string;
  public currenyZeros: boolean;
  public show: string;
  public maxDate: string;
  public minDate: string;
  public orderInSummary: number;
  public isRequired: boolean;
  public isDisabled: boolean;
  public fieldRequired: boolean;
 }

export class Validation {
  public validationId: string;
  public settings: string;
  public validationMessage: string;
}
export class ConditionAndAction {
  public condition: string;
  public action: string;
  public identifier: string;
  public validationMessage: string;
  public validationActiveDefault: string;
  public validationActiveDefaultInitialValue: string
}
export class PropertyBcplan {
  public id: number;
  public enterpriseId: number;
  public applicationDocId: string;
  public isbcplan: boolean
  public isActive: boolean;
  public createdDate: Date;
  public modifiedDate: Date
  public createdBy: string;
  public modifiedBy: string;
  public year: number;
  public tenantId: number;
  public bcpPlan: number;
  public comments: string;
  public revisionDate: Date;
}

export class PropertyFourthQuestionData {
  public Id: number;
  public  mainYesNoResponse: string;
  public productComponentName: string;
  public supplierCustomerRoleId: number;
  public supplierCustomerName: string;
  public supplierCustomerCountryId: number;
  public  otherCountry: string;
  public supplierCustomerAddress: string;
  public supplierCustomerCityMunicipality: string;
  public supplierCustomerStateId: number;
  public  supplierCustomerContactPhone: string;
  public salesImpacted: string;
  public anyAlternateSource: number;
  public  delayInDays: string;
  public additionalCostPerDay: string;
  public rowIndex: number;
  public entityId: number;
  public year: number;
  public qno: number;
  public  locationId: string ;
  public reportingCurrencyId: number;
  public isForceFullyAdded: boolean;
  public isClosedLocation: boolean;
  public companyRoleId: number;
  public marmonCompanyName: string;
  public countryId: number;
  public address: string;
  public cityMuncipality: string;
  public stateId: number;
  public contactPhones: number;
}

export class ApplicationFeedbackVM {
  public id: number;
  public appId: number;
  public enterpriseId: number
  public issue: string;
  public feedback: string;
  public userId: string;
  public completeName: string;
  public email: string;
  public createdDate: Date;
  public versionId: number;
    }
