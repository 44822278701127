import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ListUsersConstraints, PagingVM, GridData, gridPageSortFilters } from "../question/question.component";
import { Observable } from "rxjs";

@Injectable()
export class QuestionService {

  myAppUrl: string = "";

  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.myAppUrl = baseUrl;
  }

  getRepoIssues(constraints: ListUsersConstraints, gridpsFilterItem: gridPageSortFilters): Observable<any> {
    return this._http.post<any>(this.myAppUrl + 'api/Question/Index', { constraints: constraints, sortPageFilters:gridpsFilterItem });
    //+'&sort=' + sort + '&order=' + order + '&page=' + currentPage + '&searchString=' + searchString);
  }
}
