
import { GlobalVariable } from "./globalService";
import { HttpClient } from '@angular/common/http';
import { Observable, of } from "rxjs";
import { EnterpriseModel, EnterprisePageModel, EnterpriseStructure, EnterpriseModelVM, LevelInfoModel, CurrencyModel, CountryModel, StateModel } from "../models/enterpriseModel";
import { LocationModel, enterpriseLocationListVM, EnterpriseLocationPageModel, ApplicationFeedbackVM } from '../models/LocationModel';
import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { saveAs } from 'file-saver';
import { GridPageSortFilters, CommonParameters } from "../models/commonControl.model";
import { LookupType, LookupCollection } from "../models/configSettingsModel";
@Injectable({
  providedIn: 'root'
})
export class LocationService {
  constructor(private globalVar: GlobalVariable, private httpService: HttpClient) {
  }
  getEnterpriseLocation(constraints: enterpriseLocationListVM, gridpsFilterItem: GridPageSortFilters, commonParameters: CommonParameters): Observable<any> {
    return this.httpService.post<any>(this.globalVar.BASE_URL + 'api/enterprise/GetEnterpriseLocationInfo', { constraints: constraints, sortPageFilters: gridpsFilterItem, commonParameters: commonParameters });
  }
  public deletegetEnterpriseLocation(locationid: number): Observable<any> {
    return this.httpService.get<any>(this.globalVar.BASE_URL + 'api/enterprise/DeleteLocationById?locationid=' + locationid);
  }
  GetEnterpriseLocationById(id: number, enterpriseid: number, appVersionId: number): Observable<LocationModel> {
    if (id != 0) {
      var returnedObservableStream = this.httpService.get<LocationModel>(this.globalVar.BASE_URL + 'api/enterprise/GetEnterpriseLocationById?locationId=' + id + "&enterpriseid=" + enterpriseid + "&appVersionId=" + appVersionId).pipe(map(resp => {
        return resp;
      }))
      return returnedObservableStream;
    }
    else {
      var enterpriseModel = new LocationModel();
      enterpriseModel.locationid = 0;
      return of(enterpriseModel);
    }
  }
  GetEnterprisePropertyLocationById(id: string, enterpriseid: number, appVersionId: number,year:number): Observable<any> {
    var returnedObservableStream = this.httpService.get<any>(this.globalVar.BASE_URL + 'api/enterprise/GetEnterprisePropertyLocationById?locationId=' + id + "&enterpriseid=" + enterpriseid + "&appVersionId=" + appVersionId + "&year=" + year).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  getLookupCollection(id:number): Observable<any> {
    return this.httpService.get<any>(this.globalVar.BASE_URL + 'api/enterprise/GetLookupCollection?entityId='+id);
  }
  getCountries() {
    var returnedObservableStream = this.httpService.get<CountryModel[]>(this.globalVar.BASE_URL + 'api/enterprise/GetCountriesLookup').pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  getStateByCountryid(countryId: number) {
    var returnedObservableStream = this.httpService.get<StateModel[]>(this.globalVar.BASE_URL + 'api/enterprise/GetCountryStates?CountryId=' + countryId).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }

  saveEnterpriseLocation(enterpriselocationObj: LocationModel, entityId: number, appVersionid: number) {
    var returnedObservableStream = this.httpService.post<number>(this.globalVar.BASE_URL + 'api/enterprise/SaveEnterpriseLocation?entityId=' + entityId + "&appVersionId=" + appVersionid, enterpriselocationObj).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  saveEnterprisePropertyLocation(enterpriselocationObj: any, entityId: number, appVersionid: number): Observable<any> {
    var returnedObservableStream = this.httpService.post<any>(this.globalVar.BASE_URL + 'api/enterprise/SaveEnterprisePropertyLocation?entityId=' + entityId + "&appVersionId=" + appVersionid, enterpriselocationObj).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  public deleteCurrentLocation(locationId: string, EntityId: number, IsNonLocation: boolean,section:string, year:number): Observable<any> {
    return this.httpService.get<any>(this.globalVar.BASE_URL + 'api/enterprise/DeleteCurrentLocation?locationid=' + locationId + "&EntityId=" + EntityId + "&isNonLocation=" + IsNonLocation + "&section=" + section + "&year=" + year);
  }
  public getEntityCurrency(entityid: number) {
    var returnedObservableStream = this.httpService.get<number>(this.globalVar.BASE_URL + 'api/enterprise/GetEntityCurrency?entityId=' + entityid).pipe(map(resp => {
      return resp;
    }))
    return returnedObservableStream;
  }
  public getNonLocationTabs(entityid: number, year: number): Observable<any> {
    return this.httpService.get<any>(this.globalVar.BASE_URL + 'api/enterprise/GetNonLocationTabs?entityId=' + entityid + "&year=" + year);
  }
  public getNonLocationDataBySectionAndRecord(section: number, recordId: string, enterpriseid: number, locationId: string, year: number): Observable<any> {
    return this.httpService.get<any>(this.globalVar.BASE_URL + 'api/enterprise/GetNonLocationDataBySectionAndRecord?section=' + section + "&recordId=" + recordId + "&enterpriseid=" + enterpriseid + "&locationId=" + locationId + "&year=" + year);
  }
  public savePropertyNonLocationData(obj: any, questionNumber: number, val: boolean, year:number): Observable<any> {
    var returnedObservableStream1 = this.httpService.post<any>(this.globalVar.BASE_URL + 'api/enterprise/SavePropertyNonLocationData?questionNo=' + questionNumber + "&year=" + year, obj).pipe(map(resp => {
      return resp;

    }))
    return returnedObservableStream1;
  }
  public getFeedBackAndQA(entityid: number, appid: number, versionid: number): Observable<any> {
    return this.httpService.get<any>(this.globalVar.BASE_URL + 'api/enterprise/GetFeedBack?entityId=' + entityid + "&applicationId=" + appid + "&versionId=" + versionid);
  }
  public saveFeedbackAnQA(obj: ApplicationFeedbackVM): Observable<any> {
    var returnedfeedbackObj = this.httpService.post<any>(this.globalVar.BASE_URL + 'api/enterprise/SaveFeedBack', obj).pipe(map(res => {
      return res;
    }))
    return returnedfeedbackObj;
  }
  public downLoadSurveyResponse(entityId: number, flag: number, userTimeZone: number): Observable<any> {
    return this.httpService.get(this.globalVar.BASE_URL + 'api/enterprise/PrintPropertyLocations?EntityId=' + entityId + " &flag=" + flag + " &userTimeZone=" + userTimeZone, { observe: 'response', responseType: 'blob' }).pipe(map(resInner => {
      saveAs(resInner.body, "Property Report" + new Date() + ".PDF");

      return resInner;
    }))
  }
  public downloadEnterpriseLocationData(filter: EnterpriseLocationPageModel, isDownloadExcel: boolean): Observable<any> {
    return this.httpService.get(this.globalVar.BASE_URL + "api/enterprise/DownloadEnterpriseLocationData?filter=" + JSON.stringify(filter) + "&isDownloadExcel=" + isDownloadExcel, { observe: 'response', responseType: 'blob' }).pipe(map(resInner => {

      if (isDownloadExcel) {
        saveAs(resInner.body, "Enterprise Location File" + new Date() + ".xlsx");
      }
      else {
        saveAs(resInner.body, "Enterprise Location File" + new Date() + ".PDF");
      }
      return resInner;
    }))
  }
}
