import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ElementRef, NgZone } from '@angular/core';
import { EnterpriseManager } from '../../services/enterpriseService';
import { LocationService } from '../../services/location.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { LocationModel } from '../../models/LocationModel';
import { EnterpriseModel, CountryModel, StateModel } from '../../models/enterpriseModel';
import { GridPagingModel } from '../../GridPagingModel';
import { forkJoin } from 'rxjs';
import { NgForm } from '@angular/forms';
import { LoaderService } from '../../services/loadingService';
import { MapsAPILoader, MouseEvent } from '@agm/core';
@Component({
  selector: 'app-enterprise-location-add-edit',
  templateUrl: './enterprise-location-add-edit.component.html',
  styleUrls: ['./enterprise-location-add-edit.component.css'],
  providers: [GridPagingModel]
})
export class EnterpriseLocationAddEditComponent implements OnInit {
  @ViewChild('enterpriseForm', { static: false }) public createEnterpriseForm: NgForm;
  @ViewChild('search', { static: false }) public searchElementRef: ElementRef;
  constructor(private enterpriseService: EnterpriseManager, private locationService: LocationService, private gridPageModel: GridPagingModel, private route: ActivatedRoute, private router: Router, private loadingService: LoaderService, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) {
    // this.loadGoogleMap(this.enterpriseLocationModelVM.latitude, this.enterpriseLocationModelVM.logitude);
  }
  enterpriseid: number;
  appVersionId: number;
  countryId: number;
  @Input() set enterpriselocationToEdit(enterpriselocationToEdit: any) {
    this.initializesetter(enterpriselocationToEdit);
  }
  @Output() closeForm = new EventEmitter();
  initializesetter(enterpriselocationToEdit: any) {
    if (enterpriselocationToEdit != undefined) {
      this.gridPageModel.showGrid.next(false);
      this.isEditMode = false;
      let recordId = 0;
      this.enterpriseid = 0;
      this.countryId = 2;
      if (enterpriselocationToEdit != null) {
        if (enterpriselocationToEdit.locationid != 0) {
          this.isEditMode = true;
          recordId = enterpriselocationToEdit.locationid;
          this.enterpriseid = enterpriselocationToEdit.entityid;

        }
        this.enterpriseid = enterpriselocationToEdit.entityid;
        this.appVersionId = enterpriselocationToEdit.appVersionId;
      }
      if (!this.isEditMode) {
        this.buttonMessage = "Add"
        this.countryId = 2;
      }
      else {
        this.buttonMessage = "Update"
      }
      this.fromString = "enterprise";
      setTimeout(() => {
        this.initializeForm(recordId, this.enterpriseid, this.appVersionId);
      },500);
    
    }
    else {
      this.loadGoogleMap(this.enterpriseLocationModelVM.latitude, this.enterpriseLocationModelVM.logitude);
    }
  }
  locationId: string;
  enterpriseLocationModelVM: LocationModel = new LocationModel();
  buttonMessage: string;
  fromString: string;
  countryData: CountryModel[];
  stateData: StateModel[];
  isEditMode: boolean;
  buttonClicked: string;
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  mapStreetAdddress: string;
  private geoCoder: any;
  ngOnInit() { }
  initializeForm(recordId: number, enterpriseid: number, appVersionId: number) {

    this.createEnterpriseForm.resetForm(true);
    forkJoin(this.locationService.GetEnterpriseLocationById(recordId, enterpriseid, appVersionId), this.locationService.getCountries()).subscribe(resp => {
      this.enterpriseLocationModelVM = resp[0];
      if (this.enterpriseLocationModelVM == null) {
        this.enterpriseLocationModelVM = new LocationModel();
        this.enterpriseLocationModelVM.locationid = 0;
        
      }
      if (this.enterpriseLocationModelVM.locationid > 0) {
        this.countryId = this.enterpriseLocationModelVM.countryId;
      }
      this.countryData = resp[1];
    
      this.getDropDownListforState(this.countryId);
      this.loadGoogleMap(this.enterpriseLocationModelVM.latitude, this.enterpriseLocationModelVM.logitude);
    });
  }
  getDropDownListforState(countryid: number) {
    this.locationService.getStateByCountryid(countryid).subscribe(resp => {
      this.stateData = resp;
    })
  }
  saveEnterprise() {
    var f = this.buttonClicked;
    this.loadingService.blockUI();
    this.locationService.saveEnterpriseLocation(this.enterpriseLocationModelVM, this.enterpriseid, this.appVersionId).subscribe(enterpriseId => {
      this.loadingService.unblock();
      if (f != "Save") {
        this.backToGrid();
      }
    })
  }
  backToGrid() {
    this.closeForm.emit();
  }
  setCurrentLocation(latitude, logitude) {
    if ('geolocation' in navigator) {
      // navigator.geolocation.getCurrentPosition((position) => {
      //this.latitude = position.coords.latitude;
      // this.longitude = position.coords.longitude;
      if (latitude != null && latitude != undefined && latitude != "0") {
        this.latitude = parseFloat(latitude);
      }
      else {
        this.latitude = 0;
      }
      if (logitude != null && logitude != undefined && logitude != "0") {
        this.longitude = parseFloat(logitude);
      }
      else {
        this.longitude = 0;
      }
      this.zoom = 15;
      this.getAddress(this.latitude, this.longitude);
      //  });
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
        //  window.alert('No results found');
        }
      } else {
        //window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  markerDragEnd($event: MouseEvent) {
    console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.setlatLong();
    this.getAddress(this.latitude, this.longitude);
  }

  loadGoogleMap(latitude, longitude) {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
      this.setCurrentLocation(latitude, longitude);
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.setlatLong();
          this.zoom = 12;
          this.getAddress(this.latitude, this.longitude);
        });
      });
    });
  }

  getGeoLocation() {
    var address = '';
    address = this.enterpriseLocationModelVM.streetAdddress;
    if (this.enterpriseLocationModelVM.cityId != null && this.enterpriseLocationModelVM.cityId != undefined && this.enterpriseLocationModelVM.cityId != "") {
      if (address != '') {
        address = address + ', ' + this.enterpriseLocationModelVM.cityId;
      }
      else {
        address = this.enterpriseLocationModelVM.cityId;
      }
    }
    this.mapStreetAdddress = address;
    var address = address;
    this.geoCoder.geocode({ 'address': address }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          this.latitude = results[0].geometry.location.lat();
          this.longitude = results[0].geometry.location.lng();
          this.setlatLong();
          this.address = address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });




  }

  setlatLong() {
    this.enterpriseLocationModelVM.latitude = (this.latitude).toString();
    this.enterpriseLocationModelVM.logitude = (this.longitude).toString();
  }
}
