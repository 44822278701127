import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
@Injectable()
export class GlobalVariable {
  public BASE_URL: string = document.getElementsByTagName('base')[0].href;
  public isAdmin: boolean = false;
  public userName: string;
  public userEmail: string;
  public roleBehaviourSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isAdmin);
  public userBehaviourSubject: BehaviorSubject<string> = new BehaviorSubject<string>(this.userName);
  public userEmailBehaviourSubject: BehaviorSubject<string> = new BehaviorSubject<string>(this.userEmail);
  constructor(private httpService: HttpClient) {
  }
  public escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }
  /* Define functin to find and replace specified term with replacement string */
  public replaceAll(str, term, replacement):string {
    return str.replace(new RegExp(this.escapeRegExp(term), 'g'), replacement);
  }
  public checkIfDuplicateExists(arr: any[]): any {
    return arr.filter((item, index) => arr.indexOf(item) != index)
  }
  public convertQueryStringToJson(query: string) {
    var pairs = query.split('&');
    var result = {};
    pairs.forEach(function (pairObj) {
      var pair = pairObj.split('=');
      result[pair[0]] = decodeURIComponent(pair[1] || '');
    });
    return JSON.parse(JSON.stringify(result));
  }
  public getUserRole(): Observable<boolean> {
    return this.httpService.get<boolean>(this.BASE_URL + "api/app/getuserrole").pipe(map(isAdmin => {
      this.isAdmin = isAdmin;
      return isAdmin;
    }));
  }
  public getUserName(): Observable<string> {
    return this.httpService.get<userProfile>(this.BASE_URL + "api/app/getuserName").pipe(map(userName => {
      this.userName = userName.userName;
      return userName.userName;
    }));
  }
  public getUserEmail(): Observable<string> {
    return this.httpService.get<userProfile>(this.BASE_URL + "api/app/getuserEmail").pipe(map(userName => {
      this.userEmail = userName.email;
      return userName.email;
    }));
  }
  public saveUserActivity(currentUrl: string, previousUrl: string): Observable<number> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify({ currentUrl: currentUrl, previousUrl: previousUrl });
    return this.httpService.post<number>(this.BASE_URL + "api/app/saveuseractivity", body, { 'headers': headers });
  }
  public createDataTree(dataset: any[], returnType?: number) {
    let hashTable = Object.create(null)
    dataset.forEach(aData => hashTable[aData.id] = { ...aData, children: [] })
    let dataTree = []
    dataset.forEach(aData => {
      if (aData.parentId != null && hashTable[aData.parentId]!=null) {
        hashTable[aData.parentId].children.push(hashTable[aData.id])
      }
      else dataTree.push(hashTable[aData.id])
    })
    if (returnType == null) {
      return dataTree
    } else {
      return dataTree
    }
  }
  public newGuid(): string {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }
  public getCurrentQuarter(): { year: number, quarter: number } {
    return { year: 2021, quarter: 1 }
  }
  public fiscalYearQuarter: FiscalYearModel[] = [{ monthFrom: 1, monthTo: 3, quarter: 4, year: -1 },
  { monthFrom: 4, monthTo: 6, quarter: 1, year: 0 },
  { monthFrom: 7, monthTo: 9, quarter: 2, year: 0 },
    { monthFrom: 10, monthTo: 12, quarter: 3, year: 0 }];

  public compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }
      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }
  public hideHeader() {
    try {
      document.getElementById('head_contact').style.setProperty("display", "none", "important"); // display = "none"
    }
    catch (e) { }
  }
  public showHeader() {
    try {
      document.getElementById('head_contact').style.setProperty("display", "block", "important");// = "block !important"
    }
    catch (e) { }
  }
  public copyToClipboard(str) {

    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.focus();
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    
  }
}
export interface userProfile {
  userName: string;
  email: string;
}
export class FiscalYearModel {
  public monthFrom: number;
  public monthTo: number;
  public quarter: number;
  public year: number;

}
declare global {
  interface Array<T> {
    icontains(o: number[]): boolean;
    distinct(propName?: string): T[];
    sortByNumeric(propName?: string): T[];
  }
  interface String {
    indexesOf(str: string): number[];
  }
}
if (!Array.prototype.icontains) {
  Array.prototype.icontains = function <T>(this: T[], elem: number[]): boolean {
    let found: number = 0;
    elem.forEach(parentNumber => {
      var indexTrue = this.findIndex(act => { return Number(parentNumber) == Number(act) });
      if (indexTrue > -1) {
        found = found + 1;
      }
    })
    if (found == elem.length) {
      return true;
    }
    else {
      return false;
    }
  }
}
if (!String.prototype.indexesOf) {
  String.prototype.indexesOf = function (this: String, str: string): number[] {
    let indexes: number[] = [];
    let indexOfChar: number = null;
    while (indexOfChar > -1) {
      if (indexOfChar == null) {
        indexOfChar = this.indexOf(str);
        indexes.push(indexOfChar);
      }
      else {
        indexOfChar = this.indexOf(str, indexOfChar + 1);
        indexes.push(indexOfChar);
      }
    }
    return indexes;
  }
}
if (!Array.prototype.distinct) {
  Array.prototype.distinct = function <T>(this: T[], str?: string): T[] {
    if (str == null || (str!=null && str.trim() == "")) {
      return this.filter((currValue, index, selfArray) => selfArray.indexOf(currValue) === index);
    }
    else {
      return this.filter((currValue, index, selfArray) => {
        return selfArray.findIndex(dd => dd[str] == currValue[str])===index
      });
    }
  }
}
if (!Array.prototype.sortByNumeric) {
  Array.prototype.sortByNumeric = function <T>(this: T[], str?: string): T[] {
    var rows = this.sort((obj1, obj2) => {
      if (str != null && str.trim() != "") {
        if (Number(obj1[str]) > Number(obj2[str])) {
          return 1;
        }
        if (Number(obj2[str]) > Number(obj1[str])) {
          return -1;
        }
        return 0;
      }
      else {
        if (Number(obj1) > Number(obj2)) {
          return 1;
        }
        if (Number(obj2) > Number(obj1)) {
          return -1;
        }
        return 0;
      }
    });
    return rows;
  }
}

