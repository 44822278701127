export class ApplicationDocuments {
    public fileId: number;
    public filePath: string
    public fileName: string
    public fileSize: number;
    public appId: number;
    public fkey: number;
    public modelName: string
    public uploadedBy: string
    public isActive: boolean;
    public extension: string;
}
export class FinalizeFilesModel {
    public constructor() {
        this.fileIds = [];
    }
    public fileIds: number[];
  public fkey: number;
}
export class FinalizeFilesWithUpdateModel {
  public constructor() {
    this.fileIds = [];
  }
  public fileIds: number[];
  public fkey: number;
  public recordFlag:number;
}
export class fileModel {
    public data: any;
    public inProgress: boolean;
    public progress: number;
    public isCompleted: boolean;
    public name: string;
    public url: string;
    public fileId: number;
    public keyId: number;
    public isError: boolean;

}
export class FileOutputModel {
    public files: fileModel[];
  public newFileIds: number[];
  public customReturnedObject: any;
  public isInitializing: boolean;
}
export class GridPageSortFilters {
  pageIndex: number;
  sortDirection: string;
  sortExpression: string;
  search: string;
  pageSize: number;
}
export class CommonParameters {
  EnterpriseId: number;
  ApplicationId: number;
  ModelName: string;
  appVersionId: number;
  tenantId: number;
}
