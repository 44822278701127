import { Component, OnInit, Output, Input, EventEmitter, ViewChild } from '@angular/core';
import { debounceTime, switchMap, map } from 'rxjs/operators';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from '../../services/globalService';
import { NgModel } from '@angular/forms';
import { MatSelectChange } from '@angular/material';
import { CommonControlManager } from '../../services/commonControl.service';
//import { MatSelect } from '@angular/material/select';
@Component({
  selector: 'app-filter-control',
  templateUrl: './filter-control.component.html',
  styleUrls: ['./filter-control.component.css']
})
export class FilterControlComponent implements OnInit {

  constructor(private globalService: GlobalVariable, private commonService: CommonControlManager) { }
  public filterListTemporary: any[] = [];
  public filterObservable: Observable<any[]> = new Observable<any[]>();
  public filterSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /*Configutaion object*/
  //if showAllRec = 1, showDefaultOption = false, then all records will show up no server side search or processing only first time
  //if showAllRec = 1, showDefaultOption = true, then all records will show up by default but user can unselect and opt for server side processing
  //if showAllRec = 0, showDefaultOption = false, Not allowed
  //if showAllRec = 0, showDefaultOption = true, then no records will show up by default but user can tick the option and opt to show all records at once.
  public showAllRec: number = 0;
  public showDefaultOption: boolean = true;
  public filterPlaceHolder: string = '';
  public searchPlaceHolder: string = '';
  public filterOptionNameVariable: string = '';
  public filterOptionIdVariable: string = '';
  public controllerName: string;
  public actionName: string;
  /*Configuration object*/

  //search textbox ngModel
  public searchFilter: string = "";
  public selectedFilters: any[] = [];
  public selectedFilter: any;
  public selectedFiltersTemp: any[] = [];

  public postedObject: any = {};
  public isSetterFired: boolean;
  public isInitRun: boolean;
  public isOptionGroup: boolean;
  public showSearchBar: boolean;
  public isMultiSelect: boolean;
  noOfRecords: number;
  limit = 10;
  offset = 0;
  isInfiniteScrollWorking: boolean = false;
  variablesSetForFirstTime: boolean = false;
  firstChunk: any[] = [];
  valueTye: string = "number";
  visibleValuesList: any[];
  //setter on viewchild is get called when ngIf on container become true.
  //@ViewChild('filterSelect', { static: false }) set filterSelectSetter(filterSelect: MatSelect) {
  //    if (filterSelect != null) {
  //        if ((!this.isOptionGroup) && (this.filterOptionIdVariable != null && this.filterOptionIdVariable!='')) {
  //            try {
  //                filterSelect.openedChange.subscribe(evt => {
  //                    if (evt) {
  //                        try {
  //                            filterSelect.panel.nativeElement.removeListener('scroll', this.onScroll.bind(this))
  //                        }
  //                        catch (e) { }
  //                        filterSelect.panel.nativeElement.addEventListener('scroll', this.onScroll.bind(this))
  //                    }                       
  //                })
  //            }
  //            catch (e) { }

  //        }
  //    }
  //} 
  @Output('optionsSelected') optionsSelected = new EventEmitter();
  @Output('openClosed') openClosed = new EventEmitter();
  @Input('configurationObject') set configurationObject(configurationObject: any) {
    if (configurationObject != null) {
      this.showAllRec = (configurationObject.showAllRecords == null ? 1 : configurationObject.showAllRecords);
      this.showDefaultOption = (configurationObject.showSelectDefaultOption == null ? false : configurationObject.showSelectDefaultOption);
      this.filterPlaceHolder = (configurationObject.filterPlaceHolder == null ? '' : configurationObject.filterPlaceHolder);
      this.searchPlaceHolder = (configurationObject.searchPlaceHolder == null ? '' : configurationObject.searchPlaceHolder);
      this.filterOptionNameVariable = (configurationObject.filterOptionName == null ? '' : configurationObject.filterOptionName);
      this.filterOptionIdVariable = (configurationObject.filterOptionIdName == null ? '' : configurationObject.filterOptionIdName)
      this.postedObject = (configurationObject.postedObject == null ? {} : configurationObject.postedObject);
      this.controllerName = (configurationObject.controllerName == null ? '' : configurationObject.controllerName);
      this.actionName = (configurationObject.actionName == null ? '' : configurationObject.actionName);
      this.isOptionGroup = (configurationObject.isOptionGroup == null ? false : configurationObject.isOptionGroup);
      this.showSearchBar = (configurationObject.showSearchBar == null ? true : configurationObject.showSearchBar);
      this.isMultiSelect = (configurationObject.isMultiSelect == null ? true : configurationObject.isMultiSelect);
      this.limit = (configurationObject.limit == null ? 10 : configurationObject.limit);
      this.valueTye = (configurationObject.valueTye == null ? 'number' : configurationObject.valueTye);
      if (this.controllerName.trim() != "" && this.actionName.trim() != "" && this.filterOptionNameVariable.trim() != "") {
        this.isSetterFired = true;
      }
    }
  }
  public intializeSystem() {
    this.filterObservable = this.filterSubject.pipe(debounceTime(1000), switchMap((fsbool) => {
      if (fsbool != null) {
        if (this.searchFilter == null) this.searchFilter = ""; else this.searchFilter = this.searchFilter.trim();
        if ((this.showAllRec == 1 && this.filterListTemporary != null && this.filterListTemporary.length != 0)) {
          //in case of client side processing
          if (this.isOptionGroup != true) {
            //without option group 
            if (this.filterOptionIdVariable != null && this.filterOptionIdVariable.trim() != "") {
              //filter with primitive ngModel
              return of(this.filterListTemporary.filter(ft => ((ft[this.filterOptionNameVariable] != null && ft[this.filterOptionNameVariable].trim().toLowerCase().indexOf(this.searchFilter) > -1) || (this.searchFilter == ""))));
            }
            else {
              //filter with object type ngModel
              return of(this.filterListTemporary.filter(ft => ((ft[this.filterOptionNameVariable] != null && ft[this.filterOptionNameVariable].trim().toLowerCase().indexOf(this.searchFilter) > -1) || (this.searchFilter == ""))));
            }
          }
          else {
            //filter with option group
            return of(this.filterListTemporary.filter(ft => ((ft[this.filterOptionNameVariable] != null && ft[this.filterOptionNameVariable].trim().toLowerCase().indexOf(this.searchFilter) > -1) || (ft.children != null && ft.children.findIndex(findx => findx[this.filterOptionNameVariable] != null && findx[this.filterOptionNameVariable].trim().toLowerCase().indexOf(this.searchFilter) > -1) > -1) || (this.searchFilter == ""))));
          }
        }
        else {
          //if server side processing or first time data fetching in case of client processing
          this.postedObject.searchFilter = this.searchFilter;
          this.postedObject.showAllRec = this.showAllRec;
          this.postedObject.selectedFilters = (this.selectedFilters == null ? [] : this.selectedFilters);
          this.postedObject.selectedFilter = (this.selectedFilter == null ? 0 : this.selectedFilter);
          if (this.filterOptionIdVariable != null && this.filterOptionIdVariable.trim() != "") {
            if (this.valueTye == 'number') {
              this.postedObject.selectedFilter = Number(this.postedObject.selectedFilter);
              this.postedObject.selectedFilters.forEach(obj => {
                obj = Number(obj);
              })
            }
            else if (this.valueTye == 'string') {
              this.postedObject.selectedFilter = String(this.postedObject.selectedFilter);
              this.postedObject.selectedFilters.forEach(obj => {
                obj = String(obj);
              })
            }
          }
          this.postedObject.skip = (this.offset == null ? 0 : this.offset);
          this.postedObject.limit = (this.limit == null ? 10 : this.limit);
          return this.commonService.filterControlData(this.globalService.BASE_URL + 'api/' + this.controllerName + "/" + this.actionName, this.postedObject);
        }
      }
      else {
        return of(this.firstChunk);
      }
    }), map((resp: any) => {
      //if return is array we assume that we have sent all records and dont need infinite scroll
      if (resp instanceof Array) {
        if (this.filterOptionIdVariable != null && this.filterOptionIdVariable.trim() != "") {
          resp.forEach(arrayObj => {
            if (this.valueTye == 'number') {
              arrayObj[this.filterOptionIdVariable] = Number(arrayObj[this.filterOptionIdVariable]);
            }
            else if (this.valueTye == 'string') {
              arrayObj[this.filterOptionIdVariable] = String(arrayObj[this.filterOptionIdVariable]);
            }

          })
        }
        if (this.showAllRec && (this.filterListTemporary == null || (this.filterListTemporary != null && this.filterListTemporary.length == 0))) {
          this.filterListTemporary = resp;
          this.noOfRecords = resp.length;
        }
        this.fillVisibleValues(resp);
        return resp;
      }
      else {
        this.isInfiniteScrollWorking = true;
        //infinite scroll on server side processing

        var finalData = [];
        if (resp.item2 != null) {
          this.noOfRecords = resp.item2;
        }
        else {
          this.noOfRecords = resp.total;
        }
        if (resp.item1 != null) {
          finalData = resp.item1;
        }
        else {
          finalData = resp.data;
        }
        finalData.forEach(finalDataObj => {
          if (this.filterOptionIdVariable != null && this.filterOptionIdVariable.trim() != "") {
            if (this.valueTye == "number") {
              finalDataObj[this.filterOptionIdVariable] = Number(finalDataObj[this.filterOptionIdVariable]);
            }
            else if (this.valueTye == "string") {
              finalDataObj[this.filterOptionIdVariable] = String(finalDataObj[this.filterOptionIdVariable]);
            }
          }          
        })
        if (this.offset == 0 && this.searchFilter != null && this.searchFilter.trim() != '') {
          this.filterListTemporary = finalData;
        }
        else {
          finalData.forEach(obj => {
            if (this.filterListTemporary.findIndex(rrr => rrr[this.filterOptionIdVariable] == obj[this.filterOptionIdVariable]) < 0) {
              this.filterListTemporary.push(obj);
            }
          })
        }
        if (this.variablesSetForFirstTime != true) {
          this.firstChunk = this.filterListTemporary;
        }
        this.variablesSetForFirstTime = true;
        this.fillVisibleValues(this.filterListTemporary);
        return this.filterListTemporary;
      }
    }));
    this.isInitRun = true;
  }
  fillVisibleValues(visibleList: any[]) {
    if (this.isMultiSelect) {
      if (this.filterOptionIdVariable != null && this.filterOptionIdVariable.trim() != "") {
        this.visibleValuesList = visibleList.map(dd => dd[this.filterOptionIdVariable]);
        this.selectedFiltersTemp = this.selectedFilters.filter(d => { return true });
      }
      else {
        this.visibleValuesList = visibleList.filter(d => { return true });
        this.selectedFiltersTemp = this.selectedFilters.filter(d => { return true });
      }
    }
  }
  getNextBatch() {
    if (this.isInfiniteScrollWorking) {
      this.offset += this.limit;
      this.filterSubject.next(true);
    }
  }
  //onScroll(evt: any) {
  //    if (evt.target.scrollTop > 100) {
  //        console.log(evt);
  //    }
  //}
  ngOnInit() {
    setTimeout(rr => {
      this.intializeSystem();
    });
  }
  selectionMade(event?: MatSelectChange) {
    if (this.isMultiSelect) {
      let allRemovedElems: any[] = [];
     // if (this.filterOptionIdVariable != null && this.filterOptionIdVariable.trim() != "") {
        this.visibleValuesList.forEach(r => {
          if (this.selectedFiltersTemp.indexOf(r) < 0) {
            allRemovedElems.push(r);
          }
        })
        this.selectedFiltersTemp.forEach(toBeAdded => {
          if (this.selectedFilters.indexOf(toBeAdded) < 0) {
            this.selectedFilters.push(toBeAdded);
          }
        })
        allRemovedElems.forEach(toBeRemoved => {
          var tobeRemIndex = this.selectedFilters.indexOf(toBeRemoved);
          if (tobeRemIndex > -1) {
            this.selectedFilters.splice(tobeRemIndex, 1);
          }
        })
      //}      
      var toBePosted: any[] = [];
      if (this.selectedFilters != null && this.selectedFilters.length > 0) {
        if (this.filterOptionIdVariable != null && this.filterOptionIdVariable.trim() != "") {
          if (this.valueTye == "number") {
            toBePosted = this.selectedFilters.map(obj => Number(obj));
          }
          else if (this.valueTye == "string") {
            toBePosted = this.selectedFilters.map(obj => String(obj));
          }
        }
        else {
          toBePosted = this.selectedFilters.map(rr => { return { ...rr } });
        }
      }
      this.optionsSelected.emit(toBePosted);
    }
    else {
      if (this.valueTye == "number") {
        this.selectedFilter = Number(this.selectedFilter)
        this.optionsSelected.emit(this.selectedFilter);
      }
      else if (this.valueTye == "string") {
        this.selectedFilter = String(this.selectedFilter)
        this.optionsSelected.emit(this.selectedFilter);
      }
    }
  }
  openClose(evt: any) {
    if (!evt) {
      //emit close event
      this.openClosed.emit("closed");
      if (this.searchFilter == null || (this.searchFilter != null && this.searchFilter.trim() == "")) {
        if (this.isInfiniteScrollWorking) {
          this.offset = 0;
          this.filterSubject.next(null);
        }
      }
    }
    else {
      this.openClosed.emit("opened");
      //emit open event
    }
  }
  emptyFilter(filterTypeSelect: NgModel) {
    if (this.isMultiSelect) {
      filterTypeSelect.update.emit([]);
    }
    if (this.selectedFilters != null) {
      this.selectedFilters.length = 0;
    }
    else {
      this.selectedFilters = [];
    }
    if (this.selectedFiltersTemp != null) {
      this.selectedFiltersTemp.length = 0;
    }
    else {
      this.selectedFiltersTemp = [];
    }
    if (this.valueTye == 'number') {
      this.selectedFilter = 0;
    }
    else if (this.valueTye == 'string') {
      this.searchFilter = '';
      this.selectedFilter = '';
    }    
    this.selectionMade();
  }
}
