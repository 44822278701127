import { Directive, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UserManagementService } from '../../app/services/user-management.service';


@Directive({
  selector: '[profile-photo]',
  host: {
    '[src]': 'sanitizedImageData'
  }
})
export class ProfilePhotoDirective implements OnInit {
  imageUrl: any;
  sanitizedImageData: any;
  @Input() name: string;
  @Input() value: string;
  constructor(protected _sanitizer: DomSanitizer, private userService: UserManagementService) { }

  ngOnInit() {
    this.imageUrl = 'assets/images/generic_user_image_thumb.png';
    this.sanitizedImageData = this._sanitizer.bypassSecurityTrustUrl(this.imageUrl);
    if (this.name != null) {
      this.userService.getUserPhoto(this.name, this.value, true).subscribe(rr => {
        if (rr != "") {this.imageUrl = 'data:image/png;base64,' + rr; }
          this.sanitizedImageData = this._sanitizer.bypassSecurityTrustUrl(this.imageUrl);
      });
    }
  }
}  
