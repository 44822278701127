import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { GlobalVariable } from './services/globalService';
import { CommonControlManager } from './services/commonControl.service';
import { AuthorizeService } from '../api-authorization/authorize.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
    providers: []
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'app';
  currentUrl = "";
  previousUrl = "";
  constructor(private global: GlobalVariable, private authService: AuthorizeService, private commonControl: CommonControlManager, private router: Router) {
  }
  ngAfterViewInit() {
    this.authService.isAuthenticated().subscribe(rr => {
      if (rr == true) {
        setTimeout(() => {
          this.global.getUserRole().subscribe(resp => {
            this.global.roleBehaviourSubject.next(resp);
          });
          this.global.getUserName().subscribe(resp => {
            this.global.userBehaviourSubject.next(resp);
          });
          this.global.getUserEmail().subscribe(resp => {
            this.global.userEmailBehaviourSubject.next(resp);
          });
          this.commonControl.startTransactionChecker();
       
        }, 1000);

        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
          if (this.currentUrl !== event.url) {
            this.previousUrl = this.currentUrl;
            this.currentUrl = event.url;
            this.global.saveUserActivity(this.currentUrl, this.previousUrl).subscribe(response => {
            //   console.log("Reponse:" + response);
          });
        }
        });
      }
    })
  }
  ngOnInit() {
    //this.checkIfTenantThere();
    
    window.addEventListener("dragover", e => {
      e && e.preventDefault();
    }, false);
    window.addEventListener("drop", e => {
      e && e.preventDefault();
    }, false);

    window.addEventListener("mousemove", ({ clientX, clientY }) => {
      window['lastPosition'] = { x: clientX, y: clientY };
    })
  }
}
