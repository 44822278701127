import { Injectable } from '@angular/core';
import { GlobalVariable } from '../globalService';
import { HttpClient } from '@angular/common/http';
import { TimeTrackService } from '../time-track.service';
import { LogErrorVM } from '../../models/Timetrack';

@Injectable({
    providedIn: 'root'
})
export class LoggingService {
    logErrorVM: LogErrorVM;
    constructor(private globalVar: GlobalVariable, private httpclient: HttpClient, private timeTrackService: TimeTrackService) { }

    logError(message: string, stack: string, error: any) {
        // Send errors to server here
        console.log('LoggingService: ' + message);
        this.logErrorVM = {
            message: error.message,
            error: 'Error : ' + error.message + ' \n' + 'Url : ' + error.url + ' \n ' + error.error,
          name: error.name,
          status: (error.status == null ? "" : error.status).toString(),
            url: error.url
        }
        this.timeTrackService.logError(this.logErrorVM).subscribe();
    }
}
