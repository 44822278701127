import { Directive, Input, Renderer2, ElementRef, Output, HostListener, EventEmitter, OnInit } from '@angular/core';
import * as moment from "moment";


declare var $: any;

@Directive({
  selector: '[dateRange]',
})
export class DateRangeDirective implements OnInit {
  @Output() changeDates: EventEmitter<any> = new EventEmitter<any>();

  constructor(private elementRef: ElementRef, private rendered: Renderer2) {

  }

  ngOnInit(): void {
    var start = moment().subtract(29, 'days');
    var end = moment();
    var bindvar = this;

    function cb(start, end) {
      if (bindvar.elementRef != undefined || bindvar.elementRef != null)
        $(bindvar.elementRef.nativeElement.querySelectorAll('span')).html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
      //$('#reportrange span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
    }

    $(this.elementRef.nativeElement).daterangepicker({
      startDate: start,
      endDate: end,
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cb);
    cb(start, end);


    $(this.elementRef.nativeElement).on('apply.daterangepicker', function (ev, picker) {
      bindvar.changeDates.emit({ startDate: picker.startDate.format('YYYY-MM-DD'), endDate: picker.endDate.format('YYYY-MM-DD') });
      //bindvar.endDate.emit(picker.endDate.format('YYYY-MM-DD'));
      //console.log(picker.startDate.format('YYYY-MM-DD'));
      //console.log(picker.endDate.format('YYYY-MM-DD'));
    });

    this.changeDates.emit({ startDate: start.format('YYYY-MM-DD'), endDate: end.format('YYYY-MM-DD') });

  }
}




