//import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
//import { AppRoutingModule, routingComponent } from '../shared-module/component-module/app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '../shared-module/material-module/material.module';
import { SharedModule } from '../shared-module/shared.module';
import { UploadControlComponent } from './upload-control/upload-control.component';
import { SafeHtmlPipe } from '../global-dialog/safehtml.pipe';
import { FilterControlComponent } from './filter-control/filter-control.component';
import { UploadControlDynamicActionComponent } from './upload-control-dynamic-action/upload-control-dynamic-action.component';
import { GlobalDialogComponent } from '../global-dialog/global-dialog.component';
import { DownloadControlsComponent } from './download-controls/download-controls.component';
import { StringFilterByPipe } from './string-filter-by-pipe/string-filter-by-pipe.pipe';
@NgModule({
  entryComponents: [
    GlobalDialogComponent
  ],
  declarations: [
    GlobalDialogComponent,
    UploadControlComponent,
    SafeHtmlPipe,
    FilterControlComponent,
    UploadControlDynamicActionComponent,
    DownloadControlsComponent,
    StringFilterByPipe
  ],
  imports: [
    //BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    SharedModule,
    //BrowserAnimationsModule,
    MaterialModule,
  ],
  exports: [
    GlobalDialogComponent,
    UploadControlComponent,
    SafeHtmlPipe,
    FilterControlComponent,
    UploadControlDynamicActionComponent,
    DownloadControlsComponent,
    StringFilterByPipe
  ]
  //bootstrap: [AppComponent]
})
export class CommonControlModule { }
