import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CommonControlManager } from '../../services/commonControl.service';
import { map, catchError, switchMap } from 'rxjs/operators';
import { HttpEventType, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { fileModel, FileOutputModel, ApplicationDocuments } from '../../models/commonControl.model';
import { GlobalDialogService } from '../../global-dialog/global-dialog.service';
import { GlobalVariable } from '../../services/globalService';
import { LoaderService } from '../../services/loadingService';

@Component({
  selector: 'app-upload-control-dynamic-action',
  templateUrl: './upload-control-dynamic-action.component.html',
  styleUrls: ['./upload-control-dynamic-action.component.css']
})
export class UploadControlDynamicActionComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(private commonControlManager: CommonControlManager, private dialogService: GlobalDialogService, private httpService: HttpClient, private globalVar: GlobalVariable, private loadingService: LoaderService,) {

  }
  @ViewChild("fileUpload", { static: false }) fileUpload: ElementRef;
  @ViewChild('fileDragDropSection', { static: false }) fileDragDropSection: ElementRef;
  files: fileModel[] = [] = [];
  newlyUploadedFileIds: number[] = [];
  isError: boolean = true;
  @Output() outputFileModel = new EventEmitter<FileOutputModel>();
  configObj: { appId: number, keyId: number, modelName: string, allowedTypes: string[], maxSize: number, showUploadedList: boolean, allowedNoOfFiles: number, isForeignKeyRequired: boolean, isForcefullyActive: boolean, controllerName: string, actionName: string } = { appId: 0, keyId: 0, modelName: '', allowedTypes: [], maxSize: 0, showUploadedList: false, allowedNoOfFiles: 0, isForeignKeyRequired: true, isForcefullyActive: false, controllerName: '', actionName:'' };
  @Input() set uploadConfig(value: any) {
    if (value != null) {
      this.copyValueToConfig(value);
      if (this.configObj != null) {
        if (this.newlyUploadedFileIds == null) {
          this.newlyUploadedFileIds = [];
        }
        else {
          this.newlyUploadedFileIds.length = 0;
        }
        if (this.files == null) {
          this.files = [];
        }
        else {
          this.files.length = 0;
        }
        this.initializeControl();
      }
    }
    else {
      this.isError = true;
    }
  }
  copyValueToConfig(value: any) {
    this.configObj.appId = (value.appId == null ? 0 : value.appId);
    this.configObj.keyId = (value.keyId == null ? 0 : value.keyId);
    this.configObj.allowedNoOfFiles = (value.allowedNoOfFiles == null ? 0 : value.allowedNoOfFiles);
    this.configObj.allowedTypes = (value.allowedTypes == null ? [] : value.allowedTypes);
    this.configObj.modelName = (value.modelName == null ? "" : value.modelName);
    this.configObj.showUploadedList = (value.showUploadedList == null ? true : value.showUploadedList);
    this.configObj.maxSize = (value.maxSize == null ? 0 : value.maxSize);
    this.configObj.isForeignKeyRequired = (value.isForeignKeyRequired == null ? true : value.isForeignKeyRequired);
    this.configObj.isForcefullyActive = (value.isForcefullyActive == null ? false : value.isForcefullyActive);
    this.configObj.controllerName = (value.controllerName == null ? '' : value.controllerName);
    this.configObj.actionName = (value.actionName == null ? '' : value.actionName);
  }
  uploadFile(file: fileModel) {
    this.loadingService.blockUI();
    if (file.data != null) {
      const formData = new FormData();
      formData.append('file', file.data);
      var tempFormData = JSON.stringify({ appId: this.configObj.appId, keyId: this.configObj.keyId, modelName: this.configObj.modelName, isForceFullyActive: this.configObj.isForcefullyActive });
      formData.append('uploadData', tempFormData);
      file.inProgress = true;
      this.addUploadedFilesToTable(formData).pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round(event.loaded * 100 / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          file.isCompleted = true;
          file.inProgress = false;
          file.isError = true;
          this.loadingService.unblock();
          return of(`${file.data.name} upload failed.`);
        })).subscribe((event: any) => {
         
           if (event != null && event != undefined) {
            var returnedObject: any;
            if (typeof (event) === 'object') {
              file.isCompleted = true;
              file.inProgress = false;
              file.isError = false;
              file.name = event.body.item1.fileName
              file.url = event.body.item1.filePath;
              file.fileId = event.body.item1.fileId;
              returnedObject = event.body.item2;
              if (event.body.isActive != true) {
                this.newlyUploadedFileIds.push(file.fileId);
              }           
              this.loadingService.unblock();
            }
            var outMod = new FileOutputModel();
            outMod.files = this.files;
            outMod.newFileIds = this.newlyUploadedFileIds;
            outMod.customReturnedObject = returnedObject
             outMod.isInitializing = false;
             this.loadingService.unblock();
            this.outputFileModel.emit(outMod);
          }
        });
    }
  }
  private addUploadedFilesToTable(formData) {
    //return this.httpService.post<{ item1: ApplicationDocuments, item2: any }>(this.globalVar.BASE_URL + "api/" + this.configObj.controllerName + "/" + this.configObj.actionName + "", formData, { reportProgress: true, observe: 'events' })
    return this.commonControlManager.addUploadedFilesToTableDynamic(formData, this.configObj.controllerName, this.configObj.actionName)

  }
  private uploadFiles() {
    this.fileUpload.nativeElement.value = '';
    var files = this.files.filter(rr => rr.isCompleted == false && rr.inProgress == false);
    files.forEach(file => {
      this.uploadFile(file);
    });
  }
  onClick() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.click();
  }
  initializeControl() {
    if (this.configObj.modelName != null && this.configObj.modelName.trim() != "") {
      this.isError = false;
      if (this.configObj.showUploadedList == null) {
        this.configObj.showUploadedList = true;
      }
      if (this.configObj.keyId != 0 && this.configObj.keyId != null) {
        this.getAlreadySavedFiles().subscribe(rr => {
          this.outputFileModel.emit({ files: rr, newFileIds: [], customReturnedObject: null, isInitializing:true });
        });
      }
      else {
        if ((!this.configObj.isForeignKeyRequired)) {
          this.getAlreadySavedFiles().subscribe(rr => {
            this.outputFileModel.emit({ files: rr, newFileIds: [], customReturnedObject: null, isInitializing: true });
          });
        }
        else {
          this.outputFileModel.emit({ files: [], newFileIds: [], customReturnedObject: null, isInitializing: true });
        }
      }
    }
    else {
      this.isError = true;
    }
  }
  showMessageForSizeExceed(filesThatExceedSize: any[]) {
    if (filesThatExceedSize != null && filesThatExceedSize.length > 0 && this.configObj.maxSize != 0) {
      let htmlMessage: string = "<table>"
      htmlMessage = htmlMessage + "<tr><th>Following files exceed the allowed file size of " + this.configObj.maxSize + " bytes</th></tr>"
      filesThatExceedSize.forEach(file => {
        htmlMessage = htmlMessage + "<tr><td>" + file.name + " (" + file.size + " bytes)</td></tr>"
      });
      htmlMessage = htmlMessage + "</table>"
      this.dialogService.openDialog("File size exceed the allowed size", htmlMessage, [{ option: 'Ok', optionValue: 1 }]).subscribe();
    }
  }
  showMessageForTypeNotAllowed(filesThatAreNotInAllowedTypes: any[]) {
    if (filesThatAreNotInAllowedTypes != null && filesThatAreNotInAllowedTypes.length > 0 && this.configObj.allowedTypes != null && this.configObj.allowedTypes.length > 0) {
      var allowedTypeString = this.configObj.allowedTypes.join(",");
      let htmlMessage: string = "<table>"
      htmlMessage = htmlMessage + "<tr><th>Following files are not allowed as only " + allowedTypeString + " format(s) are allowed</th></tr>"
      filesThatAreNotInAllowedTypes.forEach(file => {
        htmlMessage = htmlMessage + "<tr><td>" + file.name + "</td></tr>"
      });
      htmlMessage = htmlMessage + "</table>"
      this.dialogService.openDialog("File type not allowed", htmlMessage, [{ option: 'Ok', optionValue: 1 }], 0, "", "550px").subscribe();
    }
  }
  onFileSelect(fileUpload: any) {
    try {
      if (this.isError != true) {
        if (fileUpload.files.length > 0) {
          let filesThatExceedSize: any[] = [];
          let filesThatAreNotInAllowedTypes: any[] = [];
          for (let index = 0; index < fileUpload.files.length; index++) {
            const file = fileUpload.files[index];
            if (this.configObj.allowedNoOfFiles == 0 || (this.files.length < this.configObj.allowedNoOfFiles)) {
              if (this.configObj.maxSize == 0 || (file.size <= this.configObj.maxSize)) {
                var extension = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length);
                if (this.configObj.allowedTypes == null || (this.configObj.allowedTypes != null && this.configObj.allowedTypes.length == 0)
                  || (this.configObj.allowedTypes.indexOf(extension) > -1)) {
                  this.files.push({ data: file, inProgress: false, progress: 0, isCompleted: false, name: file.name, url: '', fileId: 0, keyId: this.configObj.keyId, isError: false });
                }
                else {
                  filesThatAreNotInAllowedTypes.push(file);
                }
              }
              else {
                filesThatExceedSize.push(file);
              }
            }
            else {
              this.dialogService.openDialog("File limits reached", "You cannot add more than " + this.configObj.allowedNoOfFiles + " file(s)", [{ option: 'Ok', optionValue: 1 }]).subscribe();
              break;
            }
          }
          this.showMessageForSizeExceed(filesThatExceedSize);
          this.showMessageForTypeNotAllowed(filesThatAreNotInAllowedTypes);
          this.uploadFiles();
        }
      }
      else {
        console.exception("Problem in upload control configuration")
      }
    }
    catch (e) {
      console.log(e);
    }
  }
  onChangeOfFiles(evt: any) {
    this.onFileSelect(this.fileUpload.nativeElement);
  }
  onDrop(evt: any) {
    console.log(evt);
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.files = evt.dataTransfer.files;
    this.onFileSelect(fileUpload);
  }
  getAlreadySavedFiles(): Observable<fileModel[]> {
    return this.commonControlManager.getUploadedFiles(this.configObj.appId, this.configObj.modelName, this.configObj.keyId).pipe(map(uploadedFiles => {
      uploadedFiles.forEach(file => {
        let fileObj: fileModel = new fileModel();
        fileObj.data = null;
        fileObj.fileId = file.fileId;
        fileObj.inProgress = false;
        fileObj.isCompleted = true;
        fileObj.isError = false;
        fileObj.keyId = file.fkey;
        fileObj.name = file.fileName;
        fileObj.progress = 100;
        fileObj.url = file.filePath;
        this.files.push(fileObj)
      })

      return this.files;
    }));
  }
  deleteFile(fileId: number) {
    this.dialogService.openDialog("Are you sure?", "Are you sure you want to delete file?", [{ option: 'Yes', optionValue: 1 }, { option: 'No', optionValue: 2 }]).pipe(switchMap(res => {
      if (Number(res) == 1) {
        return this.commonControlManager.deleteFile(fileId);
      }
      else {
        return of(null);
      }
    })).subscribe(fileDeleteResponse => {
      if (fileDeleteResponse != null) {
        if (fileDeleteResponse == true) {
          var indexToBeDeleted = this.files.findIndex(d => d.fileId == fileId);
          if (indexToBeDeleted > -1) {
            this.files.splice(indexToBeDeleted, 1);
            var indexOfNewFileToBeDeleted = this.newlyUploadedFileIds.findIndex(d => d == fileId);
            this.newlyUploadedFileIds.splice(indexOfNewFileToBeDeleted, 1);
          }
        }
      }
    })
  }
  downloadFile(fileId: number) {
    this.commonControlManager.downloadFile(fileId).subscribe();
  }
  ngOnInit() {
  }
  ngAfterViewInit() {
    this.fileUpload.nativeElement.addEventListener('change', this.onChangeOfFiles.bind(this))
    this.fileDragDropSection.nativeElement.addEventListener('drop', this.onDrop.bind(this))
  }
  ngOnDestroy() {
    this.fileDragDropSection.nativeElement.removeEventListener('drop', this.onDrop.bind(this));
    this.fileUpload.nativeElement.removeEventListener('change', this.onChangeOfFiles.bind(this));
  }
}
