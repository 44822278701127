export class TimeTrackConstraints {
  id: number
  employeeId: string;
  projectId: number
  startTime: Date;
  endTime: Date;
  totalTime: number;
  task: string;
  isApproved: boolean;
  employeeComments: string;
  managerComments: string;
  commentByManagerId: string;
  isActive: boolean;
  createdByDate: Date;
  createdByEmployeeId: string;
  modifiedByDate: Date;
  modifiedByEmployeeId: string;
  approvedByManagerId: string;
  workTypeId: number
  tenantId: number
  appName: number
  status: number
  dueDate: Date;
  startDate: Date;
  priority: number
  category: number
  entityId: number
  commentByManagerName: string;
  projectName: string;
  employeeName: string;
  createdByEmployeeName: string;
  modifiedByEmployeeName: string;
  approvedByManagerName: string;
  applicationName: string;
  statusName: string;
  priorityName: string;
  categoryName: string;
  workTypeDesc: string;
  strStartDate: string;
  strDueDate: string;
  isAdmin: string;
}


export class TimeTracks {
  id: number = 0;
  employeeId: string;
  projectId: number;
  //start_Time: Date;
  //end_Time: Date;
  //total_Time: number;
  startTime: Date;
  endTime: Date;
  totalTime: number;
  task: string;
  isApproved: boolean = false;
  //employee_Comments: string;
  //manager_Comments: string;
  employeeComments: string;
  managerComments: string;
  //commentByManagerId: number;
  commentByManagerId: string;
  commentByManagerName: string;
  projectName: string;
  employeeName: string;
  //approvedByManagerID: number = 0;
  approvedByManagerID: string;
  approvedByManagerName: string;
  createdByEmployeeName: string;
  modifiedByEmployeeName: string;
  workTypeId: number;
  selectedDateString: string;
  appName: number;
  status: number;
  dueDate: Date;
  startDate: Date;
  priority: number;
  category: number;
  applicationName: string;
  statusName: string;
  priorityName: string;
  categoryName: string;
  workTypeDesc: string;
  entityId: number;
  isAdmin: boolean;
  tenantId: number;
  hour: string;
  taskStatus: number;
  taskStatusName: string;
  commentNumbers: number;
  approvedHours: number;
  currentDate: string;
  currentDayName: string;
  hours: string = "";
  weekStartDate: Date;
  weekEndDate: Date;
  constructor() {
    this.isApproved = true;
    this.employeeId = "";
    this.projectId = 0;
    this.startTime = new Date();
    this.endTime = new Date();
    this.workTypeId = 0;
    this.priority = 0;
    this.appName = 0;
    this.taskStatus = 0;
    this.weekEndDate = new Date();
    this.weekStartDate = new Date();
  }
}


export class HolidayList {
  id: number;
  countryId: string;
  holidayDate: Date;
  description: string;
  year: number;
}

export class TimeTrackerFilterModelVM {
  constructor() {
  }
  public selectedWorkTypes: number[];
  public skip: number
  public take: number
  public searchString: string
  public enterpriseId: number;

  public sortyBy: string;
  public sortDirection: string;
  public employeeId: string;
  public projectId: number;
  public firstDayOfWeek: Date;
  public lastDateOfWeek: Date;
  public firstDayOfWeek1: Date;
  public lastDateOfWeek1: Date;
}
export class ExpressEntry {
  id?: number = 0;
  employeeId: string;
  projectId?: number;
  weekStartDate?: Date;
  weekEndDate?: Date;
  //currentDate: Date;
  // currentDate: string;
  currentDate: string;
  currentDayName: string;
  hours: string = "";
  task: string;
  notes: string;
  // workType: string;
  workTypeId?: number;
  arrayInternalIndexId?: number;
  isMarkedDeletedInView: boolean = false;
  status?: number;
  appName?: number;
  priority?: number;
  category?: number;
  startDate?: Date;
  dueDate?: Date;
  taskPristine: boolean = true;
  taskRequiredFired: boolean = false;
  projectPristine: boolean = true;
  projectRequiredFired: boolean = false;
  applicationPristine: boolean = true;
  applicationRequired: boolean = false;
  statusPristine: boolean = true;
  statusRequired: boolean = false;
  workTypePristine: boolean = true;
  workTypeRequired: boolean = false;
  priorityPristine: boolean = true;
  priorityRequired: boolean = false;
  categoryPristine: boolean = true;
  categoryRequired: boolean = false;
  startDatePristine: boolean = true;
  startDateRequiredFired: boolean = false;
  dueDatePristine: boolean = true;
  dueDateRequiredFired: boolean = false;
  hourPristine: boolean = true;
  hourRequired: boolean = false;
  isNewRecord: boolean = false;
  isdisable: boolean = true;
  isEdit: boolean = false;
  hourvalidation: boolean = false;
  compareDate: boolean = false;
  taskStatusName: string;
  taskStatus: number;
  validOneDayHours: boolean =false
  public approvedHours: string;
  public managerComments: string;
  public managerCreatedName: string;
  public managerCreatedDate: Date;
  public managerModifiedDate: Date; 
  public managerModifiedName: string;
  public commentNumbers: number;


}
export class Projects {
  id: number;
  projectName: string;
  isActive: boolean;
}
export class Employees {
  id: number;
  //first_Name: string;
  //last_Name: string;
  firstName: string;
  lastName: string;
  email: string;
  isActive: boolean;
  countryName: string;
  countryId: number;
  username: string;
  //constructor(id,first_Name,last_Name,email) {
  //  this.Id = id;
  //  this.Email = email;
  //  this.First_Name = first_Name;
  //  this.Last_Name = last_Name;
  //}

  //constructor(id: number,
  //  first_Name: string,
  //  last_Name: string,
  //  email: string,
  //) {
  //  this.Id = id;
  //  this.Email = email;
  //  this.First_Name = first_Name;
  //  this.Last_Name = last_Name;
  //}
}

export class ApplicationType {
  public applicationId: number;
  public name: string;
  public description: string;
}

export class DropDownVM {
  value: number;
  id?: number;
  text: string;
  Enabled?: boolean;
}
export class ApplicationsVM {
  //applicationId: number = 0;
  //name: string;
  //description: number;
  //url: string;
  //activityId: number;
  //modifiedDate: string;
  //isActive: boolean;
  //isRelatedToEntity: boolean
  //createdDate: string
  //hierarchyStructure: number
  //releaseCycle: number
  //isReleased: number
  //isSubItem: boolean
  //targetParentRoleId: string
  //targetSubRoleId: string
  //configuration: string
  //checkEntitiesInActivityEntityMapping: boolean
  public applicationId: number = 0;

  public name: string;
  public description: string;
  public isEnterpriseBased: boolean;
  public isReleased: boolean;
  public hierarchyStructure: number;
  public isActive: boolean;
  public configuration: string;
  public createdBy: string;
  public modifiedBy: string;
  public selectedAppVersionIds: [];
  public tenantId: number;
  public isAppVersionAssigned: boolean;
  public cprRate: number;
  public threshhold: number;
  public cprRequired: boolean = false;
  public threshholdRequired: boolean = false;
  public cprValid: boolean = false;
  public threshholdValid: boolean = false;
  public edited: boolean;
}

export class LogErrorVM {
  public message: string;
  public error: string;
  public name: string;
  public status: string;
  public url: string;
}
export class AspNetUsers {
  public id: string;
  public email: string;
  public firstName: string;
  public lastName: string;
}
export class TimeTrackerComments {
  public id: number;
  public timeTrackId: number;
  public createdBy: string;
  public createdDate: Date;
  public modifedBy: string;
  public modifiedDate: string;
  public userName: string;
  public comment: string;
  public isRead: boolean;
  public isActive: boolean;
  public tenantId: number;
    }

export class AppUsersModelVM {
  public appId: number;
  public userId: string;
  public userList: Employees[];
  public applicationList: ApplicationType[];
}

export class TimetrackAppHours {
  public id: number;
  public name: string;
  public hours: number;
  public workHours: number;
  public mealHours: number;
  public approveHours: number;
  public workHourMin: string;
  public mealHourMin: string;
  public productivehourMin: string;
    }
