import { GlobalVariable } from "../services/globalService";
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from "rxjs";
import { map, catchError, retry } from "rxjs/operators";

import { NCCICauseGroupVM, ClaimStatusVM, IncidentType, RecordedIncident, IncidentFilterModelVM, RecordedIncidentVM, EnterpriseLocationsVM, YearQuarterModel, QuarterlyHourWorkedVM, WCLossesYearlyDataReportVM, SafetyProgramsVM, safetyReport, QuesstionAnswerVM, analysisInputModel, EntityAnalysis, EntityAnalysisWCLosses, RecordedIncidentWithLocationVM, SafetyLossesYearlyDataReportWithLocationVM, BodyParts, ClaimEvaluationPeriod } from "./safety.model";
import { LevelInfoModel, CountryModel } from '../models/enterpriseModel';
import { Injectable } from "@angular/core";
import { saveAs } from 'file-saver';
import { GridPageSortFilters, CommonParameters } from "../models/commonControl.model";
@Injectable()
export class SafetyManager {
  constructor(private globalVar: GlobalVariable, private httpService: HttpClient) {
  }
  private getQuarterYear(month: number, year: number): { quarter: number, fyear: number } {
    var quarterObj = this.globalVar.fiscalYearQuarter.filter(d => d.monthFrom <= month && d.monthTo >= month)[0];
    if (quarterObj != null) {
      let quarterToBeReturned: number;
      let yearToBeReturned: number;
      quarterToBeReturned = quarterObj.quarter;
      if (quarterObj.year == 0) {
        yearToBeReturned = (year);
      }
      else {
        yearToBeReturned = (year - 1);
      }
      return { quarter: quarterToBeReturned, fyear: yearToBeReturned };
    }
    else {
      return { quarter: 0, fyear: 0 };
    }
  }
  getNCCCauseGroups(): Observable<NCCICauseGroupVM[]> {
    return this.httpService.post<NCCICauseGroupVM[]>(this.globalVar.BASE_URL + 'api/safety/getNCCCauseGroups', {});
  }
  getClaimStatus(): Observable<ClaimStatusVM[]> {
    return this.httpService.post<ClaimStatusVM[]>(this.globalVar.BASE_URL + 'api/safety/getclaimstatus', {});
  }
  getIncidentTypes(): Observable<IncidentType[]> {
    return this.httpService.post<IncidentType[]>(this.globalVar.BASE_URL + 'api/safety/getIncidentTypes', null);
  }
  getSafetyBodyParts(): Observable<BodyParts[]> {
    return this.httpService.post<BodyParts[]>(this.globalVar.BASE_URL + 'api/safety/getSafetyBodyParts', {});
  }
  getClaimEvaluationPeriod(): Observable<ClaimEvaluationPeriod[]> {
    return this.httpService.post<ClaimEvaluationPeriod[]>(this.globalVar.BASE_URL + 'api/safety/GetClaimEvaluationPeriod', {});
  }  
  getIncident(incidentId: number): Observable<RecordedIncidentVM> {
    if (incidentId != 0) {
      return this.httpService.get<RecordedIncidentVM>(this.globalVar.BASE_URL + 'api/safety/getIncident?incidentId=' + incidentId).pipe(map((resp => {
        var dayOfMonth = resp.dayOfMonth;
        var month = (resp.month - 1);
        var year = resp.year;
        resp.incidentDate = new Date(year, month, dayOfMonth);
        try {
          var quarterYearObj = this.getQuarterYear(resp.month, year);
          resp.financialYear = quarterYearObj.fyear;
          resp.quarter = quarterYearObj.quarter;
        } catch (e) { }
        //convert UTC to local datetime                
        return resp;
      })));
    }
    else {
      var recIncident = new RecordedIncidentVM();
      recIncident.incidentId = 0;
      recIncident.incidentDate = new Date();
      recIncident.dayOfMonth = recIncident.incidentDate.getDate();
      recIncident.month = (recIncident.incidentDate.getMonth() + 1);
      recIncident.year = recIncident.incidentDate.getFullYear();
      recIncident.isDayAwayFromWork = false;
      recIncident.isClaimReported = false;
      try {
        var quarterYearObj = this.getQuarterYear(recIncident.month, recIncident.year);
        recIncident.financialYear = quarterYearObj.fyear;
        recIncident.quarter = quarterYearObj.quarter
      }
      catch (E) { }
      //calculate quarter and financial year here
      return of(recIncident);
    }
  }
  saveIncident(incident: RecordedIncidentVM): Observable<number> {
    if (incident.recordedIncidentClaim != null) {
      incident.recordedIncidentClaim.forEach(qq => {
        qq.totalIncurred = Number(qq.totalIncurred);
        qq.totalPaid = Number(qq.totalPaid);
      });
    }
    //convert local time to utc
    if (incident != null) {
      incident.dayOfMonth = incident.incidentDate.getDate();
      incident.year = incident.incidentDate.getFullYear();
      incident.month = (incident.incidentDate.getMonth() + 1);
      try {
        var quarterYearObj = this.getQuarterYear(incident.month, incident.year);
        incident.financialYear = quarterYearObj.fyear;
        incident.quarter = quarterYearObj.quarter;
      }
      catch (e) { }
    }
    let newIncident: RecordedIncident = <RecordedIncident>(incident);
    if (newIncident.totalPaid != null) {
      let tempTotalPaid: number = Number(newIncident.totalPaid);
      if (!isNaN(tempTotalPaid)) {
        newIncident.totalPaid = tempTotalPaid;
      }
      else {
        newIncident.totalPaid = 0
      }
    }
    if (newIncident.totalIncurred != null) {
      let tempTotalIncurred: number = Number(newIncident.totalIncurred);
      if (!isNaN(tempTotalIncurred)) {
        newIncident.totalIncurred = tempTotalIncurred;
      }
      else {
        newIncident.totalIncurred = 0;
      }
    }
    //return this.httpService.post<number>(this.globalVar.BASE_URL + 'api/safety/saveIncident', newIncident);
    return this.httpService.post<number>(this.globalVar.BASE_URL + 'api/safety/saveIncident', { incident: newIncident, recordedIncidentClaim: incident.recordedIncidentClaim });
    
  }
  //getIncidents(filter: IncidentFilterModelVM): Observable<{ item1: RecordedIncidentVM[], item2: number }> {
  //  return this.httpService.post<{ item1: RecordedIncidentVM[], item2: number }>(this.globalVar.BASE_URL + 'api/safety/getIncidents', filter);
  //}
  getIncidents(constraints: RecordedIncidentVM, gridpsFilterItem: GridPageSortFilters, commonParameters: CommonParameters): Observable<any> {
    return this.httpService.post<any>(this.globalVar.BASE_URL + 'api/safety/getIncidents', { constraints: constraints, sortPageFilters: gridpsFilterItem, commonParameters: commonParameters });
  }

  getLocations(enterpriseId: number, searchedString: string, isAll: number, defaultLocationId: number[]): Observable<EnterpriseLocationsVM[]> {
    return this.httpService.post<EnterpriseLocationsVM[]>(this.globalVar.BASE_URL + 'api/safety/getLocations', { enterpriseId: enterpriseId, searchedString: searchedString, isAll: isAll, defaultLocationIds: defaultLocationId });
  }
  getQuarterYears(enterpriseIds: number[]): Observable<{ item1: YearQuarterModel[], item2: YearQuarterModel[] }> {
    return this.httpService.post<{ item1: YearQuarterModel[], item2: YearQuarterModel[] }>(this.globalVar.BASE_URL + 'api/safety/GetYearQuarterFilters', enterpriseIds);
  }
  getQuarterYearsFlat(enterpriseIds: number[]): Observable<YearQuarterModel[]> {
    return this.httpService.post<YearQuarterModel[]>(this.globalVar.BASE_URL + 'api/safety/GetYearQuarterFiltersFlat', enterpriseIds);
  }
  //getSafetyReport(filter: IncidentFilterModelVM): Observable<RecordedIncidentVM[]> {
  //  return this.httpService.post<RecordedIncidentVM[]>(this.globalVar.BASE_URL + 'api/safety/getSafetyReport', filter);
  //}
  getSafetyReport(filter: IncidentFilterModelVM): Observable<RecordedIncidentWithLocationVM[]> {
    return this.httpService.post<RecordedIncidentWithLocationVM[]>(this.globalVar.BASE_URL + 'api/safety/getSafetyReport_New', filter);
  }
  removeIncident(incidentId: number): Observable<number> {
    return this.httpService.get<number>(this.globalVar.BASE_URL + 'api/safety/removeIncident?incidentId=' + incidentId);
  }
  GetYearQuarterFiltersForReport(): Observable<YearQuarterModel[]> {
    return this.httpService.get<YearQuarterModel[]>(this.globalVar.BASE_URL + 'api/safety/GetYearQuarterFiltersForReport');
  }
  addSafetyHoursWorked(obj: RecordedIncidentVM): Observable<RecordedIncidentVM> {
    return this.httpService.post<RecordedIncidentVM>(this.globalVar.BASE_URL + 'api/Safety/SaveSafetyHourWork', obj);//.pipe(
    //    retry(1),
    //    catchError(this.handleError)
    //);
  }
  //getSafetyWCLossReport(wcfilter: IncidentFilterModelVM): Observable<WCLossesYearlyDataReportVM[]> {
  //  return this.httpService.post<WCLossesYearlyDataReportVM[]>(this.globalVar.BASE_URL + 'api/safety/GetSafetyWCLossData', wcfilter);
  //}
  getSafetyWCLossReport(wcfilter: IncidentFilterModelVM): Observable<SafetyLossesYearlyDataReportWithLocationVM[]> {
    return this.httpService.post<SafetyLossesYearlyDataReportWithLocationVM[]>(this.globalVar.BASE_URL + 'api/safety/GetSafetyWCLossData_New', wcfilter);
  }
  SaveWCLossYearsData(WCLossYearData: WCLossesYearlyDataReportVM): Observable<number> {
    return this.httpService.post<number>(this.globalVar.BASE_URL + 'api/Safety/SaveWCLossYearsData', WCLossYearData);
    //.pipe(
    //    retry(1),
    //    catchError(this.handleError)
    //);
  }
  //getAllPrograms(programfilter: SafetyProgramsVM): Observable<{ item1: SafetyProgramsVM[], item2: number }> {
  //  return this.httpService.post<{ item1: SafetyProgramsVM[], item2: number }>(this.globalVar.BASE_URL + 'api/safety/GetProgramsList', programfilter);
  //}
  getAllPrograms(constraints: SafetyProgramsVM, gridpsFilterItem: GridPageSortFilters, commonParameters: CommonParameters): Observable<any> {
    return this.httpService.post<any>(this.globalVar.BASE_URL + 'api/safety/GetProgramsList', { constraints: constraints, sortPageFilters: gridpsFilterItem, commonParameters: commonParameters });
  }
  savePrograms(program: SafetyProgramsVM): Observable<number> {
    if (program != null) {
      program.dayOfMonth = program.programDate.getDate();
      program.year = program.programDate.getFullYear();
      program.month = (program.programDate.getMonth() + 1);
    }
    return this.httpService.post<number>(this.globalVar.BASE_URL + 'api/safety/savePrograms', program);
  }
  getPrograms(incidentId: number): Observable<SafetyProgramsVM> {
    if (incidentId != 0) {
      return this.httpService.get<SafetyProgramsVM>(this.globalVar.BASE_URL + 'api/safety/GetProgramById?programId=' + incidentId).pipe(map((resp => {
        var dayOfMonth = resp.dayOfMonth;
        var month = (resp.month - 1);
        var year = resp.year;
        resp.programDate = new Date(year, month, dayOfMonth);
        return resp;
      })));
    }
    else {
      var recProgram = new SafetyProgramsVM();
      recProgram.programId = 0;
      recProgram.programDate = new Date();
      recProgram.programinfo = "";
      recProgram.dayOfMonth = recProgram.programDate.getDate();
      recProgram.month = (recProgram.programDate.getMonth() + 1);
      recProgram.year = recProgram.programDate.getFullYear();

      return of(recProgram);
    }
  }
  removeProgram(programid: number): Observable<number> {
    return this.httpService.get<number>(this.globalVar.BASE_URL + 'api/safety/removeProgram?programId=' + programid);
  }
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
  getWorkerOrTRIRDataStatus(enterpriseId: number, selectedIncidentTypeId: number): Observable<safetyReport[]> {
    return this.httpService.get<safetyReport[]>(this.globalVar.BASE_URL + 'api/safety/GetWorkerOrTRIRDataStatus?enterpriseId=' + enterpriseId + "&incidentTypeId=" + selectedIncidentTypeId);
  }
  getIndustryTRIRDART(enterpriseId: number): Observable<{ item1: number, item2: number }> {
    return this.httpService.get<{ item1: number, item2: number }>(this.globalVar.BASE_URL + 'api/safety/getIndustryTRIRDART?enterpriseId=' + enterpriseId);
  }

  getSafetyQuestion(Quesstionfilter: QuesstionAnswerVM): Observable<QuesstionAnswerVM> {
    return this.httpService.post<QuesstionAnswerVM>(this.globalVar.BASE_URL + 'api/safety/GetsafetyQuestion', Quesstionfilter);
  }
  public downloadSafetyProgramData(filter: SafetyProgramsVM, isDownloadExcel: boolean): Observable<any> {
    return this.httpService.get(this.globalVar.BASE_URL + "api/safety/DownloadSafetyProgramData?filter=" + JSON.stringify(filter)+"&isDownloadExcel=" + isDownloadExcel, { observe: 'response', responseType: 'blob' }).pipe(map(resInner => {
     
      if (isDownloadExcel) {
        saveAs(resInner.body, "Safety Program File" + new Date() + ".xlsx");
      }
      else {
        saveAs(resInner.body, "Safety Program File" + new Date() + ".PDF");
      }
      return resInner;
    }))
  }

  SaveSafetyQuestionResponse(responsefilters: QuesstionAnswerVM): Observable<number> {
    return this.httpService.post<number>(this.globalVar.BASE_URL + 'api/safety/SaveSafetyQuestionResponse', responsefilters);
  }
  generateIncidentTemplateExcel(enterpriseId: number) {

    return this.httpService.get(this.globalVar.BASE_URL + "api/safety/GenerateIncidentTemplateExcel?enterpriseId=" + enterpriseId, { observe: 'response', responseType: 'blob' }).pipe(map(resInner => {
      //var blob = new Blob([resInner.blob()], { type: res.extention })
      saveAs(resInner.body, "Incident Upload Template.xlsx");
      return resInner;
    }))
  }
  checkIfOldUploadIsRunning(enterpriseId: number): Observable<boolean>
  {
    return this.httpService.get<boolean>(this.globalVar.BASE_URL + "api/safety/checkIfOldUploadIsRunning?enterpriseId=" + enterpriseId);
  }
  cancelTransaction(enterpriseId: number): Observable<boolean>
  {
    return this.httpService.get<boolean>(this.globalVar.BASE_URL + "api/safety/cancelTransaction?enterpriseId=" + enterpriseId);
    }
    getLevelInfo(): Observable<LevelInfoModel[]>{
        return this.httpService.get<LevelInfoModel[]>(this.globalVar.BASE_URL + 'api/enterprise/GetLevelInfo', {});
    }
    getSafetyAggregateReport(aggregatefilter: analysisInputModel): Observable<{ item1: EntityAnalysis[], item2: EntityAnalysis[] }> {
        return this.httpService.post<{ item1: EntityAnalysis[], item2: EntityAnalysis[] }>(this.globalVar.BASE_URL + 'api/SafetyAnalysis/GetAggregateOfIncidents', aggregatefilter);
    }
    getSafetyAggregateReportWCDtata(aggregatefilterWC: analysisInputModel): Observable<{ item1: EntityAnalysisWCLosses[], item2: EntityAnalysisWCLosses[] }> {
        return this.httpService.post<{ item1: EntityAnalysisWCLosses[], item2: EntityAnalysisWCLosses[] }>(this.globalVar.BASE_URL + 'api/SafetyAnalysis/GetAggregateOfWcLostdata', aggregatefilterWC);
    }

    
    GetCountryLookup(): Observable<CountryModel[]> {

        return this.httpService.get<CountryModel[]>(this.globalVar.BASE_URL + 'api/SafetyAnalysis/GetCountriesLookup', {});
  }
  public downloadIncidentData(filter: IncidentFilterModelVM, isDownloadExcel: boolean): Observable<any> {
    return this.httpService.get(this.globalVar.BASE_URL + "api/safety/DownloadIncidentsData?filter=" + JSON.stringify(filter) + "&isDownloadExcel=" + isDownloadExcel, { observe: 'response', responseType: 'blob' }).pipe(map(resInner => {

      if (isDownloadExcel) {
        saveAs(resInner.body, "Safety Incident File" + new Date() + ".xlsx");
      }
      else {
        saveAs(resInner.body, "Safety Incident File" + new Date() + ".PDF");
      }
      return resInner;
    }))
  }
  public downloadOccupationalSafetyResults(filter: IncidentFilterModelVM, isDownloadExcel: boolean): Observable<any> {
    return this.httpService.get(this.globalVar.BASE_URL + "api/safety/DownloadOccupationalSafetyResults?filter=" + JSON.stringify(filter) + "&isDownloadExcel=" + isDownloadExcel, { observe: 'response', responseType: 'blob' }).pipe(map(resInner => {

      if (isDownloadExcel) {
        saveAs(resInner.body, "Occupational Safety Results File" + new Date() + ".xlsx");
      }
      else {
        saveAs(resInner.body, "Occupational Safety Results File" + new Date() + ".PDF");
      }
      return resInner;
    }))
  }
  public downloadWorkerCompensationCostSummary(filter: IncidentFilterModelVM, isDownloadExcel: boolean): Observable<any> {
    return this.httpService.get(this.globalVar.BASE_URL + "api/safety/DownloadWorkerCompensationCostSummary?filter=" + JSON.stringify(filter) + "&isDownloadExcel=" + isDownloadExcel, { observe: 'response', responseType: 'blob' }).pipe(map(resInner => {

      if (isDownloadExcel) {
        saveAs(resInner.body, "Worker Cost Sumary File" + new Date() + ".xlsx");
      }
      else {
        saveAs(resInner.body, "Worker Cost Sumary File" + new Date() + ".PDF");
      }
      return resInner;
    }))
  }
   
}
