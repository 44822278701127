import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ElementRef, NgZone, Pipe } from '@angular/core';
import { EnterpriseManager } from '../../services/enterpriseService';
import { LocationService } from '../../services/location.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { LocationModel, PropertyFields } from '../../models/LocationModel';
import { EnterpriseModel, CountryModel, StateModel } from '../../models/enterpriseModel';
import { GridPagingModel } from '../../GridPagingModel';
import { forkJoin } from 'rxjs';
import { NgForm } from '@angular/forms';
import { LoaderService } from '../../services/loadingService';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { LookupCollection } from '../../models/configSettingsModel';
import { FLOAT } from 'html2canvas/dist/types/css/property-descriptors/float';
import { GlobalDialogService } from '../../global-dialog/global-dialog.service';
import { isNullOrUndefined } from 'util';
@Component({
  selector: 'app-enterprise-property-location',
  templateUrl: './enterprise-property-location.component.html',
  styleUrls: ['./enterprise-property-location.component.css'],
  providers: [GridPagingModel]
})
export class EnterprisePropertyLocationComponent implements OnInit {
  @ViewChild('enterpriseForm', { static: false }) public createEnterpriseForm: NgForm;
  @ViewChild('search', { static: false }) public searchElementRef: ElementRef;
  constructor(private enterpriseService: EnterpriseManager, private locationService: LocationService, private gridPageModel: GridPagingModel, private route: ActivatedRoute, private router: Router, private loadingService: LoaderService, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, public dialog: GlobalDialogService) {
 
  }
  enterpriseid: number;
  appVersionId: number;
  countryId: number;
  propertyLocationSections: any;
  propertyFields: any;
  propertyFieldsVM: PropertyFields[];
  allControlsUsedInCalculations: PropertyFields[];
  allControlsUsedInRequired: PropertyFields[];
  allControlsUsedInHidden: PropertyFields[];
  allControlsUsedInDisable: PropertyFields[];

  selectedTab: number = 1;
  lookups: any = {};
  propertyData: any;
  lookupCollections: LookupCollection[];
  currencyList: any;
  ReportedCurrencyId: number = 0;
  ReportedCurrencyString: string = '';
  ReportedCurrencyStringWithoutZeroes: string = '';
  validationErrorLogger: any[] = [];
  finalvalidationErrorLogger: any[] = [];
  @Input() set enterpriselocationToEdit(enterpriselocationToEdit: any) {
    this.initializesetter(enterpriselocationToEdit);
  }
  @Output() closeForm = new EventEmitter();
  initializesetter(enterpriselocationToEdit: any) {
    let recordId = "";
    this.address = '';
    if (enterpriselocationToEdit != undefined) {
      this.gridPageModel.showGrid.next(false);
      this.isEditMode = false;
      this.enterpriseid = 0;
      this.countryId = 2;
      if (enterpriselocationToEdit != null) {
        if (enterpriselocationToEdit.locationid != "") {
          this.isEditMode = true;
          recordId = enterpriselocationToEdit.locationid;
          this.enterpriseid = enterpriselocationToEdit.entityid;

        }
        this.enterpriseid = enterpriselocationToEdit.entityid;
        this.appVersionId = enterpriselocationToEdit.appVersionId;
      }
      if (!this.isEditMode) {
        this.buttonMessage = "Add"
        this.countryId = 2;
      }
      else {
        this.buttonMessage = "Update"
      }
      this.fromString = "enterprise";
      setTimeout(() => {
        this.getLookupCollection(recordId);
      }, 500);

    }
    else {
    }
    if (this.allControlsUsedInCalculations == null) this.allControlsUsedInCalculations = []; else this.allControlsUsedInCalculations.length = 0;
    if (this.allControlsUsedInRequired == null) this.allControlsUsedInRequired = []; else this.allControlsUsedInRequired.length = 0;
    if (this.allControlsUsedInHidden == null) this.allControlsUsedInHidden = []; else this.allControlsUsedInHidden.length = 0;
    if (this.allControlsUsedInDisable == null) this.allControlsUsedInDisable = []; else this.allControlsUsedInDisable.length = 0;

  }
  locationId: string;
  enterpriseLocationModelVM: LocationModel = new LocationModel();
  buttonMessage: string;
  fromString: string;
  countryData: CountryModel[];
  stateData: StateModel[];
  isEditMode: boolean;
  buttonClicked: string;
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  mapStreetAdddress: string;
  private geoCoder: any;
  ngOnInit() { }
  initializeForm(recordId: string, enterpriseid: number, appVersionId: number) {

    this.createEnterpriseForm.resetForm(true);
    forkJoin(this.locationService.GetEnterprisePropertyLocationById(recordId, enterpriseid, appVersionId,2021), this.locationService.getCountries()).subscribe(resp => {

      this.enterpriseLocationModelVM = resp[0];
      
      debugger;
      this.propertyFields = resp[0].item1;
      this.propertyFieldsVM = resp[0].item1;
      
      this.allControlsUsedInCalculations = resp[0].item1.filter(a => a.conditionsAndActions.length > 0)

      


      var isDataSaved = resp[0].item3;
     // if (isDataSaved == false || isDataSaved == "false" || isDataSaved == "False") {
      this.propertyData = resp[0].item4;
      debugger;

     // let a = this.propertyData.isLocationClosed;

      this.setCurrentLocationReportedCurrencyString(this.propertyData['reportingCurrencyId']);
     // this.onChangeOfCountyandState("countryId");
    //  this.onChangeOfCountyandState("stateId");
      
      var totalSections = this.propertyFieldsVM.map(item => item.section)
        .filter((value, index, self) => self.indexOf(value) === index)
      this.propertyLocationSections = [];

      totalSections.forEach(sectionNumber => {
        if (sectionNumber != 0 && sectionNumber != null) {
          var rows = this.propertyFieldsVM.filter(function (item) { return item.section == sectionNumber }).map(t => t.row).distinct();
          var sectionName = "";
          if (sectionNumber == 1) {
            sectionName = "Location Information";
          }
          else if (sectionNumber == 2) {
            sectionName = "Property Value";
          }
          else if (sectionNumber == 3) {
            sectionName = "Building Usage";
          }
          else if (sectionNumber == 4) {
            sectionName = "Values per Square foot";
          }
          var obj = {
            section: sectionNumber, sectionName: sectionName, rows: rows, isDisabled: false
          }

          this.propertyLocationSections.push(obj)

        }
      });

      if (this.enterpriseLocationModelVM == null) {
        this.enterpriseLocationModelVM = new LocationModel();
        this.enterpriseLocationModelVM.locationid = 0;

      }
      if (this.enterpriseLocationModelVM.locationid > 0) {
        this.countryId = this.enterpriseLocationModelVM.countryId;
      }
      this.countryData = resp[1];

      this.getDropDownListforState(this.countryId);
  


      this.loadGoogleMap(this.propertyData['latitude'], this.propertyData['logitude']);
      this.propertyFieldsVM.forEach(item => {
        this.onChangeOfAnyControl(item, true);
      })




    });
  }

  setCurrentLocationReportedCurrencyString = function (reportedCurrencyId) {
    this.ReportedCurrencyId = reportedCurrencyId;
    this.ReportedCurrencyString = this.currencyList.filter(function (item) {
      return item.currencyId == reportedCurrencyId;
    })[0].trailingText;
    this.ReportedCurrencyStringWithoutZeroes = this.ReportedCurrencyString.substring(11);
  }

  getLookupCollection(recordId: string) {
    this.locationService.getLookupCollection(this.enterpriseid).subscribe(resp => {
      this.lookupCollections = resp.item1;
      this.currencyList = resp.item2;
      this.initializeForm(recordId, this.enterpriseid, this.appVersionId);
    })
  }
  getLookupDropDown(lookupTypeId, fieldName, lookupIdSelected, way) {
    //if state
    var lookups = [];
    if (fieldName == 'countryId') {
      var countryId = lookupIdSelected;
      if (countryId == null || countryId.toString().trim() == "" || isNaN(countryId) || Number(countryId) == 0) {
        //this.lookups['StateId'] = [];//lookups;
      }
      else {
        countryId = Number(countryId);
        lookups = this.lookupCollections.filter(function (item) {
          return item.parentId == countryId

        });
        if (lookups.length == 0) {

          lookups = this.lookupCollections.filter(function (item) {
            return item.parentLookupId == 2 && item.lookupId ==0
          });
          //this.lookups['StateId'] = this.lookupCollections.filter(function (item) {
          //  return item.lookupId == countryId
          //});
          this.lookups['stateId'] = lookups;

        }
        else {
          this.lookups['stateId'] = lookups;
        }
        //get states of country
      }
    }
    if (fieldName == 'stateId') {
      lookups = this.lookupCollections.filter(function (item) {
        return item.parentLookupId == lookupTypeId && item.parentId != null
      });
      this.lookups['stateId'] = lookups;
    }
    if (fieldName != 'stateId' && way == 'init') {
      lookups = this.lookupCollections.filter(function (item) {
        return item.parentLookupId == lookupTypeId
      });
      if (lookups != null) {
        this.lookups[fieldName] = lookups;
      }
      else {
        this.lookups[fieldName] = [];
      }
    }
  }

  getDropDownListforState(countryid: number) {
    this.locationService.getStateByCountryid(countryid).subscribe(resp => {
      this.stateData = resp;
    })
  }
  getLookupStateDropDown(lookupTypeId, fieldName, lookupIdSelected, way) {
    var lookups = [];
    if (fieldName == 'countryId') {
      var countryId = lookupIdSelected;
      if (countryId == null || countryId.toString().trim() == "" || isNaN(countryId) || Number(countryId) == 0) {
        this.lookups['StateId'] = [];//lookups;
      }
      else {
        lookups = this.lookupCollections.filter(function (item) {
          return  item.parentId == countryId
        });
        this.lookups['stateId'] = lookups;
        if (lookups.length == 0) {

          lookups = this.lookupCollections.filter(function (item) {
            return item.parentLookupId == 2 && item.lookupId ==0
          });
          this.lookups['stateId'] = lookups;

        }
        else {
          this.lookups['stateId'] = lookups;
        }
        //get states of country
      }
    }

  }
  onChangeOfPropertyLocationControls(fieldName) {
    debugger;
    if (fieldName == 'reportingCurrencyId') {
      var oldCurrencyId = this.ReportedCurrencyId;
      this.setCurrentLocationReportedCurrencyString(this.propertyData[fieldName]);
      var newCurrencyId = this.ReportedCurrencyId;
      var newCurrencyObject = this.currencyList.filter(function (item) {
        return item.currencyId == newCurrencyId
      })[0];
      if (newCurrencyObject != null) {
        this.propertyData['currentUSDConversionRate'] = Number(newCurrencyObject.USDConversionRate);
        this.propertyData['previousUSDConversionRate'] = Number(newCurrencyObject.USDConversionRate);
      }
      var allCurrencyFields = this.propertyFieldsVM.filter(function (item) {
        return item.type == 'Currency'
      });

      if (newCurrencyId != 0 && (!isNaN(newCurrencyId)) && (!isNaN(oldCurrencyId))) {
        allCurrencyFields.forEach(field => {
          if (field.fieldName != 'tIVWithUSDConversion') {
            this.propertyData[field.fieldName] = this.changeThisValueToParticularCurrency(this.toNumberCurrency(this.propertyData[field.fieldName]), oldCurrencyId, newCurrencyId);

          }
        })
      }
    }
    if (fieldName == 'streetAdddress' || fieldName == 'cityId') {
      this.getGeoLocation();
    }
  }

  onChangeOfCountyandState(fieldName) {
    if (fieldName == "countryId") {
      let val = this.propertyData[fieldName];
      if (val == 0 || val == null) {
        return this.propertyFieldsVM.filter(a => a.fieldName == "otherCountryName").filter(function (item) {
          item.isHidden = false;
          item.isRequired = true;
        })
      }
      else {
        return this.propertyFieldsVM.filter(a => a.fieldName == "otherCountryName").filter(function (item) {
          item.isHidden = true;
          item.isRequired = false;
        });
       
      }
    }
    if (fieldName == "stateId") {
      let val = this.propertyData[fieldName];
      if (val == 0 || val == null) {
        return this.propertyFieldsVM.filter(a => a.fieldName == "otherStateName").filter(function (item) {
          item.isHidden = false;
          item.isRequired = true;
        })
      }
      else {
        return this.propertyFieldsVM.filter(a => a.fieldName == "otherStateName").filter(function (item) {
          item.isHidden = true;
          item.isRequired = false;
        });

      }
    }
  }

  //locationChange(fieldName) {
  //  let val = this.propertyData[fieldName];
  //  var conditionAndAction = this.propertyFieldsVM.filter(a => a.conditionsAndActions.length > 0);
  //  if (conditionAndAction.length > 0) {
  //    let a = conditionAndAction.forEach(a => {
  //      a.conditionsAndActions.forEach(a => a.action)
  //    })
  //  }

  //}
  
  changeThisValueToParticularCurrency(value, currCurrencyId, newCurrencyId) {
    var newCurrencyObject = this.currencyList.filter(function (item) {
      return item.currencyId == newCurrencyId
    })[0];
    var oldCurrencyObject = this.currencyList.filter(function (item) {
      return item.currencyId == currCurrencyId
    })[0];
    if (newCurrencyObject != null && oldCurrencyObject != null) {
      var oldRate = Number(oldCurrencyObject.usdConversionRate);
      var newRate = Number(newCurrencyObject.usdConversionRate);
      //iterate through all currency fields
      if (newRate != 0 && !isNaN(newRate) && oldRate != 0 && !isNaN(oldRate)) {
        var newValue = Math.round(((Number(value) * oldRate) / newRate));
        return String(newValue);
      }
      else {
        return String(value);
      }
    }
    else {
      return String(value);
    }
  }

  toNumberCurrency(currencyStr) {
  var decimalSep = ".";
  var toNumberRegex = new RegExp('[^0-9-\\' + decimalSep + ']', 'g');
  if (!isNaN(currencyStr)) {
    return currencyStr;
  }
  else {
    if (typeof currencyStr == 'string') {
      if (currencyStr == "-") {
        return "-";
      }
      else {
        return parseFloat(currencyStr.replace(toNumberRegex, ''));
        //return parseFloat(currencyStr.replace(toNumberRegex, ''), 10);
      }
    }
    else {
      return 0;
    }
  }
}
  saveEnterprise() {
    var f = this.buttonClicked;
    var data = this.propertyData;
    if (this.validationErrorLogger != null) {
      if (this.validationErrorLogger.length > 0) {
        this.propertyData.isValidationError = true;
      }
      else {
        this.propertyData.isValidationError = false;
      }
    }
    else {
      this.propertyData.isValidationError = false;
    }
      this.loadingService.blockUI();

    this.locationService.saveEnterprisePropertyLocation(this.propertyData, this.enterpriseid, this.appVersionId).subscribe(response => {
      this.loadingService.unblock();

      if (f != "Save") {
        this.dialog.showAlertMessage("Location has been updated successfully", "Updated");
        this.backToGrid();
      }
      else {
        if (response != null) {
          this.propertyData.locationIdGuid = response.locationId;
          this.propertyData.locationid = response.keyId;
          this.dialog.showAlertMessage("Location has been saved successfully", "Saved");

        }
      }
    })
  }
  backToGrid() {
    this.closeForm.emit();
  }
  setCurrentLocation(latitude, logitude) {
    if ('geolocation' in navigator) {
      // navigator.geolocation.getCurrentPosition((position) => {
      //this.latitude = position.coords.latitude;
      // this.longitude = position.coords.longitude;
      if (this.propertyData == null) {
        this.propertyData = [];
      }
      if (latitude != null && latitude != undefined && latitude != "0") {
        this.propertyData['latitude'] = String(latitude);
        this.latitude = parseFloat(latitude);
      }
      else {
        this.propertyData['latitude'] = "";
        this.latitude = 0;
      }
      if (logitude != null && logitude != undefined && logitude != "0") {
        this.propertyData['logitude'] = String(logitude);
        this.longitude = parseFloat(logitude);
      }
      else {
        this.propertyData['logitude'] = "";
        this.longitude = 0;
      }
      this.zoom = 15;
      this.getAddress(this.propertyData['latitude'], this.propertyData['logitude']);
      //  });
    }
  }

  getAddress(latitude, longitude) {
    if (latitude == null || latitude == '') {
      latitude = 0;
    }
    else {
      latitude = parseFloat(latitude);
    }
    if (longitude == null || longitude == '') {
      longitude = 0;
    }
    else { longitude = parseFloat(longitude);}
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        if (results[0]) {
           this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          //  window.alert('No results found');
        }
      } else {
        //window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  markerDragEnd($event: MouseEvent) {
    console.log($event);
    this.propertyData['latitude'] = String($event.coords.lat);
    this.propertyData['logitude'] = String($event.coords.lng);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    //this.setlatLong();
    this.getAddress(this.propertyData['latitude'], this.propertyData['logitude']);
  }

  loadGoogleMap(latitude, longitude) {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
      this.setCurrentLocation(latitude, longitude);
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.propertyData['latitude'] = String(place.geometry.location.lat());
          this.propertyData['logitude'] = String(place.geometry.location.lng());
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          //this.setlatLong();
          this.zoom = 12;
          this.getAddress(this.propertyData['latitude'], this.propertyData['logitude']);
        });
      });
    });
  }

  getGeoLocation() {
    var address = '';
    address = this.propertyData['streetAdddress'];
    if (this.propertyData['cityId'] != null && this.propertyData['cityId'] != undefined && this.propertyData['cityId'] != "") {
      if (address != '') {
        address = address + ', ' + this.propertyData['cityId'];
      }
      else {
        address = this.propertyData['cityId'];
      }
    }
    this.mapStreetAdddress = address;
    var address = address;
    this.geoCoder.geocode({ 'address': address }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          this.propertyData['latitude'] = String(results[0].geometry.location.lat());
          this.propertyData['logitude'] = String(results[0].geometry.location.lng());
          this.latitude = results[0].geometry.location.lat();
          this.longitude = results[0].geometry.location.lng();
         // this.setlatLong();
          this.address = address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  setlatLong() {
    this.enterpriseLocationModelVM.latitude = (this.propertyData['latitude']).toString();
    this.enterpriseLocationModelVM.logitude = (this.propertyData['logitude']).toString();
  }

  //////////////////

  onChangeofControl(controlObj: PropertyFields, fromUI?: boolean) {
    if (controlObj.fieldName != null) {
      var regexForFormula = /\{#([^#}]+)\#}/;
      var conditionFieldObj = this.propertyFieldsVM.filter(a => a.conditionsAndActions.length > 0)
      if (conditionFieldObj != null) {
        conditionFieldObj.forEach(mainObj => {
          var conditionAction = mainObj.conditionsAndActions;

          var regexForFormula = /\{#([^#}]+)\#}/;
          conditionAction.forEach(item => {
            var action = item.action;
            var condition = item.condition;
            var uniqueIdentifier = item.identifier;
            var validationMessage = item.validationMessage;
            var validationActiveDefault = item.validationActiveDefault;
            var matches = [];
            while ((matches = regexForFormula.exec(condition)) != null) {
              var indexProps = matches[1];
              if (indexProps != null) {
                var indexAndProps = indexProps.split('-');
                var propIndex = Number(indexAndProps[0]);
                var involvedColName = indexAndProps[0]
                if (!isNaN(propIndex)) {
                  condition = condition.replace(matches[0], this.propertyData[involvedColName]);

                }
                else {
                  condition = condition.replace(matches[0], this.propertyData[matches[1]]);
                }
              }
            }
            var result = false;
            try {
              result = eval(condition);
            } catch (e) { }
            if (action == "disabled" || action == "required" || action == "hidden") {
              if (result == true) {
                if (action == "required") {

                  mainObj.isRequired = true;
                  mainObj.fieldRequired = true;
                  this.onChangeFieldValue(mainObj);                  
                }
                if (action == "disabled") {
                  mainObj.isDisabled = true;
                }
                if (action == "hidden") {
                  mainObj.isHidden = true;
                }
              }
              else {
                if (action == "required") {
                  mainObj.isRequired = false;
                  mainObj.fieldRequired = false;
                  if (this.validationErrorLogger != null && this.validationErrorLogger != undefined) {
                    if (mainObj.fieldName != null) {
                      var indexOfThisError = this.validationErrorLogger.map(function (item, index) { return (item.sectionId == mainObj.section && item.FieldName == mainObj.fieldName) ? index : null }).filter(function (item) { return item != null })[0];
                      if (indexOfThisError != null) {
                        if (indexOfThisError > -1) {
                          this.validationErrorLogger.splice(indexOfThisError, 1);
                        }
                      }
                    }
                  }
                  var totalsections = 4
                  if (this.validationErrorLogger.length > 0) {
                    for (let i = 1; i <= totalsections; i++) {
                      let obj2 = this.validationErrorLogger.filter(a => a.sectionId == i)[0];

                      if (obj2 != null) {
                        let obj3 = this.finalvalidationErrorLogger.filter(a => a.sectionId == obj2.sectionId);
                        if (obj3.length == 0) {
                          this.finalvalidationErrorLogger.push({ sectionId: obj2.sectionId, fieldName: obj2.fieldName })
                        }
                      }
                      else {
                        var indexOfThisError1 = this.finalvalidationErrorLogger.map(function (item, index) { return (item.sectionId == i) ? index : null }).filter(function (item) { return item != null })[0];
                        if (indexOfThisError1 != null) {
                          if (indexOfThisError1 > -1) {
                            this.finalvalidationErrorLogger.splice(indexOfThisError1, 1);
                          }
                        }
                      }



                    }
                  }
                  else {
                    this.finalvalidationErrorLogger = [];
                  }

                }
                if (action == "disabled") {
                  //angular.element(element).removeAttr("disabled");
                  mainObj.isDisabled = false;
                }
                if (action == "hidden") {
                  mainObj.isHidden = false;
                }
              }
            }
            else if (action == "calculation") {
              if (validationActiveDefault != "No" && validationActiveDefault != "no" && validationActiveDefault != "NO") {
                item.validationActiveDefault = item.validationActiveDefaultInitialValue == null ? "yes" : item.validationActiveDefaultInitialValue;
                try {
                  //if (result != "#noChange#") {
                  //  scope.$evalAsync(function () {
                  //    scope.ngMod = result;
                  //  })
                  //}
                } catch (Ewe) { }
              }
            }
          });
        })
      }
    }
  }





  onChangeOfAnyControl(controlObj: PropertyFields, fromUI?: boolean) {

    if (controlObj.conditionsAndActions != null) {
      var regexForFormula = /\{#([^#}]+)\#}/;

      controlObj.conditionsAndActions.forEach(item => {
        var action = item.action;
        var condition = item.condition;
        var uniqueIdentifier = item.identifier;
        var validationMessage = item.validationMessage;
        var validationActiveDefault = item.validationActiveDefault;
        var matches =[];
        while ((matches = regexForFormula.exec(condition)) != null) {
          var indexProps = matches[1];
          if (indexProps != null) {
            var indexAndProps = indexProps.split('-');
            var propIndex = Number(indexAndProps[0]);
            var involvedColName = indexAndProps[0]
            if (!isNaN(propIndex)) {
              condition = condition.replace(matches[0], this.propertyData[involvedColName]);
              
            }
            else {
              condition = condition.replace(matches[0], this.propertyData[matches[1]]);
            }
          }
        }
        var result = false;
        try {
          result = eval(condition);
        } catch (e) { }
        if (action == "disabled" || action == "required" || action == "hidden") {
          if (result == true) {
            if (action == "required") {
              controlObj.fieldRequired = true;
              controlObj.isRequired = true;
              this.onChangeFieldValue(controlObj);
            }
            if (action == "disabled") {
              controlObj.isDisabled = true;
            }
            if (action == "hidden") {
              controlObj.isHidden = true;
            }
          }
          else {
            if (action == "required") {
              controlObj.isRequired = false;
              controlObj.fieldRequired = false;
              if (this.validationErrorLogger != null && this.validationErrorLogger != undefined) {
                if (controlObj.fieldName != null) {
                  var indexOfThisError = this.validationErrorLogger.map(function (item, index) { return (item.sectionId == controlObj.section && item.fieldName == controlObj.fieldName) ? index : null }).filter(function (item) { return item != null })[0];
                  if (indexOfThisError != null) {
                    if (indexOfThisError > -1) {
                      this.validationErrorLogger.splice(indexOfThisError, 1);
                    }
                  }
                }
              }
              var totalsections = 4
              if (this.validationErrorLogger.length > 0) {
                for (let i = 1; i <= totalsections; i++) {
                  let obj2 = this.validationErrorLogger.filter(a => a.sectionId == i)[0];
                  if (obj2 != null) {
                    let obj3 = this.finalvalidationErrorLogger.filter(a => a.sectionId == obj2.sectionId);
                    if (obj3.length == 0) {
                      this.finalvalidationErrorLogger.push({ sectionId: obj2.sectionId, fieldName: obj2.fieldName })
                    }
                  }
                  else {
                    var indexOfThisError1 = this.finalvalidationErrorLogger.map(function (item, index) { return (item.sectionId == i) ? index : null }).filter(function (item) { return item != null })[0];
                    if (indexOfThisError1 != null) {
                      if (indexOfThisError1 > -1) {
                        this.finalvalidationErrorLogger.splice(indexOfThisError1, 1);
                      }
                    }
                  }
                }
              }
              else {
                this.finalvalidationErrorLogger = [];
              }
            }
            if (action == "disabled") {
              //angular.element(element).removeAttr("disabled");
              controlObj.isDisabled = false;
            }
            if (action == "hidden") {
              controlObj.isHidden = false;
            }
          }
        }
        else if (action == "calculation") {
          if (validationActiveDefault != "No" && validationActiveDefault != "no" && validationActiveDefault != "NO") {
            item.validationActiveDefault = item.validationActiveDefaultInitialValue == null ? "yes" : item.validationActiveDefaultInitialValue;
            try {
              //if (result != "#noChange#") {
              //  scope.$evalAsync(function () {
              //    scope.ngMod = result;
              //  })
              //}
            } catch (Ewe) { }
          }
        }
      });
    }

  }

  onChangeFieldValue(controlObj: PropertyFields) {
    var val = this.propertyData[controlObj.fieldName];
    if (val != null) {
    //  val = val | null;
      var str: string = val.toString();
    }

    if (controlObj.fieldRequired) {
      if (str == null ||
        (str != null && str.trim() == "")) {
        let obj = this.validationErrorLogger.filter(a => a.sectionId == controlObj.section && a.fieldName == controlObj.fieldName);
        if (obj.length ==0 ) {
          this.validationErrorLogger.push({ sectionId: controlObj.section, fieldName: controlObj.fieldName, sectionName: controlObj.sectionNumber })
        }
        controlObj.isRequired = true;
      }
      else {
        controlObj.isRequired = false;
        if (this.validationErrorLogger != null && this.validationErrorLogger != undefined) {
          if (controlObj.fieldName != null) {
            var indexOfThisError = this.validationErrorLogger.map(function (item, index) { return (item.sectionId == controlObj.section && item.fieldName == controlObj.fieldName) ? index : null }).filter(function (item) { return item != null })[0];
            if (indexOfThisError != null) {
              if (indexOfThisError > -1) {
                this.validationErrorLogger.splice(indexOfThisError, 1);
              }
            }
          }
        }
        
      }
      var totalsection = 4
      if (this.validationErrorLogger.length > 0) {
        for (let i = 1; i <= totalsection; i++) {
          let obj2 = this.validationErrorLogger.filter(a => a.sectionId == i)[0];

          if (obj2 != null) {
            let obj3 = this.finalvalidationErrorLogger.filter(a => a.sectionId == obj2.sectionId);
            if (obj3.length == 0) {
              this.finalvalidationErrorLogger.push({ sectionId: obj2.sectionId, fieldName: obj2.fieldName })
            }
          }
          else {
            var indexOfThisError1 = this.finalvalidationErrorLogger.map(function (item, index) { return (item.sectionId == i) ? index : null }).filter(function (item) { return item != null })[0];
            if (indexOfThisError1 != null) {
              if (indexOfThisError1 > -1) {
                this.finalvalidationErrorLogger.splice(indexOfThisError1, 1);
              }
            }
          }



        }
      }
      else {
        this.finalvalidationErrorLogger = [];
      }


    }

  }





}
