import { Directive, Input, Renderer2, ElementRef, Output, HostListener, EventEmitter } from '@angular/core';
import { DecimalPipe } from '@angular/common'
import { GlobalVariable } from '../services/globalService';



@Directive({
    selector: '[appNumberComma]',
    providers: [DecimalPipe]
})
export class NumberCommadirDirective {
    constructor(private renderer: Renderer2, private elementRef: ElementRef, private dpipe: DecimalPipe, private global: GlobalVariable) { }
    @Output() exactValue: EventEmitter<string> = new EventEmitter<string>();
    @HostListener('keyup') onKeyUp(e) {

        var oldValue = this.elementRef.nativeElement.value;
        if (oldValue != null) {            
            oldValue = this.global.replaceAll(oldValue, ",", "");
        }
        var num = Number(oldValue);
        if (!isNaN(num)) {
            this.exactValue.emit(oldValue);
        }
        else {
            this.elementRef.nativeElement.value = "";         
        }
    }
    @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
        e.preventDefault();
    }
    @Input() public set customValue(obj: string) {
        var modifiedString: string;
        if (obj == null) {
            modifiedString = "";
        }
        else {
            modifiedString = String(obj);
        }
        if (modifiedString.length > 3) {
            modifiedString = this.dpipe.transform(Number(modifiedString))
            this.elementRef.nativeElement.value = modifiedString;
        }
        else {
            this.elementRef.nativeElement.value = modifiedString;
        }
    }  
}
