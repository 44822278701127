import { Directive, Input, Renderer2, ElementRef, Output, HostListener, EventEmitter } from '@angular/core';
import { GlobalVariable } from '../services/globalService';
//import Tagify from '@yaireo/tagify'
declare var $: any;
@Directive({
  selector: '[tagifyControl]'
})
export class TagifyControl {
  constructor(private renderer: Renderer2, private elementRef: ElementRef, private global: GlobalVariable) { }
  @Output() exactValue: EventEmitter<string> = new EventEmitter<string>();
  @Input() public set whiteListArray(inputVariable: { array: any[], idVar: string, valueVar: string, originalValue: string }) {    
    //var tagify = new Tagify(this.elementRef.nativeElement, {
    //  //  mixTagsInterpolator: ["{{", "}}"],
    //  mode: 'mix',  // <--  Enable mixed-content
    //  pattern: '@',  // <--  Text starting with @ or # (if single, String can be used here)
    //  // Array for initial interpolation, which allows only these tags to be used
    //  whitelist: inputVariable.array.map((obj) => { return { id: obj[inputVariable.valueVar] , value: obj[inputVariable.idVar]  } }),
    //  duplicates: true,
    //  enforceWhitelist: true,
    //  dropdown: {
    //    enabled: 0,
    //    position: "text",
    //    highlightFirst: true,
    //    mapValueTo:"id"
    //    // automatically highlights first sugegstion item in the dropdown        
    //  },
    //  autoComplete: {
    //    enabled: true
    //  },
    //  callbacks: {
    //    add: console.log,  // callback when adding a tag
    //    remove: console.log   // callback when removing a tag
    //  }
    //});
    //var self = this;
    //tagify.loadOriginalValues(inputVariable.originalValue);
    //tagify.parseMixTags(inputVariable.originalValue);
    //tagify.on('input', function (e) {
    //  //var prefix = e.detail.prefix;
    //  //tagify.dropdown.show.call(tagify, e.detail.value);      
    //  console.log(tagify.value)
    //  console.log('mix-mode "input" event value: ', e.detail)
    //})
    //tagify.on('add', function (e) {
    //  console.log(e);
    //});
  }  
}
