import { MatDialog, MatSnackBar } from "@angular/material";
import { GlobalDialogComponent } from "./global-dialog.component";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
@Injectable()
export class GlobalDialogService {
  constructor(private dialog: MatDialog, private _snackBar: MatSnackBar) {

  }
  openDialog(title: string, contentText: string, controlsOptions: any[], inputElement?: number, tbValue?: string, width?: string): Observable<any> {
    const dialogRef = this.dialog.open(GlobalDialogComponent, {
      width: (width == null ? '250px' : width),
      data: { title: title, content: contentText, controls: controlsOptions, inputElement: inputElement, tbValue: tbValue }
    });
    return dialogRef.afterClosed();
  }
  showAlertMessage(message: string, action: string, durationInput?: number) {
    if (durationInput == null || durationInput == 0) {
      durationInput = 3000;
    }
    this._snackBar.open(message, action, { duration: durationInput });
  }

}
