import { Injectable } from "@angular/core";
@Injectable()
export class AppName {
  constructor() {
  }
  public oshaApp: number = 32;
  public oshaAnalysis: number = 38;
  public enterpriseManagement: number = 160;
  public timeTracker: number = 52;
  public propertyProfile: number = 169;
  public adminManagement: number = 36;
  public surveyDesigner: number = 39;
  public directory: number = 232;
  public directoryVersion: number = 209;
  //Region other app statics
  //Safety App related statics
  public incidentTypeInjury: number = 1;
  public incidentTypeNearMiss: number = 2;
  public claimStatusClosed: number = 2;

  //Safety App related statics end
  //end regin app statics
}
