import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalVariable } from './globalService';
import { TenantProviderService } from './tenant-provider.service';
@Injectable({
  providedIn: 'root'
})
export class TenantGaurd implements CanActivate {
  constructor(private router: Router, private tenantService: TenantProviderService) {
  }
  canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.IsAllowedToOpen()
  }
  IsAllowedToOpen(): Observable<boolean> {
    return this.tenantService.IsTenantRequest().pipe(map((d) => {
      if (d > 1) {
        //there is some tenant in url which exists
        this.router.navigate(['/mainapp/dashboard']);
        return false;
      }
      else if (d == 1) {
        //the root tenant or tenant is not specified in url
        return true;
      }
      else if (d == 0) {
        //there is tenant specified in URL but that tenant doesn't exist
        return false;
      }
      return (!d);
    }));
  }
}
